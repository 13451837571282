<template>
  <div class="container">
    <div
      v-if="canStart"
      class="grid grid-cols-1 md:grid-cols-2 p-0 mt-4 border rounded"
    >
      <div class="form-box">
        <div class="form-box--top">
          <h3> {{ $t('user_profile.interview_start_title') }} </h3>
          <p> {{ $t('user_profile.interview_start_desc1') }} </p>
          <p> {{ $t('user_profile.interview_start_desc2') }} </p>
          <p> {{ $t('user_profile.interview_start_desc3') }} </p>
        </div>
        <div :class="[isApple ? 'no-apples' : 'ok-apples']">
          {{ $t('user_profile.interview_start_apple') }}
          <p>
            <span>
              <a href="https://getfirefox.com">
                <img
                  style="width: 36px"
                  src="../assets/firefox.svg"
                >
                Mozilla Firefox {{ $t('user_profile.interview_start_browser_recommended') }}
              </a>
            </span>
          </p>
          <p>
            <span>
              <a href="https://www.google.com/chrome">
                <img src="../assets/chrome.png">
                Google Chrome</a>
            </span>
          </p>
        </div>
        <form
          v-show="!interviewloading"
          class="form-box--bottom mt-auto"
          @submit.prevent="start"
        >
          <p
            v-show="formError"
            class="no-apples"
          >
            {{ formError }}
          </p>
          <div class="checkbox-div">
            <input
              v-model="tosAccepted"
              type="checkbox"
              name="tos-check"
              :disabled="isApple"
            >
            <label>
              {{ $t('register.privacy1') }}
              <a
                target="_blank"
                :href="privacyLink"
              >
                {{ $t('register.privacy2') }}
              </a>
              {{ $t('register.privacy3') }}
            </label>
          </div>
          <SubmitButton :disabled="isApple">
            {{ $t('user_profile.interview_start_submit') }}
          </SubmitButton>
        </form>
        <div
          v-show="interviewloading"
          class="form-box--bottom"
        >
          {{ $t('user_profile.interview_start_loading') }}
        </div>
      </div>
      <div class="illustration-side hidden md:flex">
        <div class="illustration-box" />
      </div>
    </div>
    <div v-else>
      <InterviewStatusBanner />
    </div>
  </div>
</template>

<script>
import '@/vanilla/Box.js'
import '@/vanilla/Stack.js'

import { ref } from 'vue'

import { useInterview } from '@/composables/userData'
import useRedirectUnauthorized from '@/composables/useRedirectUnauthorized'
import InterviewStatusBanner from '@/components/InterviewStatusBanner.vue'
import SubmitButton from '@/components/SubmitButton'
import { useI18n } from "vue-i18n";

export default {
  name: 'StartInterview',
  components: { SubmitButton, InterviewStatusBanner },
  setup() {
    const canStart = ref(true)
    useRedirectUnauthorized()

    const { startInterview, interviewloading, userInterviewState } = useInterview()
    if (userInterviewState.isProcessing() || userInterviewState.isReady()) {
      canStart.value = false;
    }
    const formError = ref('')
    const tosAccepted = ref(false)

    const { t } = useI18n();
    const start = async () => {
      if (!tosAccepted.value) {
        formError.value = t('user_profile.interview_start_no_tos_accepted')
        return
      }
      formError.value = ''
      const result = await startInterview()
      console.debug('[StartInterview.vue] ', result.status)
      if (result.error || !result.url) {
        console.error('Error in server response, got ', result.status, ': ', result)
        formError.value = result.error
        return
      }
      window.location = result.url
    }

    const { locale } = useI18n();
    let privacyLink = locale === 'it' ? 'https://www.viblio.com/privacy-policy/' : 'https://www.viblio.com/en/privacy-policy-en/'

    return { canStart, start, tosAccepted, interviewloading, formError, privacyLink }
  },
  data() {
    return {
      isApple: false,
    }
  },
  created() {
    console.debug(`Apple Safari is not allowed past this point`)
    // source:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#making_the_best_of_user_agent_sniffing
    // ex. UA=Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1 Safari/605.1.15
    var UA = navigator.userAgent
    var isSafari =
      /\b(iPad|iPhone|iPod|Safari)\b/.test(UA) &&
      /WebKit/.test(UA) &&
      /Safari/.test(UA) &&
      /like Gecko/.test(UA) &&
      !/Edge/.test(UA) &&
      !/Chrome/.test(UA) &&
      !/Chromium/.test(UA) &&
      !window.MSStream
    // console.debug(`isApple=${this.isApple} isSafari=${isSafari}`)
    if (isSafari === true) {
      this.isApple = true
      console.debug('Disabling Apple Safari')
    }
  },
}
</script>

<style scoped>


.container {
  padding-top: 1rem;
}

box-l {
  display: block;
  padding: var(--s1);
  border-width: var(--border-thin);
  /* ↓ For high contrast mode */
  outline: var(--border-thin) solid transparent;
  outline-offset: calc(var(--border-thin) * -1);
}

.form-illustrated {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  margin: 1rem 0;
  border-radius: 4px;
}

/* Phone */
@media (max-width: 500px) {
  .form-illustrated {
    display: table;
    margin: 0;
    padding: 0;
  }

  .form-box {
    padding: 0.3rem 0.2rem !important;
  }

  .form-box__interview {
    max-width: 22rem !important;
  }

}

.form-box {
  display: flex;
padding: var(--space-8, 2rem);

flex-direction: column;
align-items: flex-start;
gap: var(--space-6, 1.5rem);

flex: 1 0 0;
}

.form-box__interview {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.form-box h1 {
  color: var(--Text-default, #121520);
  /* text-xl/bold */
  font-family: var(--font-text);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  /* 133.333% */
}

.form-box p {
  color: var(--Text-subtle, #64748B);
  /* text-default/regular */
  font-family: var(--font-text);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 142.857% */
}

.form-box__interview .form-box--bottom {
  align-self: end;
  margin-bottom: 1.2rem;
}

.illustration-box {
  width: 25.75rem;
height: 31.4375rem;
border-radius: var(--space-0, 0rem);

background: url("../assets/man-telephone.svg"), lightgray 50% / cover no-repeat;

}

.illustration-side{
  padding: var(--space-0, 0.4375rem) var(--space-0, 2.0625rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-0, 0rem);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-0, 0rem) var(--space-4, 1rem) var(--space-4, 1rem) var(--space-0, 0rem);

  background: #F2ECFF;
}


.checkbox-div {
  display: flex;
  margin-top: 1.2rem;
  align-items: center;
  margin-bottom: 1.2rem;
}

.checkbox-div input {
  width: 1.375rem;
  height: 1.375rem;
  margin: 0 0.75rem 0 0;
  padding: 0;
}

.no-apples {
  /* Show the warning */
  padding: 10px;
  color: #ff4b00;
  border: 2px dotted red;
  display: block;
  height: fit-content;
}

.no-apples img {
  vertical-align: middle;
  display: table-cell;
  margin-right: 10px;
  width: 36px;
}

.checkbox-div input {
  box-shadow: none;
}

.no-apples a {
  display: flex;
  align-items: center;
}

.no-apples a,
span {
  color: #434c54;
}

.ok-apples {
  display: none;
}

</style>
