{
  "on_boarding": {
    "header_title": "Ciao {name}, benvenuto su Viblio! | Ottimo! Ogni viaggio inizia con un primo passo | Aggiungi le competenze che ti interessano",
    "header_subtitle": "Sono la tua guida digitale per seguire il tuo lavoro, le competenze attualmente richieste e quelle che saranno essenziali in futuro.<br>Mi dici quale lavoro fai, e ti mostro cosa c'è da imparare.<br>Grazie alla mia intelligenza artificiale, cerco e seleziono i contenuti dalle migliori piattaforme digitali del mondo per te: non solo corsi, ma anche podcast, video, articoli.<br>Il momento migliore per iniziare è ora!",
    "role_edit": "Modifica ruoli",
    "showing_n_job_roles": "{tot} risultati",
    "show_search_help": "Se ti rappresenta, clicca su 'Passa allo step successivo'. Se non siamo ancora lì, puoi aggiungere un altro {item} cercando nel mio database.",
    "next_step": "Passa allo step successivo | Salta | Conferma",
    "next_button": "Avanti",
    "back_button": "Indietro",
    "finish_button": "Inizia, entra in Viblio",
    "finish_button_short": "Inizia",
    "terms_and_conditions": "Termini e condizioni",
    "role": {
      "label": "Il tuo ruolo",
      "hello": "Ciao {name}, sono Viblio, il tuo mentore digitale!<br/>Nei prossimi minuti ti farò alcune domande per suggeriti i contenuti formativi più adatti a te!",
      "second": "<strong>Iniziamo: Qual è il tuo ruolo?</strong><div class='text-sm'>Cerca nel mio database inserendo le parole che descrivono il tuo ruolo. Puoi selezionare fino a 2 ruoli</div>",
      "third": "Questo è l’unico ruolo che ricopri? Se sei soddisfatto seleziona “Avanti”, altrimenti aggiungi un altro ruolo.",
      "forth": "Ho memorizzato i tuoi ruoli. Seleziona “avanti” per procedere",
      "first_role_selected": "Ruolo selezionato",
      "second_role_selected": "Secondo ruolo selezionato",
      "search_placeholder": "Descrivi il tuo ruolo...",
      "error": "Seleziona almeno un ruolo",
      "no_results": {
        "header": "Nessun risultato trovato",
        "content": "Purtroppo non è stato possibile trovare il tuo ruolo, prova con un ruolo simile. Il nostro database di ruoli internazionali è in continuo ampliamento, sono sicuro che presto ci sarà!"
      }
    },
    "skill": {
      "label": "I tuoi interessi",
      "first": "<div>Sento già di conoscerti un po meglio! So che sei <strong>{roles}</strong>.<br/>Ora mi interessa conoscere cio che ti contraddistingue!</div>",
      "second": "<strong>Quali sono le competenze che ti rendono unico?</strong><div class='text-sm'>Seleziona fino a 5 competenze o interessi che vuoi apprendere o allenare!<br/>(La selezione degli interessi è opzionale)</div>",
      "third": "Ho memorizzato tutte le tue competenze. Seleziona “avanti” per andare al riepilogo",
      "search_placeholder": "Cerca competenze...",
      "skill_selected": "Competenza",
      "no_results": "Nessun risultato trovato"
    },
    "summary": {
      "label": "Riepilogo",
      "first": "<div><strong>Sto costruendo delle collezioni di contenuti di apprendimento</strong> partendo dalle informazioni che mi hai dato:</div>",
      "second": "Verifica i tuoi dati e seleziona su “Inizia, entra in Viblio”.<br/>Per modificare la tua selezione clicca sull’icona di modifica.",
      "roles": "<strong>Il tuo ruolo</strong>{roles}",
      "skills": "<strong>I tuoi interessi</strong/>{skills}"
    }
  },
  "preauthentication": {
    "login": {
      "title": "Autenticazione in corso...",
      "body": "Stiamo completando la tua autenticazione. Sarai presto indirizzato a Viblio. Grazie per la tua pazienza."
    },
    "logout": {
      "title": "Attendi per favore...",
      "body": "Stai uscendo da Viblio, attendi per favore. Grazie per la tua pazienza."
    }
  },
  "modal_mobile": {
    "title": "Sezione non ancora disponibile su mobile",
    "text": "Ci dispiace, Viblio non è ancora completamente ottimizzato per dispositivo mobile: stiamo lavorando per garantirti un esperienza di utilizzo migliore.",
    "button": "Torna alla sezione precedente"
  },
  "sidebar": {
    "for_you": "Per te",
    "collections": "Collezioni",
    "collections_personal": "Collezioni personali",
    "collections_company": "Collezioni aziendali",
    "collections_shared": "Collezioni condivise",
    "analysis": "Analizza",
    "organization_title": "Impostazioni Aziendali",
    "organization": "Organizazione",
    "overview": "Overview",
    "team": "Team Settings",
    "settings": "Settings",
    "departments": "Dipartimenti",
    "teams": "Teams",
    "skills": "Gestione Skills",
    "users": "Utenti",
    "plans": "Il mio piano",
    "subscriptions": "Gestione Subscriptions",
    "home": "Home",
    "explore": "Esplora",
    "explore-results": "Risultati",
    "dashboard": "Dashboard",
    "manage": "Gestisci",
    "missing_payment_title": "Il tuo piano scade tra 10gg",
    "missing_payment_text": "Aggiungi un metodo di pagamento per continuare ad usare Viblio",
    "interview_banner": "Completa la video intervista",
    "interview_banner_start": "Inizia la video intervista",
    "interview_banner_failed": "Riprova la video intervista",
    "interview_banner_progress": "Analisi intervista in corso",
    "interview_banner_download": "Scarica il Report intervista",
    "company_collection": "Collezioni aziendali",
    "personal_collection": "Le mie Collezioni",
    "shared_with_me_collection": "Condivisi con me",
    "wait_first_org_shared_collection_banner": "Non sono presenti collezioni condivise dalla tua azienda",
    "wait_first_org_shared_collection_banner2": "In questa sezione verranno mostrate le collezioni condivise con te dalla tua azienda",
    "wait_first_shared_collection_banner": "Non sono presenti collezioni condivise con te",
    "wait_first_shared_collection_banner2": "In questa sezione verranno mostrate le collezioni condivise con te dal tuo team o dipartimento",
    "first_personal_collection_banner": "Crea adesso la tua prima collezione! Salva contenuti, organizza le tue informazioni e inizia a costruire il tuo hub di conoscenza curato."
  },
  "for_you": {
    "title": "Tutti i contenuti per te",
    "description": "In questa sezione puoi trovare i contenuti curati su misura per il tuo ruolo e i tuoi interessi creati per te da Viblio",
    "global_collections": "Collezione degli esperti | Collezioni degli esperti"
  },
  "card": {
    "view_all": "Vedi tutto",
    "free": "Gratuito",
    "more_details": "Piu dettagli"
  },
  "interview": {
    "not_done_title": "Pronto a far salire di livello le tue competenze?",
    "not_done_subtitle": "Scopri i tuoi punti di forza con la video intervista!",
    "not_done_body": "Completa la video-intervista e scopri i tratti di personalità che identificano i tuoi punti di forza e le potenziali aree di miglioramento che impattano sul tuo ruolo.",
    "not_done_cta": "Inizia la video intervista",
    "report_ready_title": "Scarica il report della tua video-intervista!",
    "report_ready_subtitle": "Scopri i tuoi punti di forza e le potenziali aree di miglioramento.",
    "report_ready_button": "Scarica il report",
    "created_title": "Termina la video-intervista per scoprire i tratti di personalità che impattano sul tuo ruolo.",
    "created_subtitle": "Completa la video-intervista e scopri i tuoi punti di forza e le potenziali aree di miglioramento.",
    "created_button": "Continua l'intervista",
    "in_progress_title": "Stiamo elaborando la tua intervista sui tratti di personalità",
    "in_progress_subtitle": "Stiamo analizzando la tua intervista. Riceverai una e-mail quando il tuo report è pronto",
    "modal_header_initialize": "Inizia  la video intervista e identifica e sviluppa il tuo potenziale",
    "modal_header_continue": "Continua la video intervista e identifica e sviluppa il tuo potenziale",
    "modal_save_label_initialize": "Inizia la video intervista",
    "modal_save_label_continue": "Continua la video intervista",
    "modal_body_first": "Viblio utilizza il motore di intelligenza artificiale YobsTech e un meccanismo di valutazione basato sugli standard dell'American Psychological Association (APA) per analizzare le tue risposte e suggerirti il percorso di apprendimento più adatto a te.",
    "modal_body_second": "Al termine della video intervista, riceverai un report dettagliato con i tuoi punti di forza e le aree di miglioramento, per analizzare in profondità i risultati in prima persona.",
    "modal_body_third": "La video intervista dura solo 6 minuti e dovrai rispondere a 3 domande e per ognuna avrai 1 minuto di tempo per prepararti e 1 minuto di tempo per rispondere.",
    "modal_accept_privacy": "Accetto la <a href='https://www.viblio.com/privacy-policy/'>privacy policy</a> del servizio",
    "modal_accept_privacy_error": "Devi accettare la privacy policy per continuare",
    "modal_cancel": "Annulla"
  },
  "subscriptions": {
    "expiration_date": "Data Scadenza",
    "is_expirable": "Ha scadenza?"
  },
  "plans": {
    "select": "Seleziona un Piano",
    "plan": "Piano",
    "subscription": "Piano",
    "start_date": "Sottoscrizioni",
    "expiration_date": "Data Scadenza",
    "renewal_date": "Rinnovo",
    "total_license": "Numero di Sottoscrizioni",
    "used_license": "Utenti",
    "trial_period": "Periodo di prova",
    "none": "Nessuno",
    "cancel_plan": "Annullato",
    "expired": "Scaduto",
    "pending": "In attesa",
    "payed": "Pagato",
    "plan_id": "Id Piano: {id}",
    "validity": "Validità",
    "validity_val": "Da {start} a {end} ({frequency})",
    "msg_error_saving": "Si è verificato un errore nel salvataggio. Per favore riprova più tardi. {errormsg}",
    "msg_success_created": "Piano creato con successo",
    "msg_success_updated": "Piano aggiornato con successo",
    "msg_payed_success": "Aggiornamento pagamento piano registrato con successo",
    "msg_payed_failed": "Aggiornamento pagamento piano fallito",
    "no_subscription": "Non ci son sottoscrizioni associate a questo piano",
    "select_user": "Seleziona gli utenti da associare a questo piano",
    "hide_expired": "Nascondi i piani scaduti",
    "show_expired": "Mostra anche i piani scaduti",
    "manage_action": "Gestisci piano",
    "banner_upgrade_to_business": {
      "title": "Funzionalità disponibile con piano business",
      "message": "Clicca su “Cambia piano” per iniziare subito ad usarla.",
      "cta_button": "Cambia piano"
    },
    "banner_limit_user_in_trial_period": "Nel periodo di prova è possibile creare massino 10 utenti per provare la piattaforma",
    "banner_trial": {
      "title": "Piano {plan} - Periodo di prova",
      "message": "Il periodo di prova scade oggi. Il giorno {expireDate} il tuo piano verrà disattivato se non inserisci un metodo di pagamento cliccando su “Gestisci piano”. | Il periodo di prova scade tra {totalDays} giorni. Il giorno {expireDate} il tuo piano verrà disattivato se non inserisci un metodo di pagamento cliccando su “Gestisci piano”.",
      "description": "Per usare le funzioni contrasegnate con l’icona del lucchetto devi passare al piano “Business”: Provalo gratis. "
    },
    "banner_trial_with_payment": {
      "title": "Piano {plan} - Il periodo di prova scade oggi ma non devi fare nulla | Piano {plan} - Il periodo di prova scade tra {totalDays} giorni ma non devi fare nulla",
      "message": "",
      "description": "Per usare le funzioni contrasegnate con l’icona del lucchetto devi passare al piano “Business”: Provalo gratis. "
    },
    "banner_expired": {
      "title": "Il tuo piano è scaduto! Clicca su “Gestisci piano” e aggiungi un metodo di pagamento per continuare ad usare Viblio.",
      "message": "Se hai dubbi contattaci a "
    },
    "banner_canceled": {
      "title": "",
      "message": "Non ci sono abbonamenti attivi sul tuo account, clicca su “Seleziona piano” per attivare un abbonamento."
    },
    "banner_canceled_still_valid": {
      "title": "",
      "message": "Hai annullato il tuo piano: potrai utilizzare Viblio fino a {expireDate}. \n\rSe desideri riattivare il tuo piano, clicca si “Gestisci piano” e selezione “Rinnova piano”"
    },
    "banner_pending": {
      "title": "Sono stati rilevati degli errori sul metodo di pagamento inserito per cui non è stato possibile rinnovare o attivare il piano. \n\r ",
      "message": "Clicca su “Gestisci piano”, verifica il metodo di pagamento e clicca su “paga l’importo dovuto” per riattivare il piano. Se hai dubbi contattaci a "
    },
    "banner_payment_error": {
      "title": "Sono stati rilevati degli errori sul metodo di pagamento inserito, clicca su “Gestisci piano” per correggerli.",
      "message": "Il tuo piano rimarrà attivo fino al prossimo rinnovo."
    },
    "info": {
      "title": "Piani Viblio",
      "description": "Se sei interessato a sottoscrivere degli add-on",
      "contact_us": "Contattaci",
      "user_month": "Per utente / mese",
      "includes": "Cosa include:",
      "includes_desc": "In aggiunta al piano “{planName}”, include:",
      "upgrade": "Upgrade",
      "downgrade": "Downgrade",
      "actual_plan": "Piano Corrente",
      "choose_plan": "Seleziona Piano",
      "current": "Selezionato",
      "personal": {
        "title": "Personal",
        "users": "1 utente",
        "features": {
          "0": "Percorso di apprendimento individuale.",
          "1": "Onboarding e intervista individuale.",
          "2": "Collezioni personali",
          "3": "Dashboard personale"
        }
      },
      "business": {
        "title": "Business",
        "users": "da 2 a 250 utenti",
        "features": {
          "0": "Collezioni aziendali",
          "1": "Dashboard aziendale",
          "2": "Gestione utenti, team, dipartimenti, ruoli",
          "3": "Add-on: catalogo corsi premium, set up aziendale, collezioni delgi esperti"
        }
      },
      "enterprise": {
        "title": "Enterprise",
        "users": "Oltre i 251 utenti",
        "price": "Prezzo personalizzato",
        "price_desc": "Sottoscrizione annuale",
        "features": {
          "0": "Ruoli e competenze personalizzate",
          "1": "Single sign-on (SSO) basato su SAML",
          "2": "Add-on: Gamification e marketplace"
        }
      },
      "values": {
        "range_2_250": "Da 2 a 250",
        "more_250": "Più di 250",
        "unlimited": "Illimitati",
        "no": "No",
        "one": "1",
        "add_on": "Add on *"
      },
      "add_on_notes": "(*) Tutti gli add-on prevedono la sottoscrizione di un piano annuale",
      "features": {
        "users": "Utenti",
        "teams": "Teams",
        "departments": "Dipartimenti",
        "organizations": "Organizzazioni",
        "custom_path": "Percorso si apprendimento personalizzato",
        "custom_path_desc": "Creazione di un percorso formativo personalizzato a partire dalla selezione di ruolo, competenze e interessi",
        "interview": "Video intervista sulla personalità",
        "interview_desc": "Video intervista che, tramite l'intelligenza artificiale, definisce i tratti della personalità e le attitudini personali",
        "system_coll_occ": "Contenuti suggeriti per hard e soft skill",
        "system_coll_occ_desc": "Contenuti suggeriti riferiti a hard skill e soft skill del ruolo",
        "system_coll_skill": "Contenuti suggeriti per competenze",
        "system_coll_skill_desc": "Contenuti suggeriti riferiti a competenze e interessi (max 5)",
        "system_coll_hot_tech": "Contenuti suggeriti per skill tecnologiche",
        "system_coll_hot_tech_desc": "Contenuti suggeriti riferiti a competenze e tendenze tecnologiche",
        "system_coll_soft": "Contenuti suggeriti per attitudini personali",
        "system_coll_soft_desc": "Contenuti suggeriti riferiti ai tratti della personalità e le attitudini personali",
        "search": "Motore di ricerca dei corsi",
        "search_desc": "Motore di ricerca dei corsi con più di 55.000 contenuti tra video, corsi, podcast, talk e webinar",
        "free_providers": "Catalogo contenuti gratuiti",
        "free_providers_desc": "Catalogo corsi gratuiti di tutti i nostri provider (più di 25.500) come POLIMI, Udemy, Udacity, EdX, Future Learn, Coursera, TED, Harvard Business School, Spotify, Youtube playlist e altri ancora.",
        "udemy_providers": "Catalogo contenuti premium Udemy ",
        "udemy_providers_desc": "Catalogo corsi a pagamento Udemy con 10.000 certificationi",
        "udemy_providers_note": "Min 21 utenti",
        "future_providers": "Catalogo contenuti premium Future Learn",
        "future_providers_desc": "Catalogo corsi a pagamento Future Learn con 5.000 certificationi",
        "future_providers_note": "Min 50 utenti",
        "coursera_providers": "Catalogo contenuti premium Coursera",
        "coursera_providers_desc": "Catalogo corsi a pagamento Coursera con 9.000 certificationi",
        "coursera_providers_note": "Min 150 utenti",
        "collections": "Collezioni personali",
        "collections_desc": "Creazione collezioni personali",
        "org_collections": "Collezioni aziendali",
        "org_collections_desc": "Creazione collezioni aziendali per team e dipartimento",
        "global_collections": "Collezioni degli esperti",
        "global_collections_desc": "Collezioni degli esperti e percorsi formativi su misura per raggiungere obiettivi aziendali",
        "yearly_subscription_note": "Solo per sottoscrizione annuale",
        "share_collections": "Condivisione collezioni",
        "dashboard": "Dashboard analitica personale",
        "dashboard_desc": "Dashboard analitica personale per l'analisi del dati sulla formazione e l'andamento della tua crescita",
        "org_dashboard": "Dashboard analitica aziendale",
        "org_dashboard_desc": "Dashboard analitica aziendale per l'analisi del dati sulla formazione e l'andamento della tua crescita, filtrabile per team e dipartimento",
        "management": "Gestione collaboratori, team, dipartimenti",
        "management_desc": "Creazione e gestione di collaboraori, team, dipartimenti",
        "roles_management": "Gestione ruoli",
        "roles_management_desc": "Assegnazione ruoli a tutti i collaboratori (manager, supervisiore, etc.)",
        "std_setup": "Set up standard dell'azienda",
        "std_setup_desc": "Intelligenza Artificiale collaborativa per set up standard dell'azienda",
        "onboarding": "Onboarding e Supporto",
        "onboarding_desc": "Onboarding e Supporto che comprende un Learning Architect dedicato che ti supporterà per Set Up Profili, Webinar di lancio e follow up, collezione,su misura, help desk dedicato",
        "custom_setup": "Set up aziendale personalizzato",
        "custom_setup_desc": "Set up aziendale personalizzato",
        "custom_roles": "Ruoli e competenze personalizzati",
        "custom_roles_desc": "Integrazione ruoli e competenze personalizzato",
        "org_providers": "Catalogo contenuti aziendali",
        "org_providers_desc": "Integrazione con catalogo corsi aziendale proprietario",
        "sso": "Single sign-on (SSO) ",
        "sso_desc": "Single sign-on (SSO) basato su SAML",
        "api": "Accesso alle API",
        "data": "Data Management",
        "data_desc": "Import & Export dei dati dalla piattaforma",
        "performance": "Employee Tracking & Performance MGMT",
        "performance_desc": "Employee Tracking & Performance MGMT",
        "game": "Gamification e marketplace",
        "game_desc": "Marketplace in piattaforma e tecniche di ingaggio basate sulla gamification"
      }
    },
    "miss_payment_modal": {
      "title": "Non è presente nessun metodo di pagamento",
      "subtitle": "per attivare Viblio è necessario aggiungere prima un metodo di pagamento.<br> Clicca su “Inserisci metodo di pagamento” e successivamente seleziona il piano che desideri attivare.",
      "confirm": "Inserisci metodo di pagamento"
    },
    "change": {
      "title": "Cambia piano",
      "description": "Seleziona tipo di piano e periodo di fatturazione.",
      "type_of_plan": "Tipo di piano",
      "billing_frequency": "Fatturazione",
      "summary_title": "Riepilogo",
      "summary_actual_plan": "Piano attuale “{plan}” ",
      "summary_selected_plan": "Piano selezionato “{plan}”",
      "summary_current_case": "€ {amount} / mese ({monthly_amount} € x {users} utente)",
      "summary_actual_plan_amount": "Piano attuale: {amount} €",
      "summary_selected_plan_amount": "Piano selezionato: {amount} €",
      "summary_total": "Totale da pagare: € {amount}/mese (Iva inclusa)",
      "summary_downgrade": "Fino alla data indicata continuerai ad usufruire delle funzionalità del piano “Business”.",
      "summary_next_renewal": "Prossimo addebito: {renewal_date}",
      "footer_cta": "Selezionando “Conferma piano selezionato”, dichiaro di aver compreso che sto sottoscrivendo un piano in abbonamento e autorizzo Viblio ad addebitare la tariffa di listino in vigore sul metodo di pagamento selezionato, salvo annullamento. Posso annullare l’abbonamento in qualsiasi momento andando alla pagina “Gestisci piano”.",
      "footer_downgrade": "Abbiamo notato che hai creato più utenti con il tuo piano Business. Selezionando un piano personal che prevede 1 solo utente, rimarrà attivo solo l’utente con il quale ti sei rigistrato su Viblio, gli altri saranno disabilitati e non potranno più accedere. Per consentire nuovamente l’accesso al tuo team, puoi sottoscrivere il piano “Business” in qualsiasi momento.",
      "cta_button": "Conferma piano selezionato",
      "change_plan_success": "Il tuo piano è stato aggiornato correttamente",
      "change_plan_error": "Si è verificato un errore nel cambio piano. Per favore riprova più tardi. {errormsg}",
      "summary_no_selected_plan": "Nessun piano selezionato",
      "active_plan_success": "Il tuo piano è stato attivato correttamente",
      "active_plan_error": "Si è verificato un errore nell'attivazione del piano. Per favore riprova più tardi. {errormsg}",
      "plan_type_personal_label": "Personal (9,00€ utente/mese) - 1 utente",
      "plan_type_business_label": "Business (12,00€ utente/mese) - 1 utente",
      "billing_frequency_monthly": "Mensile",
      "billing_frequency_yearly": "Annuale"
    },
    "setpayed_action": "Segna come Pagato"
  },
  "users": {
    "first_name": "Nome",
    "last_name": "Cognome",
    "filter_name": "Nome Cognome",
    "email": "Email",
    "plan": "Piano",
    "profile": "Profilo",
    "profile_help": "Piano per Business User",
    "profile_flex": "Flexible",
    "profile_fullon": "FULLON",
    "switch_tofull": "Converti in Full-On",
    "switch_toflex": "Converti in Flexible",
    "role": "Ruolo",
    "role_help": "Tutti i ruoli permettono di fare la formazione con Viblio. Supervisore (utente che gestisce utenti, team e dipartimenti, creare e condividere collezioni), manager (utente che può creare team e creare e condividere collezioni), member (può fare la formazione e creare collezioni personali).",
    "team_counter": "Teams",
    "info": "Informazioni sull’utente",
    "owner_teams": "Team di appartenenza",
    "department_name": "Dipartimento",
    "department_help": "Seleziona il dipartimento di appartenenza (potrai assegnare gli utenti ai dipartimenti anche in un secondo momento)",
    "is_active": "Stato",
    "is_active_help": "Lo stato di un utente può essere attivo o disattivo. Ad ogni utente attivo corrisponde una licenza mensile o annuale.",
    "is_active_banner": "Gli utenti disattivi non possono accedere alla piattaforma e non concorrono al prezzo totale delle licenze.",
    "is_active_true": "Attivo",
    "is_active_false": "Disattivo",
    "agreement": "Condivisione dati",
    "agreement_true": "Si",
    "agreement_false": "No",
    "deactivate_action": "Disattiva",
    "activate_action": "Attiva",
    "manage_action": {
      "tooltip": "Converti Piano",
      "title": "Sei sicuro di voler convertire  quest' utente in {switchTo} ?",
      "subtitle": "L'operazione comporterà {switchToMsg} del costo",
      "subtitle_full_on": "un aumento",
      "subtitle_flex": "una diminuizione",
      "message": "Il nuovo importo verrà notificato all'organizzazione.",
      "message_full_on": "può godere",
      "message_flex": "non può godere",
      "message_success": "La conversione è stata effettuata con successo",
      "message_success_msg": "Ora l'utente {name} {switchToMsg} dei vantaggi Full-on",
      "message_failed": "Errore"
    },
    "confirm_remove": {
      "message": "L'utente verrà eliminato. Anche le sue sottoscrizioni verranno cancellate.",
      "remove": "Elimina",
      "subtitle": "Sei sicuro di voler eliminare <strong>{name}</strong>?",
      "title": "Elimina"
    },
    "no_select_record": "Non ci son utenti da selezionare",
    "no_select_record_add_now": "Aggiungi un nuovo utente",
    "deleted": "L'utente è stato cancellato"
  },
  "general_table": {
    "search": "Cerca",
    "no_record": "Non ci son record",
    "no_record_add_now": "Crea il primo record",
    "add_record": "Nuovo Record",
    "actions": "Azioni",
    "action_not_available": "Not available in backend yet.Coming soon.",
    "confirm_remove": {
      "message": "Il record verrà eliminato.",
      "remove": "Elimina",
      "subtitle": "Sei sicuro di voler eliminare <strong>{name}</strong>?",
      "title": "Elimina"
    }
  },
  "collections": {
    "title_": "No",
    "title": "Collezioni",
    "create": "Crea nuova collezione",
    "create_cta": "Crea collezione",
    "create_desc": "Inserisci il nome della nuova collezione che vuoi creare e clicca cu “Crea collezione” per aggiungere il contenuto alla nuova collezione",
    "create_success": "La collezione {collection} è stata appena creata!",
    "create_add_success": "Contenuto aggiunto alla collezione {collection} appena creata",
    "collection": "Collezione ",
    "system_hard": "Hard Skills",
    "system_soft": "Soft Skills",
    "system_hot_tech": "Skill tecnologiche",
    "system_interview": "Personality Traits",
    "system_skill_desc": "Descrizione della raccolta Viblio di questa competenza.",
    "add_and_count_empty": "Aggiungi a una collezione",
    "add_and_count_many": "Presente in {count} collezioni",
    "add_and_count_one": "Presente in 1 collezione | Presente in {count} collezioni",
    "add_to_a_collection": "Vuoi aggiungere questo contenuto a una collezione?",
    "add_to_a_new_collection": "Inserisci il nome della nuova collezione che vuoi creare e clicca cu “Crea e aggiungi” per aggiungere il contenuto alla nuova collezione",
    "add_to_collection": "Aggiungi alla collezione",
    "in_collection": "Aggiunto alle collezioni",
    "added_to": "Aggiunto a:",
    "collection_under_construction": "Alcuni dei risultati sono in fase di selezione per la tua figura professionale.",
    "collection_created": "La nuova collezione <strong>{name}</strong> è stata creata con successo.",
    "collection_updated": "La collezione <strong>{name}</strong> è stata aggiornata con successo.",
    "collection_deleted": "La collezione è stata eliminata con successo.",
    "confirm_remove_course_title": "Vuoi eliminare il contenuto dalla collezione?",
    "confirm_remove_course_subtitle": "Sei sicuro di voler eliminare dalla collezione il contenuto selezionato?",
    "confirm_remove_course_message": "Per confermare clicca su “Elimina contenuto”.",
    "confirm_remove_course_button": "Elimina contenuto",
    "confirm_delete_title": "Vuoi eliminare la collezione?",
    "confirm_delete_subtitle": "Sei sicuro di voler eliminare la collezione {collection}? Eliminando la collezione verranno eliminati tutti i contenuti preferiti al suo interno.",
    "confirm_delete_message": "Per confermare clicca su “Elimina collezione”.",
    "confirm_delete_button": "Elimina collezione",
    "confirm_unshare_title": "Vuoi interrompere la condivisione della collezione?",
    "confirm_unshare_subtitle": "Se interrompi la condivisione le persone non potranno più consultare i contenuti formativi nel loro portale nelle collezioni condivise.",
    "confirm_unshare_message": "Seleziona “Interrompi condivisione” per confermare.",
    "confirm_unshare_button": "Interrompi condivisione",
    "shared_msg": "Hai condiviso la tua collezione",
    "unshared_msg": "La tua collezione personale non è più condivisa",
    "course_added": "Contenuto aggiunto alla collezione | Contenuto aggiunto alle collezioni",
    "courses_count": "contenuti",
    "create_new": "Crea e aggiungi",
    "create_new_subtitle": "Dai alla tua collezione un nome e una descrizione per mantenere i tuoi contenuti organizzati",
    "edit_collection": "Modifica nome collezione",
    "edit_collection_help": "Modifica in nome della tua collezione e clicca sul pulsante “Salva” per confermare.",
    "new_collection": "Nuova collezione",
    "new_collection_name": "Nome collezione",
    "new_collection_button_text": "Oppure crea una nuova collezione",
    "new_collection_description_placeholder": "C'è qualche dettaglio che vuoi aggiungere?",
    "new_collection_title_placeholder": "Cosa riguarda questa collezione?",
    "no_collections_cta": "Crea la tua prima collezione",
    "no_collections_description": "Per creare la tua prima collazione clicca sul pulsante “Nuova Collezione”",
    "no_collections_title": "Non hai nessuna collezione personale",
    "remove_from_collection": "Rimuovi dalla collezione",
    "resource_removed": "Il contenuto selezionato è stato rimosso dalla collezione",
    "share_btn": "Condividi",
    "share_collection_title": "Condividi collezione",
    "share_collection_org": "Condividi con tutta l'organizzazione",
    "share_collection_global": "Condividi con il mondo Viblio",
    "share_collection_with_group": "Condividi con persone, team e dipartimento selezionati",
    "share_collection_group_placeholder": "Seleziona teams e/o dipartimenti",
    "shared_with": "Condivisa con:",
    "shared_with_org": "Tutta l’azienda",
    "shared_with_global": "Tutta  la Community",
    "shared": "Già condivisa",
    "share_title": "Condividi collezione",
    "share_subtitle": "Seleziona a chi vuoi condividere la collezione.",
    "edit_share_org_subtitle": "Modifica le organizzazioni che possono accedere alla collezione.",
    "edit_share_group_subtitle": "Modifica le persone o i gruppi di {organization} che possono accedere alla collezione.",
    "share_radio_error": "Seleziona almeno un destinatario per condividere la collezione",
    "share_groups_users": "Team, dipartimenti o membri",
    "edit_share_groups_users": "Aggiungi team, dipartimenti o membri",
    "shared_global": "Condivisa con il mondo Viblio",
    "users": "{total} persona | {total} persone",
    "SHARE_NONE": "Privata",
    "SHARE_ORG": "Condivisa con la tua organizzazione",
    "SHARE_GLOBAL": "Condivisa con il mondo Viblio",
    "back_to_list": "Vai alla lista delle collezioni",
    "personal_title": "Collezioni personali",
    "company_title": "Collezioni aziendali",
    "shared_with_groups_title": "Collezioni condivise",
    "personal_list_subtitle": "Le collezioni ti aiutano a raggiungere più velocemente i tuoi obiettivi di crescita! Crea le tue collezioni per salvare e organizzare i contenuti preferiti.",
    "company_list_subtitle": "In questa sezione troverai le collezioni condivise dalla tua azienda",
    "shared_with_me_list_subtitle": "In questa sezione troverai le collezioni condivise con te",
    "shared_with_group_title": "Condivise con il tuo team o dipartimento",
    "shared_with_user_title": "Condivise con te",
    "classification_hard": "Hard Skills",
    "classification_soft": "Soft Skills",
    "classification_hot_tech": "Hot Technologies Skills",
    "classification_interview": "Personality Traits Collection",
    "title_job_role": "Competenze per il tuo ruolo: {jobName}",
    "title_job_role_desc": "Qui sotto trovi i contenuti che Viblio ha selezionato per te per stare al passo con il tuo ruolo da un punto di vista tecnico, (non puoi farne a meno!).",
    "title_skill": " Dato che hai scelto: {skillName}",
    "title_skill_desc": "",
    "title_from_interview_desc": "",
    "title_from_interview": "Competenze trasversali / la mia personalità",
    "unshare_collection": "Smetti di condividere",
    "user_collections": "Collezioni personali",
    "private_empty": "Non sono presenti contenuti in questa collezione",
    "private_empty_desc": "Non sono presenti contenuti in questa collezione. Puoi aggiungere i contenuti dalla lista o dal dettaglio di un contenuto formativo disponibile su Viblio.",
    "shared_with_me_empty": "Non sono presenti contenuti in questa collezione",
    "shared_with_me_empty_desc": "Non sono presenti contenuti in questa collezione ma presto verranno mostrati i contenuti selezionati e suggeriti dal tuo team o dipartimento",
    "shared_with_org_empty_desc": "Non sono presenti contenuti in questa collezione ma presto verranno mostrati i contenuti selezionati e suggeriti dalla tua organizzazione"
  },
  "course_detail": {
    "access_needs_account": "L'accesso a questo corso è riservato agli utenti registrati. Clicca qui per richiedere informazioni.",
    "access_needs_upgrade": "Per accedere a questo corso passa al piano Full On. Clicca qui per richiedere informazioni.",
    "access": "Accedi al corso",
    "access_podcast": "Ascolta il podcast",
    "unavailable": "Contenuto Non Disponibile",
    "unavailable_desc": "Contenuto non più disponibile nel provider",
    "full_on_free": "Incluso nel Piano FullOn",
    "free": "GRATUITO",
    "payed": "A PAGAMENTO",
    "resources": {
      "course": "Corso",
      "podcast": "Podcast",
      "video": "Video",
      "document": "Documento",
      "webinar": "Webinar",
      "youtube_playlist": "Youtube Playlist",
      "codecademy_playlist": "Codecademy Playlist",
      "access_content": "Accedi al Contenuto",
      "access_course": "Accedi al Corso",
      "access_podcast": "Ascolta il Podcast",
      "access_video": "Guarda il Video",
      "access_document": "Leggi il Documento",
      "access_webinar": "Partecipa al Webinar",
      "access_youtube_playlist": "Consulta la Youtube Playlist",
      "access_codecademy_playlist": "Consulta la Codecademy Playlist"
    },
    "content_level": "LIVELLO CONTENUTO",
    "skills_level": "MIGLIORA LE TUE SKILLS"
  },
  "course_results": {
    "cost": "Fascia di prezzo",
    "provider": "Provider",
    "language": "Lingua",
    "filters": "Applica Filtri",
    "reset_filters": "Elimina filtri",
    "searching": "searching...",
    "active_filters": "Filtri attivi:",
    "course_tabs": {
      "author_many": "Autori",
      "author_one": "Autore",
      "description": "Descrizione",
      "requisites": "Requisiti",
      "source": "Da"
    },
    "effort": "Impegno",
    "effort_periods": {
      "months_many": "mesi",
      "months_one": "mese",
      "weeks_many": "settimane",
      "weeks_one": "settimana"
    },
    "effort_values": {
      "less:2:hours": "Fino a due ore",
      "less_2_hours": "Fino a due ore",
      "more:1:months|less:3:months": "1-3 mesi",
      "more_1_months__less_3_months": "1-3 mesi",
      "more:1:weeks|less:4:weeks": "1-4 settimane",
      "more_1_weeks__less_4_weeks": "1-4 settimane",
      "more:3:months": "Più di 3 mesi",
      "more_3_months": "Più di 3 mesi"
    },
    "level": "Livello",
    "level_values": {
      "Advanced": "Avanzato",
      "Beginner": "Principiante",
      "Intermediate": "Intermedio",
      "exclude_others": "Per tutti",
      "Anyone": "Per tutti"
    },
    "no_results": "Sembra che la tua ricerca per <strong>\"{searchTerm}\"</strong> non abbia prodotto nessun risultato.",
    "price": "Fascia di prezzo",
    "price_values": {
      "all": "Tutti",
      "pay": "A PAGAMENTO",
      "free": "GRATUITO",
      "full_on_free": "GRATUITO FullOn"
    },
    "not_only_fullon": "Included",
    "only_fullon": "Solo con Full On",
    "tot_results_many": "{count} risultati per",
    "tot_results_one": "Un risultato per",
    "try_changing_text": "Prova a controllare i termini della ricerca",
    "try_changing_text_or_filters": "Prova a modificare i filtri attivi o controlla i termini della ricerca",
    "type": "Tipo di contenuto",
    "type_values": {
      "course": "Corso on line",
      "podcast": "Podcast",
      "webinar": "Webinar",
      "video": "Video",
      "youtube_playlist": "YouTube Playlist",
      "youtube playlist": "YouTube Playlist",
      "codecademy_playlist": "Codecademy Playlist",
      "codecademy playlist": "Codecademy Playlist"
    },
    "uh_oh": "UH-OH."
  },
  "search": {
    "title": "Ciao!",
    "description": "Cosa vuoi imparare oggi?",
    "search_button": "Cerca",
    "results_found": "risultati trovati",
    "filters": "Filtri",
    "all_types": "Tutti i tipi",
    "all_prices": "Tutti i costi",
    "no_results": {
      "title": "Oh, no! Nessun risultato trovato per “{query}”",
      "description": "Non abbiamo trovato nessun contenuto formativo che corrisponde al termine inserito all’interno del nostro catalogo. Cosa puoi fare?",
      "step_1": "Cambia il termine inserito",
      "step_2": "Controlla i filtri applicati",
      "step_3": "Prova a modificare la tua ricerca",
      "last_paragraph": "Il nostro catalogo è in continuo ampliamento quindi in futuro potrai trovare maggiori contenuti formativi."
    }
  },
  "entities": {
    "hide_soft_deleted": "Nascondi le entità cancellate",
    "show_soft_deleted": "Mostra anche le entità cancellate"
  },
  "Error": "Errore",
  "errors": {
    "generic_msg": "Errore di salvataggio, riprova",
    "api_down": "Stiamo rilevando dei problemi sul nostro server.",
    "failed_login": "Il login è fallito. Verifica email e password.",
    "failed_logout": "Il logout è fallito.",
    "failed_registration": "La registrazione è fallita. Verifica i dati e riprova.",
    "general_title": "Si è verificato un errore nel salvataggio. Ti chiediamo di riprovare.",
    "general_msg": "Se l’errore persiste, riprova più tardi. ",
    "general": "Si è verificato un errore. Riprova più tardi.",
    "logout": "Errore nel logout",
    "offline": "Sembra che non sia disponibile la connessione.",
    "retry": "Riprova",
    "session_expired": "La tua sessione è scaduta. Ti stiamo trasferendo alla pagina di login."
  },
  "footer": {
    "about_us": "Chi siamo",
    "contact_us": "Contatti",
    "terms": "Terms of privacy and cookie policy",
    "about_us_link": "https://www.viblio.com/it/about",
    "contact_us_link": "https://www.viblio.com/it/contacts",
    "terms_link": "https://www.viblio.com/it/privacy"
  },
  "general": {
    "add": "Aggiungi",
    "show": "Visualizza",
    "new": "Nuovo",
    "mark_as_completed": "Segna come completato",
    "completed": "Completato",
    "optional": " (opzionale)",
    "desktop_only": "Non disponibile su telefono",
    "required_fields_tooltip": "Compila tutti campi obbligatori per procedere",
    "select": "Seleziona",
    "select_all": "Seleziona Tutti",
    "select_added": " (Già aggiunto)",
    "change": "Cambia",
    "settings": "Impostazioni",
    "address": "indirizzo",
    "back": "Indietro",
    "skip_to_conent": "Vai al contenuto",
    "skills": "Competenze",
    "level": "Livello",
    "course_description": "Descrizione del corso",
    "back_to": "Torna ai ",
    "cancel": "Annulla",
    "city": "Città",
    "click_here": "Clicca qui",
    "confirm": "conferma",
    "cost": "costo",
    "language": "lingua",
    "country": "Nazione",
    "create": "Crea",
    "create_and_insert": "crea e aggiungi",
    "description": "Descrizione",
    "password": "Password",
    "phone": "Telefono",
    "delete": "Elimina",
    "remove": "Rimuovi",
    "role": "Ruolo",
    "save": "Salva",
    "title": "Titolo",
    "update": "Modifica",
    "detail": "Dettaglio",
    "website": "Website",
    "province": "Provincia",
    "zipcode": "CAP",
    "form": {
      "field_errors": {
        "text": "{name} è un campo obbligatorio",
        "email": "Inserisci un email valida",
        "password": "La password deve avere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"
      }
    }
  },
  "home_box": {
    "error_cta": "Ripeti l'intervista",
    "error_msg": "Puoi sostenere di nuovo l'intervista per ottenere una nuova analisi.",
    "error_pre_title": "Si è verificato un errore",
    "error_title": "Purtroppo l'analisi dell'intervista è fallita",
    "has_interview_cta": "Vedi i risultati",
    "has_interview_msg": "Ho selezionato questa collezione di contenuti sulla base dei risultati della tua video intervista: da qui puoi apprendere competenze specifiche per valorizzare i tuoi punti di forza e rafforzare le tue aree di miglioramento",
    "has_interview_processing_msg": "Stiamo elaborando il tuo report, consulta le collezioni e scegli cosa ti interessa approfondire, appena pronto lo troverai nel tuo profilo",
    "has_interview_title": "Lavoriamo insieme",
    "logged_cta": "Inizia l'intervista",
    "logged_msg": "Scopri suggerimenti su misura per un apprendimento più rapido e personalizzato.",
    "logged_pre_title": "Pronto a far salire di livello la tua scelta di contenuti?",
    "logged_title": "Fai la video-intervista!",
    "interview_banner": "La video intervista è disponibile per browser ",
    "started_cta": "Fai la video intervista",
    "started_msg": "Abbiamo visto che hai iniziato la video intervista ma non l’hai completata, rispondi alle domande, impiegherai al massimo 10 minuti,  la mia intelligenza artificiale mi aiuterà a suggerire i contenuti più adatti a te.",
    "unlogged_cta": "Registrati per accedere",
    "unlogged_msg": "Vuoi dei suggerimenti su misura? Conosciamoci meglio: rispondi alle domande della mia video intervista, la mia intelligenza artificiale mi aiuterà a suggerire i contenuti più adatti a te.",
    "unlogged_title": "Percorsi di apprendimento su misura",
    "team_title": "Collezioni suggerite dalla tua organizzazione"
  },
  "home": {
    "collections_in_progress_description": "In questo momento sono all’opera per selezionazione i migliori contenuti pensati per te.<br>Generalmente mi basta meno di un minuto, per vedere i risultati clicca su “aggiorna”.",
    "collections_in_progress_title": "Sto costruendo le collezioni<br> per la tua posizione lavorativa!",
    "collections_empty_description": "In questo momento sono all'opera per selezionazione i migliori contenuti pensati per te. Generalmente mi basta qualche giorno per una nuova posizione lavorativa.",
    "collections_empty_title": "OPS è la prima volta che ci viene richiesta la tua posizione lavorativa!",
    "collections_in_progress_button": "Aggiorna",
    "first_reel_title": "I contenuti più popolari al momento",
    "first_reel_title_w_report": "I consigli di Viblio per il tuo sviluppo personale",
    "intro": "Scegli quello che più ti piace, in totale libertà",
    "job_role_reel_title": "Suggerimenti personalizzati",
    "job_role_reel_title_not_dev": "Le proposte di Viblio per il tuo ruolo",
    "subtitle": "Evolvi le tue conoscenze con l’aiuto della nostra selezione di contenuti personalizzati per te!\n\nGrazie alla sua intelligenza artificiale, Viblio cerca e seleziona per te i contenuti delle migliori piattaforme digitali al mondo: non solo corsi, ma anche podcast, video, articoli.",
    "title": "Il tuo mentor digitale",
    "created_by": "creato da ",
    "welcome_header": "Ciao ",
    "welcome": "Andiamo, Viblio!",
    "top_content_header": "Cosa c'è di nuovo",
    "viblio_suggested": "Suggerite dagli esperti Viblio",
    "quotes": {
      "default": {
        "cit": "”Evita di fare ciò che non sai, ma apprendi tutto ciò che occorre”",
        "author": "Pitagora (filosofo greco del V secolo a.C.)"
      }
    }
  },
  "job_roles": {
    "customer_service_representatives": "Customer Service Representative",
    "developer": "Sviluppatore software",
    "generic": "I contenuti più popolari per il tuo ruolo",
    "hr_manager": "HR Manager",
    "marketing_manager": "Marketing Manager"
  },
  "login": {
    "forgot_password": "Password dimenticata?",
    "no_account": "Non hai un account Viblio?",
    "placeholders": {
      "email": "inserisci l'email con cui ti sei registrato",
      "password": "inserisci la tua password"
    },
    "welcome": "Accedi per scoprire un universo di contenuti e continuare il tuo percorso di crescita."
  },
  "organizations": {
    "add_user": "Aggiungi utente",
    "add_user_no_limit_for_trial": "Non si possono aggiungere più di 10 utenti nel periodo di prova.",
    "users_counter": "Utenti:",
    "filter_name": "Nome Organizzazione",
    "small_title": "Modifica nome spazio di lavoro",
    "small_subtitle": "Modifica il nome del tuo spazio di lavoro e clicca si “Salva” per confermare.",
    "small_name": "Organizzazione",
    "name": "Nome e cognome o Ragione sociale",
    "email": "Email",
    "status": "Stato",
    "status_disabled": "Disabilitato",
    "confirm_remove": {
      "message": "I dati dell'organizzazione non verranno cancellati, rimangono disponibili a database. Tutti gli utenti associati verrano disabilitati",
      "remove": "Disabilita",
      "subtitle": "Sei sicuro di voler disabilitare <strong>{name}</strong>?",
      "title": "Disabilita organizzazione"
    },
    "created": "L'organizzazione <strong>{organization}</strong> è stata creata.",
    "create_organization": "Crea organizzazione",
    "create_user": "Nuovo Utente",
    "cta_create": "Crea la prima",
    "deleted": "L'organizzazione è stata cancellata",
    "edit_organization": "Modifica la organizzazione {name}",
    "edit_user": "Modifica utente {name}",
    "empty": "Non ci sono organizzazioni",
    "error_saving_user": "Si è verificato un errore nel salvataggio. Per favore riprova più tardi. {errormsg}",
    "error_deleting_user": "Si è verificato un errore nell' eliminazione dell'utente. {errormsg}",
    "form": {
      "company_info": {
        "billing_title": "Inserisci l’indirizzo di fatturazione",
        "billing_subtitle": "Compila i seguenti dati e clicca su “Salva”.",
        "address": "Indirizzo",
        "source": "Fonte",
        "plan": "Piano",
        "pay_in_app": "Pagamento via stripe",
        "trial": "Periodo di prova",
        "trial_days": "Durata",
        "address_detail": "Scala, interno, piano",
        "help": "Dati anagrafici",
        "legal_address": "Sede legale",
        "title": "Dati Aziendali",
        "country_help": "Codice paese a due lettere (Es. IT, DE, FR ...)",
        "cf_code": "Codice Fiscale",
        "vat_code": "Partita iva (solo per aziende e professionisti)",
        "vat_code_help": "Aggiungere il prefisso della nazione prima della Partita IVA"
      },
      "general": {
        "email": "Email dell'organizzazione",
        "title": "Dati Personali"
      },
      "referent": "Referente",
      "personal": "Personal",
      "payment_attached_date": "Metodo di pagamento aggiunto",
      "pay_in_app": "Paga in app",
      "stripe_customer_id": "Stripe customer ID"
    },
    "covert_to_pay_in_app": "Converti con pagamento in App",
    "covert_to_pay_in_app_subtitle": "Sei sicuro di voler convertire l'organizzazione {name} a pagamenti in App?",
    "covert_to_pay_in_app_message": "L'operazione non può essere revocata.",
    "covert_to_pay_in_app_success": "La conversione è avvenuta con successo.",
    "join_us_payload": "Ciao! Sei stato invitato da <strong>{orgName}</strong> a far parte di Viblio!<br><br>Crea il tuo account: il futuro dell’apprendimento è a un click di distanza.",
    "manage_users": "Utenti",
    "manage_users_description": "Crea gli utenti della tua organizzazione e associa loro il ruolo di member, manager e supervisor. \nAd ogni utente creato verrà inviata una email per iniziare subito ad usare Viblio.",
    "manage_users_description_business": "Crea gli utenti della tua organizzazione e associa loro un ruolo. Ad ogni utente attivo aggiunto, verrà inviata una email per iniziare ad usare Viblio. Ogni utente attivo corrisponde ad una licenza mensile/annuale.",
    "no_users": "Non ci sono utenti in questa organizzazione.",
    "no_users_add_now": "Aggiungi il primo",
    "organizations": "Organizzazioni",
    "updated": "L'organizzazione <strong>{organization}</strong> è stata modificata.",
    "user_created": "Utente creato con successo",
    "user_form": {
      "job_role": "Ruolo",
      "job_role_help": "",
      "manager": "manager",
      "signup_status_invited": "INVITED",
      "user_is_MEMBER": "Member",
      "user_is_MANAGER": "Manager",
      "user_is_SUPERVISOR": "Supervisore",
      "user_is_ADMIN": "Admin",
      "user_is_CONSUMER": "Consumer",
      "wrong_link": "Il link che hai seguito non è valido o è scaduto. Per favore controlla il link e riprova. Se l'errore persiste contatta un amministratore.",
      "manager_sets_role_true": "Voglio impostare io il ruolo e le competenze",
      "manager_sets_role_false": "Voglio che sia l'utente a impostare il proprio ruolo e competenze",
      "manager_sets_role_incomplete": "Non hai completato la selezione del ruolo",
      "reset_job_titles_skills": "Resetta Job Roles e Skills",
      "no_department": "Non sono ancora stati creati dipartimenti"
    },
    "users": "Il tuoi utenti",
    "plans": "Il tuo piano | I tuoi piani",
    "add_plan": "Aggiungi piano",
    "user_updated": "Utente aggiornato con successo",
    "your_organization": "La tua organizzazione",
    "update_collection": "Refreshing collections!",
    "unavailable_plans": "Non ci son licenze disponibili. Per favore contatta il tuo amministratore",
    "add_department": "Crea Dipartimento",
    "add_team": "Crea Team",
    "teams": "Teams"
  },
  "groups": {
    "team": "Team | Teams",
    "department": "Dipartimento | Dipartimenti",
    "departments_description": "I dipartimenti sono un insieme di persone che condividono ruoli comuni.\nCrea i dipartimenti della tua organizzazione, associa gli utenti ai dipartimenti (ogni utente può essere associato ad un solo dipartimento) e crea delle collezioni condivise.",
    "teams_description": "I team sono un insieme di persone che condividono obiettivi comuni.\nCrea i team della tua organizzazione, associa gli utenti ai team (ogni utente può essere associato a più team) e crea delle collezioni condivise.",
    "name": "Nome",
    "description": "Descrizione",
    "assigned_to": "Responsabile del {model}",
    "assigned_to_department": "Responsabile del dipartimento",
    "assigned_to_team": "Responsabile del team",
    "assigned_to_help": "Potrai modificarlo anche successivamente",
    "user_counter": "Utenti",
    "delete_success": "Eliminato con successo",
    "msg_error_saving": "Si è verificato un errore nel salvataggio. Per favore riprova più tardi. {errormsg}",
    "msg_success_created": "Il Gruppo <strong>{name}</strong> è stato creato.",
    "msg_success_updated": "Il Gruppo <strong>{name}</strong> è stato modificato.",
    "no_record": "Non è presente nessun {group}.",
    "no_record_add_now": "Clicca su Crea per aggiungere il primo",
    "users": {
      "confirm_remove": {
        "remove": "Rimuovi",
        "title": "Rimuovi l'utente da questo gruppo",
        "subtitle": "Sei sicuro di voler rimuovere <strong>{name}</strong>?",
        "message": ""
      }
    }
  },
  "register": {
    "already_account": "Hai già un account?",
    "access": "Accedi",
    "access_1": "Accedi e inizia ad apprendere",
    "continue": "Continua",
    "complete": "Completa registrazione",
    "pick_password": "Scegli una password",
    "create_password": "Crea password",
    "wait": "Attendi solo un momento",
    "back": "Indietro",
    "process": "Stiamo processando i tuoi dati...",
    "select": "Seleziona",
    "title": "Registrati e scopri il tuo percorso di apprendimento",
    "terms": "Termini e condizioni",
    "sub_line_1": "Nessuna carta di credito richiesta",
    "sub_line_2": "14 giorni di prova gratuita",
    "sub_line_3": "Possibilità di cancellare la sottoscrizione",
    "separator": "OPPURE",
    "choose_password": "Scegli una password",
    "confirm_password": "Conferma la Password",
    "google_register": "Registrati con Google",
    "linkedin_register": "Registrati con Linkedin",
    "weak": "Password Debole",
    "medium": "Password Buona",
    "complex": "Password Ottima",
    "have_account": "Hai già un account? Fai il {login}",
    "same_email": "Esiste già un utente con la stessa email.",
    "title_2": "Raccontaci qualcosa in più di te",
    "mandatory": "I campi obbligatori devono essere compilati",
    "first_name": "Nome",
    "last_name": "Cognome",
    "identify": "Come ti identifichi professionalmente?",
    "objective": "Che obiettivo professionale vuoi raggiungere?",
    "company": "Azienda di appartenenza",
    "company_size": "Numero di dipendenti",
    "student": "Sono uno studente",
    "worker": "Sono un dipendente",
    "conselour": "Sono un consulente/ libero professionista",
    "team_manager": "Sono un manager/gestisco un team",
    "hr": "Sono un HR manager o gestisco la formazione in azienda",
    "other": "Altro",
    "updated": "Voglio rimanere aggiornato",
    "new_skills": "Voglio acquisire nuove competenze",
    "change_path": "Voglio cambiare il mio percorso professionale",
    "continous_formation": "Voglio uno strumento che permetta al mio team o alla mia azienda di fare formazione continua",
    "1": "Solo io",
    "2-9": "2-9",
    "10-49": "10-49 (piccola impresa)",
    "50-249": "50 - 249 (media impresa)",
    "+250": "Più di 250 dipendenti",
    "privacy_policy": "Accetto i ",
    "tec": "temini e condizioni",
    "and": "e la",
    "newsletter": "Voglio rimanere aggiornato sulle novità di Viblio (solo quando aggiungiamo qualcosa di interessante)",
    "end_registration": "Hai completato la registrazione!",
    "end_registration_sub": "Benvenuto su Viblio!",
    "end_registration_text": "Ciao, sono Viblio, il tuo mentore digitale.Grazie alla mia intelligenza artificiale, seleziono i contenuti dalle migliori piattaforme di e-learning per evolvere il tuo ruolo e le tue competenze per adattarsi agli standard europei attuali e futuri.",
    "check_email": "Controlla la tua casella di posta elettronica",
    "check_email_text_1": "Ti abbiamo inviato una email all'indirizzo",
    "check_email_text_2": ". Controlla la tua casella di posta e clicca sul link di verifica per confermare il tuo indirizzo email.",
    "check_email_sub": "(Se non trovi la mail di verifica nella posta in entrata, controlla nella casella spam o posta indesiderata)",
    "email_error": "Il campo Email è obbligatorio",
    "password_error": "Il campo Password è obbligatorio",
    "join_us": "Unisciti a noi",
    "join_us_payload": "Registrati su Viblio e inizia a imparare le conoscenze del futuro con gli strumenti e la guida dei nostri esperti",
    "password_format": "Almeno 6 caratteri alfanumerici",
    "placeholders": {
      "email": "inserisci la tua email",
      "name": "inserisci il tuo nome",
      "surname": "inserisci il tuo cognome",
      "work_email": "inserisci la tua email di lavoro"
    },
    "registering_user": "Validazione dati e registrazione utente in corso ...",
    "privacy1": "Accetto la ",
    "privacy2": "privacy policy",
    "privacy3": " del servizio"
  },
  "reset_password": {
    "enter_email": "Inserisci la tua mail",
    "info": "Inserisci qui sotto l’indirizzo email con cui ti sei registrato. Riceverai un messaggio con le istruzioni per cambiare la tua password ed accedere a Viblio.",
    "warning": "Se il tuo indirizzo non è presente nei nostri archivi, non riceverai alcuna notifica."
  },
  "user": {
    "login": "LOGIN",
    "profile": "Profilo",
    "progress": "Attività e Collezioni",
    "subscription": "Piano",
    "register": "Registrati",
    "logout": "Esci"
  },
  "user_profile": {
    "save_changes": "Salva modifiche",
    "wait_save_changes": "Non sono state rilevate modifiche",
    "profile_section_title": "Dati di accesso, privacy e notifiche",
    "my_data": "I miei dati",
    "no_skills": "Nessun interesse selezionato",
    "change_name_surname_lang": "Aggiorna",
    "account_settings": "Account e notifiche",
    "change_password_title": "Password di accesso",
    "change_password": "Modifica Password",
    "change_password_description": "Clicca su “Modifica password” per cambiare la password di accesso.  Una volta cambiata dovrai effettuare nuovamente il login",
    "change_password_error": "Il cambio di password è fallito. Per favore fai un nuovo login e riprova.",
    "change_password_success": "La password è stata cambiata con successo",
    "delete_account": "Elimina account",
    "delete_account_description": "Invia un’email a ",
    "delete_account_instructions": " per eliminare il tuo account e tutti i tuoi dati.",
    "delete_account_instructions_2": "Cancellando il tuo account perderai tutti i suggerimenti e lo storico delle tue consultazioni.",
    "description": "Qui trovi le informazioni che hai condiviso durante la profilazione iniziale. Puoi cambiare nome e cognome, ma non la mail di registrazione.",
    "interview": "Video intervista",
    "interview_description": "Vuoi dei suggerimenti di apprendimento ancora più personalizzati? Fai la video intervista che, grazie all’intelligenza artificiale, definisce il tuo profilo attitudinale. Al termine potrai scaricare il report dettagliato e scoprire i contenuti più adatti per crescere professionalmente.",
    "interview_lets_start": "Inizia la video intervista",
    "interview_started": "Non hai completato la video intervista. Clicca su “continua la video intervista” per riprendere da dove hai lasciato. Al termine potrai scaricare il report dettagliato e scoprire i contenuti più adatti per crescere professionalmente.",
    "interview_started_cta": "Continua la video intervista",
    "interview_processing": "Hai completato la video intervista e il tuo report sarà disponibile a breve. Appena sarà disponibile riceverai un’email e potrai trovare i contenuti più adatti a te su Viblio.",
    "interview_completed": "Hai già complatato la video intervista per conoscere il tuo profilo attitudinale. Di seguito puoi scaricare il report o visualizzare i contenuti suggeriti per te.",
    "interview_go_collection": "Vai ai contenuti suggeriti",
    "interview_download_cta": "Scarica il report",
    "interview_failed": "Purtroppo l'analisi della tua intervista ha generato un errore.",
    "interview_error_cta": "Ripeti la video intervista",
    "interview_start_title": "Ottieni il meglio da Viblio: identifica e sviluppa il tuo potenziale",
    "interview_start_desc1": "La video intervista dura solo 5 minuti. Risponderai a 3 domande, per ciascuna avrai a disposizione 1 minuto per leggere di che si tratta e prepararti a rispondere e 1 minuto per registrare la tua risposta.",
    "interview_start_desc2": "Viblio utilizza il motore di intelligenza artificiale YobsTech e un meccanismo di valutazione basato sugli standard APA per analizzare le tue risposte e suggerirti il percorso di apprendimento più adatto a te.",
    "interview_start_desc3": "Al termine della video intervista, riceverai un report dettagliato con i tuoi punti di forza e le aree di miglioramento, per analizzare in profondità i risultati in prima persona.",
    "interview_start_apple": "Attenzione, Apple Safari non e' ancora supportato dalla videointervista. Ti preghiamo di riprovare usando una di queste alternative:",
    "interview_start_browser_recommended": "(consigliato)",
    "interview_start_submit": "Sei pronto? Inizia ora!",
    "interview_start_loading": "Connessione in corso ...",
    "interview_start_no_tos_accepted": "Per proseguire, clicca l'accettazione dei Termini e Condizioni",
    "interview_finish_title": "CONGRATULAZIONI! HAI TERMINATO LA TUA VIDEO INTERVISTA!",
    "interview_finish_desc": "Stiamo creando il tuo report. Ti invieremo una mail non appena sarà pronto! Solitamente, ci vogliono 1 o 2 giorni.",
    "interview_finish_desc2": "Nel frattempo, puoi continuare ad esplorare la miriade di contenuti selezionati dai nostri esperti",
    "interview_finish_back": "Torna alla home page",
    "intro_section_title": "Il mio profilo professionale",
    "profile": "Profilo professionale",
    "title_form": "Informazioni personali",
    "title_interview": "Tratti di personalità - Video intervista",
    "job_title": "Ruolo",
    "skills": "Interessi",
    "update_profile_success": "Il profilo è stato aggiornato correttamente.",
    "logout_cta": "Esci da Viblio",
    "logout_description": "Clicca sul pulsante “Esci da Viblio” per effettuare il logout.",
    "logout_description_2": "La prossima volta che accederai dovrai inserire nuovamente le credenziali.",
    "notification_title": "Preferenze email",
    "email_notification": "Ricevi email con suggerimenti sulla tua formazione",
    "notification_description": "Queste email ti permettono di ricevere ulteriori contenuti formativi, approfondimenti e consigli in base alle tue abitudini di apprendimento",
    "update_notification_success": "Le tue preferenze sono state aggiornate",
    "notification": "Ricevi email funzionali",
    "agreement_title": "Preferenze sulla condivisione dei dati",
    "agreement": "Condividi dati su formazione e interessi",
    "agreement_description": "Se scegli di non condividere le informazioni sul tuo percorso di apprendimento per la tua azienda sarà più complesso offrirti la formazione di cui hai bisogno e darti supporto.",
    "policies_title": "Termini e condizioni, privacy e cookie policy",
    "policies_term_link": "Termini e condizioni",
    "policies_privacy_link": "Privacy policy",
    "policies_cookie_link": "Cookie policy"
  },
  "agreement": {
    "title": "Benvenuto su Viblio!",
    "text": "Viblio è uno strumento aziendale che ti consenti di fare formazione e di sviluppare nuove competenze adottato da {nome}. Prima di iniziare il tuo percorso formativo, {nome} ti richiede di indicare la tua preferenza rispetto alla condivisione dei dati sui contenuti di apprendimento, che consentono alla tua azienda di sfruttare a pieno le potenzialità di Viblio agarantendoti formazione e supporto personalizzato e puntuale.",
    "button-ag": "Acconsento Condivisione",
    "button-not": "Non condividere",
    "undertext": "Se scegli di non condividere le informazioni sul tuo percorso di apprendimento per la tua azienda sarà più complesso offrirti la formazione di cui hai bisogno e darti supporto. Potrai modificare in ogni momento le tue preferenze nel tuo profilo."
  },
  "support": {
    "nav_bar_text": "Supporto",
    "categories": {
      "ho_bisogno_di_supporto": "Ho bisogno di supporto",
      "voglio_segnalare_un_errore_bug": "Voglio segnalare un errore (Bug)",
      "richiesta_di_nuove_funzionalita": "Richiesta di nuove funzionalità",
      "informazioni_sui_piani_pagamenti_fatturazione": "Informazioni sui piani, pagamenti, fatturazione",
      "annullamento_piano": "Annullamento piano",
      "contatta_un_commerciale": "Contatta un commerciale",
      "feedback_e_suggerimenti": "Feedback e suggerimenti",
      "domande": "Domande su Viblio",
      "errori_tecnici": "Errori tecnici",
      "attivita_amministrative": "Attività amministrative"
    },
    "customer_request": {
      "question": "Domande su Viblio",
      "task": "Ho bisogno di supporto",
      "incident": "Voglio segnalare un errore (Bug)",
      "payment": "Informazioni sui piani, pagamenti, fatturazione",
      "feedback": "Feedback e suggerimenti",
      "request_new_feature": "Richiesta di nuove funzionalità",
      "cancel_plan": "Annullamento piano",
      "contact_sales": "Contatta un commerciale"
    },
    "success": "La tua richiesta è stata inviata correttamente. Ti contatteremo al più presto.",
    "title": "Scrivi al supporto",
    "subtitle": "Compila seguenti campi e invia la tua segnalazione/domanda, il nostro team di supporto ti risponderà nel più breve tempo possibile.",
    "contact_label": "Come possiamo aiutarti?",
    "object": "Descrivi la tua richiesta",
    "cta": "Invia"
  },
  "expired": {
    "title": "Continua a apprendere con Viblio",
    "subtitle": "Non ci sono abbonamenti attivi sul tuo account. Torna alla tabella dei dei piani, scegli il piano più adatto alle tue esigenze e clicca su “Gestisci piano” e aggiungi un metodo di pagamento valido.",
    "cta": "Scegli piano e attiva Viblio",
    "help_cta": "Hai bisogno di supporto? contattaci a ",
    "footer_title": "Per ora non vuoi più continuare con Viblio?",
    "footer_subtitle": "Descrivici brevemente come mai. Il tuo feedback per noi è essenziale per migliorare il nostro prodotto",
    "footer_cta": "Aiutaci a migliorare"
  },
  "dashboard": {
    "nav_bar_text": "Dati in evidenza",
    "description": "In questa sezione è possibile visualizzare i dati analitici della tua organizzazione riferiti a utenti, team e dipartimenti",
    "tab_my": "I miei dati",
    "tab_org": "Dati aziendali",
    "back_all_members": "Torna a tutti gli utenti",
    "filters_title": "Filtri",
    "members": "Utenti",
    "contents": "Contenuti",
    "view_more": "Maggiori dettagli",
    "open_table": "mostra tabella",
    "go_to_user_dashboard": "Vai ai dati utente",
    "go_to_content": "Vai al contenuto",
    "modal_pagination": "{limit} di {total} mostrati",
    "no_data": "Nessun dato disponibile",
    "no_users_title": "Nessun utente trovato con i filtri attuali",
    "no_users_desc": "Prova a modificare i filtri per una corrispondenza migliore.",
    "popular_skill_per_users_chart_title": "Interessi più popolari",
    "popular_skill_per_users_chart_tooltip": "Principali interessi sviluppati indirettamente all’interno dell’organizzazione non correlate direttamente ai ruoli presenti.",
    "company_skill_chart_title": "Collezioni aziendali popolari",
    "company_skill_chart_tooltip": "",
    "top_users_chart_title": "Top users",
    "top_users_chart_tooltip": "Classifica degli utenti che hanno consultato più contenuti",
    "popular_skill_per_contents_chart_title": "Principali skills",
    "popular_skill_per_contents_chart_tooltip": "Classifica delle skill professionalizzanti dei ruoli presenti nella tua organizzazione per livello",
    "days_of_week_distribution_chart_title": "Visite per giorno della settimana",
    "days_of_week_distribution_chart_tooltip": "",
    "top_contents_chart_title": "Contenuti formativi più popolari",
    "top_contents_chart_tooltip": "",
    "soft_skill": "Soft skills",
    "soft_skill_tooltip": "",
    "hard_skill": "Hard skills",
    "hard_skill_tooltip": "",
    "tech_knowledge": "Skills Tecnologiche",
    "tech_knowledge_tooltip": "In questa sezione puoi trovare le skill di tendenza che potrebbero impattare sullo sviluppo del tuo ruolo nel breve periodo.",
    "no_tech_knowledge_for_role": "In questo momento non ci sono competenze disponibili per il ruolo selezionato dai sistemi occupazionali europei.",
    "company_collections_title": "Interazione con le collezioni aziendali",
    "company_collections_tooltip": "Ogni grafico rappresenta una collezione aziendale , la percentuale indica quanti contenuti sul totale della collezione sono stati aperti.",
    "top_contents_for_user_chart_title": "Contenuti che potresti considerare",
    "top_contents_for_user_chart_tooltip": "In questa sezione puoi trovare i contenuti che altri professionisti che ricoprono il tuo ruolo hanno visualizzato.",
    "search_focus_user_title": "Interessi",
    "search_focus_user_tooltip": "Skill che stai sviluppando non direttamente correlate al tuo ruolo attraverso i contenuti formativi che visualizzi",
    "collection_legend": "contenuto con cui hai interagito",
    "counter_total_origin_by": "Contenuti consultati",
    "counter_total_levels": "Livello contenuti formativi",
    "counter_content_total_levels": " a livello {level}",
    "counter_origin_by_user": "Da ricerca o collezioni personali",
    "counter_origin_by_share": "Da collezioni aziendali e condivise",
    "counter_origin_by_system": "Da collezioni suggerite da Viblio",
    "counter_level_beginner": "Principiante",
    "counter_level_intermediate": "Intermedio",
    "counter_level_advanced": "Avanzato",
    "user_chart_agreement": "Non è possibile consultare i <br /> contenuti dell’utente <br />seleziona perchè non ha <br />acconsentito alla condivisione <br />dei dati formativi.",
    "job_charts_row": "Competenze professionali riferite al tuo ruolo",
    "trend_charts_row": "Tendenze riferite al tuo ruolo",
    "your_focus_row": "Attitudini e interessi personali",
    "org_focus_trend_row": "Contenuti di tendenza per il tuo ruolo",
    "org_focus_row": "Contenuti condivisi dall’organizzazine",
    "improve_skill": "Migliora la skill: ",
    "progress_collection": "Collezione: {collectionName}",
    "content_searched_level": "Contenuti consultati di Livello {level}",
    "week_days": {
      "mon": "Lun",
      "tue": "Mar",
      "wed": "Mer",
      "thu": "Gio",
      "fri": "Ven",
      "sat": "Sab",
      "sun": "Dom"
    },
    "search_member": "Cerca utente",
    "skills": "Competenze",
    "points": "Punti",
    "top_5": "Top {limit}",
    "total_searched_members": " utente ha consultato contenuti riferiti alla competenza |  utenti hanno consultato contenuti riferiti alla competenza ",
    "total_interacted_members": " Utente che ha interagito con: |  Utenti che hanno interagito con: ",
    "collection_title": "collezione aziendale - ",
    "no_data_with_filter": "Nessun risultato trovato per “{search}”",
    "from_for_level": "Competenza “{skill}” sviluppata attraverso la consultazione dei seguenti contenuti formativi",
    "resources_title_from_org_bubble": "{totalUsers} utenti hanno consultato contenuti riferiti alla competenza “{skill}”",
    "resources_title_from_top": "{userFullName} ha consultato i seguenti contenuti formativi",
    "filters": {
      "search": "Aggiorna",
      "from_members": "Mostra dati singoli utenti",
      "organization_placeholder": "La tua Organizzazione",
      "occupation_placeholder": "Seleziona Ruoli",
      "group_placeholder": "Seleziona Teams e Dipartimenti",
      "limit": {
        "item0": "Tutti",
        "item10": "Top 10",
        "item20": "Top 20"
      }
    },
    "banner_manager_without_groups": {
      "title": "Al momento non è disponibile nessun dato perchè non hai creato il tuo team",
      "message": "In questa sezione potrai visualizzare i dati riferiti alla formazione dei tuoi team. \n\r Crea il tuo team per monitorare skill e interessi delle persone all’interno del team.",
      "cta_button": "Crea il tuo team"
    }
  },
  "notification": {
    "empty": "Nessuna Notifica"
  },
  "privacy": {
    "title": "PRIVACY POLICY",
    "header": "Artt. 13-14 Regolamento UE 27 aprile 2016 n. 679 “Regolamento Generale sulla Protezione dei Dati”",
    "hello": "Gentile Utente,",
    "subtitle": "Apprendo S.r.l. si impegna a rispettare la privacy dell’utente del sito nei termini previsti dalle normative applicabili sulla tutela dei dati personali ed in particolare il Regolamento (UE) 2016/679 (di seguito “Regolamento”).",
    "info": "Questo documento (“Privacy Policy”) fornisce informazioni sul trattamento dei dati personali raccolti dalla società APPRENDO S.r.l. tramite questo sito web (di seguito “Sito”) e pertanto costituisce informativa agli interessati ai sensi delle predette normative. L’informativa è resa solo per il presente Sito www.viblio.com e non anche per altri siti web eventualmente consultati dall’utente tramite link.",
    "title_1": "1. CHI È IL TITOLARE DEL TRATTAMENTO E IL DPO?",
    "answer_1": "Il titolare del trattamento è la società Apprendo S.r.l. con sede in 33100 Udine, via Marinoni n. 12 (P.I. 03029040304). Il Titolare è contattabile all’indirizzo email: ",
    "title_2": "2. CHE DATI PERSONALI RACCOGLIAMO E TRATTIAMO?",
    "answer_2_1": "Le categorie di dati personali che Apprendo S.r.l. può raccogliere durante la navigazione sul sito www.viblio.comsono le seguenti:",
    "answer_2_2": "trattiamo i dati della sua navigazione sul nostro sito, cioè quelli per i quali la trasmissione al Sito è necessaria per il funzionamento dei sistemi informatici preposti alla gestione del Sito e dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente.",
    "answer_2_3": "Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.",
    "answer_2_4": "trattiamo i dati personali che ci comunichi (oltre a nome, cognome, azienda, email) per fornirti le informazioni richieste;",
    "answer_2_5": "se hai scelto di partecipare alla community di Beta Test utilizzeremo la tua email per aggiornarti sugli sviluppi della piattaforma;",
    "answer_2_6": "utilizziamo i cookie, ossia dei piccoli file di testo che il sito web inserisce nei dispositivi in uso, che siano computer o dispositivi mobili, salvati nelle directory utilizzate dal browser web dell’utente. Vi sono vari tipi di cookie, alcuni per rendere più efficace l’uso del sito, altri per abilitare determinate funzionalità. Per maggiori dettagli consulta la Cookie Policy del sito.",
    "title_3": "3. PER QUALI FINALITÀ TRATTIAMO I DATI PERSONALI E QUAL È LA BASE GIURIDICA",
    "answer_3_1": "per il funzionamento dei sistemi informatici preposti alla gestione del Sito. Vengono trattati solo i dati di navigazione, cioè quelli per i quali la trasmissione al Sito è necessaria per il funzionamento dei sistemi informatici preposti alla gestione del Sito e dei protocolli di comunicazione di Interne",
    "answer_3_2": "Base giuridica: la condizione che rende lecito il trattamento è l’interesse legittimo del Titolare alla gestione tecnica relativa alla funzionalità ed alla sicurezza del sito.",
    "answer_3_3": "per fornirti assistenza e per rispondere ai tuoi quesiti.",
    "answer_3_4": "Base giuridica: La condizione che rende lecito il trattamento è costituita dall’esecuzione del contratto di cui sei parte.",
    "answer_3_5": "per aggiornarti sugli sviluppi della piattaforma qualora tu abbia deciso di partecipare alla community di Beta Test di Viblio (solo per privati).",
    "answer_3_6": "Base giuridica: La condizione che rende lecito il trattamento è costituita dall’esecuzione del contratto di cui sei parte.",
    "answer_3_7": "finalità necessarie ad accertare, esercitare o difendere un diritto in sede giudiziaria o legate al caso in cui le autorità giurisdizionali esercitino le loro funzioni.",
    "answer_3_8": "è interesse legittimo del Titolare all’esercizio o alla difesa di un diritto.",
    "title_4": "4. LA NATURA DEL CONFERIMENTO DEI DATI E CONSEGUENZE DELL’EVENTUALE RIFIUTO DI RISPONDERE",
    "answer_4_1": "Relativamente ai punti 3 a), 3 b) e 3 c) della presente informativa, il conferimento dei dati è necessario per adempiere gli obblighi legali e contrattuali. L’Utente è libero di fornirli ma l’eventuale rifiuto a fornirli determinerà l’impossibilità per Apprendo S.r.l. di gestire le tue richieste, di navigare sul sito, di informarti sulle fasi di sviluppo per partecipare alla versione beta test della piattaforma Viblio (solo per gli utenti privati) o di svolgere correttamente tutti gli adempimenti previsti dalle normative vigenti.",
    "title_5": "5. PER QUANTO TEMPO CONSERVIAMO I DATI?",
    "answer_5_1": "I dati saranno conservati per un periodo di tempo limitato diverso a seconda del tipo di attività che prevede il trattamento dei tuoi dati personali. Scaduto tale periodo, i tuoi dati saranno definitivamente cancellati o comunque resi anonimi in via irreversibile. I tuoi dati personali sono conservati nel rispetto dei termini e criteri di seguito specificati:",
    "answer_5_2": "dati relativi alla tua richiesta di assistenza vengono conservati sino al soddisfacimento della richiesta;",
    "answer_5_3": "in relazione alla finalità sub 3 c) - dati utilizzati per partecipare al beta test della piattaforma Viblio - sono conservati sino al soddisfacimento della richiesta. Se non fossi più interessato a partecipare a tale iniziativa e non vuoi più ricevere le nostre comunicazioni puoi esercitare il tuo diritto di opposizione tramite il link “unsubscribe” che trovi nelle comunicazioni che di volta in volta ti inviamo.",
    "title_6": "6. A CHI POTREBBERO ESSERE COMUNICATI I TUOI DATI PERSONALI?",
    "answer_6_1": "I tuoi dati personali potranno essere trattati da personale del Titolare, opportunamente formato e impegnato alla riservatezza, o di soggetti terzi che forniscono servizi strumentali alla Società, che agiscono tipicamente in qualità di responsabili del trattamento, quali gestori di servizi di comunicazione, posta elettronica, recapito della corrispondenza, servizi tecnici per la gestione del Sito, fornitori di servizi informatici. Tali soggetti riceveranno solo i dati necessari alle relative funzioni e si impegneranno a usarli solo per le finalità sopra indicate ed a trattarli nel rispetto della normativa privacy applicabile. L’elenco aggiornato dei responsabili del trattamento è disponibile presso le sedi del Titolare e verrà fornito a richiesta dell’interessato scrivendo al seguente indirizzo e-mail:",
    "title_7": "7. QUALI SONO I SUOI DIRITTI?",
    "answer_7_1": "Ha il diritto di ottenere informazioni riguardo il trattamento di dati personali che la riguardano, ottenerne l’accesso e copia anche mediante mezzi elettronici di uso comune.",
    "answer_7_2": "Inoltre, ha diritto di ottenere l'aggiornamento, l’integrazione o la rettificazione dei Suoi dati.",
    "answer_7_3": "Può chiedere la portabilità dei dati trattati in ragione dell’esecuzione di un contratto o sulla base del Suo consenso.",
    "answer_7_4": "Se ne ha interesse, ha il diritto di chiedere la cancellazione dei Suoi dati.",
    "answer_7_5": "Ha infine il diritto di chiedere la limitazione del trattamento o di opporsi, in tutto o in parte, al trattamento dei dati personali che la riguardano, anche per finalità di marketing.",
    "answer_7_6": "Se crede che una o più attività di trattamento da noi realizzate possano essere in violazione della normativa, o ritiene che non siano stati tutelati i Suoi diritti, può inviare un reclamo all’Autorità di Controllo del luogo in cui abitualmente risiede, lavora o dove si è verificata la presunta violazione. In Italia può rivolgersiall’Autorità Garante Privacy.",
    "answer_7_7": "Tali diritti possono essere esercitati scrivendo a mezzo posta ai recapiti sopra indicati oppure tramite posta elettronica al seguente indirizzo e-mail:."
  },
  "progress": {
    "cta": "Ogni grande viaggio si compie facendo un passo alla volta",
    "help": "In questa sezione potrai monitorare i tuoi progressi e quante cose hai imparato nel corso del tempo."
  },
  "price_table": {
    "flex": "FLEXIBLE & LIGHT",
    "full": "FULL-ON",
    "hint_jobrole": "SUGGERIMENTI PERSONALIZZATI DI CONTENUTI X COMPETENZE DI RUOLO",
    "hint_skill": "SUGGERIMENTI PERSONALIZZATI DI CONTENUTI X TEMATICHE D’INTERESSE",
    "interview": "VIDEO INTERVISTA CON ANALISI TRATTI DI PERSONALITA’ CON YOBS Technology",
    "custom_report": "Report Personalizzato (Big Five)",
    "custom_report_collections": "Report Personalizzato (Big Five) + Collezione personalizzata",
    "metasearch": "METASEARCH",
    "available_contents_over": "oltre ",
    "available_contents_flex_count": " 25.000 ",
    "available_contents_full_count": " 14.000 ",
    "available_contents_flex": " contenuti, tra CORSI, PODCAST, WEBINAR e PROGETTI (con pagamento diretto sui provider)",
    "available_contents_full": " contenuti, tra PODCAST, WEBINAR, CORSI e PROGETTI (tutti i contenuti di Coursera inclusi nell’abbonamento)",
    "certifications": "CONTENUTI CON CERTIFICAZIONI INCLUSI",
    "certifications_flex": "Le certificazioni",
    "certifications_flex_strong": "sono a  pagamento nelle piattaforme dei provider che le propongono,  secondo le loro condizioni economiche.",
    "certifications_full_count": "9.000",
    "certifications_full": " corsi con certificazione",
    "certifications_full_included": "INCLUSI NELL’ABBONAMENTO",
    "provider": "PROVIDER",
    "subscriptions": "ABBONAMENTI",
    "subscriptions_cta": "Acquista Ora"
  },
  "subscription": {
    "title": "Purtroppo non è possibile usare Viblio",
    "subtitle": "Sembra che il piano associato alla tua organizzazione sia scaduto.",
    "contact_supervisor": "Contatta il referente {supervisor} per maggiori informazioni."
  }
}