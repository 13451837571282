<template>
  <div
    class="banner"
    data-test="interview-result-box"
  >
    <div class="banner-image" />
    <div class="banner-content">
      <h4 class="text-banner-title">
        {{ $t('home_box.has_interview_title') }}
      </h4>
      <div
        v-if="userInterviewState.isReady()"
        data-test="has-interview-success"
      >
        <span class="text-banner-description">
          {{ $t('home_box.has_interview_msg') }}
        </span>
        <a
          class="button button-primary"
          @click="getReportApi"
        >
          <div>
            {{ $t('home_box.has_interview_cta') }}
          </div>
        </a>
      </div>
      <div
        v-if="userInterviewState.isProcessing()"
        data-test="has-interview-processing"
      >
        <span class="text-banner-description">
          {{ $t('home_box.has_interview_processing_msg') }}
        </span>
      </div>
      <div
        v-if="userInterviewState.isFailed()"
        data-test="has-interview-failed"
      >
        <span class="text-banner-description">
          {{ $t('home_box.has_interview_failed_msg') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getReport,
} from "@/api/user.js";
import { useInterview } from "@/composables/userData.js";
export default {
  setup() {
    const { userInterviewState}  = useInterview();
    const getReportApi = async () => {
      //const {user} = useUserData();

      await getReport().then((bytes) => {
        let blob = new Blob([bytes.data], { type: "application/pdf" });
        let url = window.URL || window.webkitURL;
        let l = url.createObjectURL(blob);

        var link = document.createElement("a");
        link.href = l;
        link.setAttribute("download", "report.pdf");
        link.click();
        link.remove();
      });
    };
    return {
      getReportApi,
      userInterviewState
    };
  }

}
</script>
<style scoped>
.banner{
  min-height: 190px;
}
.banner-image {
  background-image: url("../assets/man-telephone.svg");
  background-size: cover;
  background-position: top;
}
</style>
