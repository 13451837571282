{
  "on_boarding": {
    "header_title": "Hello {name}, welcome to Viblio!",
    "header_subtitle": "I am your digital guide to keep up with your work, the skills that are currently in demand, and those that will be essential in the future.<br>You tell me what job you do, and I show you what there is to learn.<br>Thanks to my artificial intelligence, I search and select the content from the world's best digital platforms for you: not only courses but also podcasts, videos, articles.<br>The best time to start is now!",
    "role_edit": "Edit roles",
    "showing_n_job_roles": "{tot} results",
    "show_search_help": "If it represents you, click 'Proceed to the next step'. If we're not quite there yet, you can add another {item} by searching in my database.",
    "next_step": "Proceed to the next step | I don't want to add any more skills | Confirm",
    "next_button": "Next",
    "back_button": "Back",
    "finish_button": "Start, enter Viblio",
    "finish_button_short": "Start",
    "terms_and_conditions": "Terms and conditions",
    "role": {
      "label": "Role",
      "hello": "Hi {name}, I'm Viblio, your digital mentor!<br/>In the next few minutes I will ask you some questions to suggest the most suitable training contents for you!",
      "second": "<strong>Let's start: What is your role?</strong><div class='text-sm'>Search my database by entering the words that describe your role. You can select up to 2 roles</div>",
      "third": "Is this the only role you cover? If you are satisfied, select “Next”, otherwise add another role.",
      "forth": "Your roles have been saved. Select “Next” to proceed",
      "first_role_selected": "Selected role",
      "second_role_selected": "Second selected role",
      "search_placeholder": "Describe your role...",
      "error": "Select at least one role",
      "no_results": {
        "header": "No results found",
        "content": "Unfortunately, it was not possible to find your role, try with a similar role. Our international role database is constantly expanding, I'm sure there will be soon!"
      }
    },
    "preauthentication": {
      "login": {
        "title": "Authentication in progress...",
        "body": "We are completing your authentication. You will be redirected to Viblio shortly. Thank you for your patience."
      },
      "logout": {
        "title": "Please wait...",
        "body": "You are logging out of Viblio, please wait. Thank you for your patience."
      }
    },
    "skill": {
      "label": "Skill",
      "first": "<div>I already feel like I know you a little better! I know you are <strong>{roles}</strong>.<br/>Now I'm interested in knowing what makes you unique!</div>",
      "second": "<strong>What are the skills that make you unique?</strong><div class='text-sm'>Select up to 5 skills or interests you want to learn or train!<br/>(The selection of interests is optional)</div>",
      "third": "I have saved all your skills. Select 'Next' to go to the summary",
      "skill_selected": "Skill",
      "search_placeholder": "Search for a skill...",
      "no_results": "No results found"
    },
    "summary": {
      "label": "Summary",
      "first": "<div><strong>I'm building learning content collections</strong> starting from the information you gave me:</div>",
      "second": "Check your data and select 'Start your training'.<br/>To change your selection, click on the edit icon.",
      "roles": "<strong>Your roles</strong>{roles}",
      "skills": "<strong>Your skills</strong/>{skills}"
    }
  },
  "modal_mobile": {
    "title": "Log in from PC to continue your training",
    "text": "Sorry, Viblio is not yet fully optimized for mobile devices: we are working to guarantee you a better user experience.",
    "button": "Back to previous page"
  },
  "sidebar": {
    "for_you": "For you",
    "collections": "Collections",
    "collections_personal": "Personal collections",
    "collections_company": "Company collections",
    "collections_shared": "Shared collections",
    "analysis": "Analysis",
    "organization_title": "Business Settings",
    "organization": "Organization",
    "overview": "Overview",
    "team": "Team Settings",
    "settings": "Settings",
    "departments": "Departments",
    "teams": "Teams",
    "skills": "Manage Skills",
    "users": "Users",
    "plans": "My Plan",
    "subscriptions": "Manage Subscriptions",
    "home": "Home",
    "explore": "Explore",
    "explore-results": "Results",
    "dashboard": "Dashboard",
    "manage": "Manage",
    "missing_payment_title": "Your plan expires in 10 days",
    "missing_payment_text": "Add a payment method to continue using Viblio",
    "interview_banner": "Complete the video interview",
    "interview_banner_start": "Start the video interview",
    "interview_banner_failed": "Retry the video interview",
    "interview_banner_progress": "Interview analysis in progress",
    "interview_banner_download": "Download interview Report",
    "company_collection": "Company collections",
    "personal_collection": "My collections",
    "shared_with_me_collection": "Shared with me",
    "wait_first_org_shared_collection_banner": "There are no collections shared by your company",
    "wait_first_org_shared_collection_banner2": "This section will show the collections shared with you by your company",
    "wait_first_shared_collection_banner": "There are no collections shared with you",
    "wait_first_shared_collection_banner2": "This section will show collections shared with you by your team or department",
    "first_personal_collection_banner": "Create your first collection now! Save content, organize your insights, and begin building your curated knowledge hub."
  },
  "for_you": {
    "title": "All the content for you",
    "description": "In this section you can find tailored content for your role and interests created for you by Viblio",
    "global_collections": "Expert Collection | Expert collections"
  },
  "card": {
    "view_all": "View all",
    "free": "Free",
    "more_details": "More details"
  },
  "interview": {
    "not_done_title": "Ready to level up your skills?",
    "not_done_subtitle": "Discover your strengths with the video interview!",
    "not_done_body": "Complete the video interview and discover personality traits that identify your strengths and potential for improvement that impact your role.",
    "not_done_cta": "Start the video interview",
    "report_ready_title": "Download your video interview report!",
    "report_ready_subtitle": "Discover your strengths and potential areas for improvement.",
    "report_ready_button": "Download the report",
    "created_title": "Finish the video interview to discover your personality traits",
    "created_subtitle": "Complete the video interview and discover your strengths and potential areas for improvement.",
    "created_button": "Continue the interview",
    "in_progress_title": "We are developing your your personality traits",
    "in_progress_subtitle": "We are analyzing your interview. You will receive an email when your report is ready",
    "modal_header_initialize": "Start the video interview and identify and develop your potential",
    "modal_header_continue": "Continue the video interview and identify and develop your potential",
    "modal_save_label_initialize": "Start the video interview",
    "modal_save_label_continue": "Continue the video interview",
    "modal_body_first": "Viblio uses the YobsTech artificial intelligence engine and an evaluation mechanism based on the standards of the American Psychological Association (APA) to analyze your responses and suggest the most suitable learning path for you.",
    "modal_body_second": "At the end of the video interview, you will receive a detailed report with your strengths and areas for improvement, to analyze the results in depth firsthand.",
    "modal_body_third": "The video interview lasts only 6 minutes and you will have to answer 3 questions, and for each one, you will have 1 minute to prepare and 1 minute to respond.",
    "modal_accept_privacy": "I accept the <a href='https://www.viblio.com/en/privacy-policy-en/'>privacy policy</a> of the service",
    "modal_accept_privacy_error": "You must accept the privacy policy to continue",
    "modal_cancel": "Cancel"
  },
  "subscriptions": {
    "expiration_date": "Expiration Date",
    "is_expirable": "Has it an expiration?"
  },
  "plans": {
    "select": "Select a Plan",
    "plan": "Plan",
    "subscription": "Plan",
    "start_date": "Subscriptions",
    "expiration_date": "Expiration Date",
    "renewal_date": "Renewal",
    "total_license": "Number of Subscriptions",
    "used_license": "Users",
    "trial_period": "Trial period",
    "none": "None",
    "cancel_plan": "Cancelled",
    "expired": "Expired",
    "pending": "Pending",
    "payed": "Payed",
    "plan_id": "Plan Id: {id}",
    "validity": "Validity",
    "validity_val": "From {start} to {end} ({frequency})",
    "msg_error_saving": "An error occurred while saving. Please try again later. {errormsg}",
    "msg_success_created": "Plan created successfully",
    "msg_success_updated": "Plan updated successfully",
    "msg_payed_success": "Plan payment update saved successfully",
    "msg_payed_failed": "Plan payment update failed",
    "no_subscription": "There aren't any subscriptions associated to this plan",
    "select_user": "Select users for associating this plan",
    "hide_expired": "Hide expired entities",
    "show_expired": "Show expired entities too",
    "manage_action": "Manage plan",
    "banner_upgrade_to_business": {
      "title": "Feature available with business plan",
      "message": "Click on “Change plan” to start using it immediately.",
      "cta_button": "Change plan"
    },
    "banner_limit_user_in_trial_period": "In the trial period you can create a maximum of 10 users to test the platform",
    "banner_trial": {
      "title": "Plan {plan} - Trial period",
      "message": "The trial period expires today. On {expireDate} your plan will be deactivated if you do not enter a payment method by clicking “Manage plan”. | The trial period expires in {totalDays} days. On {expireDate} your plan will be deactivated if you do not enter a payment method by clicking “Manage plan”.",
      "description": "To use the functions marked with the padlock icon you must switch to the “Business” plan: Try it for free. "
    },
    "banner_trial_with_payment": {
      "title": "Plan {plan} - Trial period",
      "message": "The trial period expires today but you don't have to do anything. | The trial expires in {totalDays} days but you don't have to do anything.",
      "description": "To use the functions marked with the padlock icon you must switch to the “Business” plan: Try it for free. "
    },
    "banner_expired": {
      "title": "You plan is expired! Click on “Manage plan” and add payment methode to continue using Viblio.",
      "message": "If you have any doubts please contact us at "
    },
    "banner_canceled": {
      "title": "",
      "message": "There are no active subscriptions on your account, click on “Select plan” to activate a subscription."
    },
    "banner_canceled_still_valid": {
      "title": "",
      "message": "You have canceled your plan: you will be able to use Viblio until {expireDate}. \n\rIf you wish to reactivate your plan, click “Manage plan” and select “Renew plan”"
    },
    "banner_pending": {
      "title": "Errors were detected on the payment method entered so it was not possible to renew or activate the plan.",
      "message": "Click on “Manage plan”, verify the payment method and click on “pay the amount due” to reactivate the plan. If you have any doubts, contact us at "
    },
    "banner_payment_error": {
      "title": "Errors occurred in the payment method added. Click “Manage plan” to solve them.",
      "message": "You will be able to use Viblio until the next renewal."
    },
    "info": {
      "title": "Viblio Plans",
      "description": "If you are interested in subscribing to add-ons",
      "contact_us": "Contact us",
      "user_month": "Per user / month",
      "includes": "What includes:",
      "includes_desc": "In addition to the plan “{planName}”, includes:",
      "upgrade": "Upgrade",
      "downgrade": "Downgrade",
      "actual_plan": "Current plan",
      "choose_plan": "Select Plan",
      "current": "Selected",
      "personal": {
        "title": "Personal",
        "users": "1 user",
        "features": {
          "0": "Individual learning path.",
          "1": "Onboarding and individual interview.",
          "2": "Personal collections",
          "3": "Personal Dashboard"
        }
      },
      "business": {
        "title": "Business",
        "users": "2 to 250 users",
        "features": {
          "0": "Corporate collections",
          "1": "Corporate Dashboard",
          "2": "Manage users, teams, departments, roles",
          "3": "Add-on: premium course catalogue, company set up, expert collections"
        }
      },
      "enterprise": {
        "title": "Enterprise",
        "users": "More than 251 users",
        "price": "Custom price",
        "price_desc": "Annual subscription",
        "features": {
          "0": "Custom roles and skills",
          "1": "SAML-based single sign-on (SSO)",
          "2": "Add-on: Gamification and marketplace"
        }
      },
      "values": {
        "range_2_250": "2 to 250",
        "more_250": "More than 250",
        "unlimited": "Unlimited",
        "no": "No",
        "one": "1",
        "add_on": "Add on *"
      },
      "add_on_notes": "(*) All add-ons require a subscription to an annual plan",
      "features": {
        "users": "Users",
        "teams": "Teams",
        "departments": "Departments",
        "organizations": "Organizations",
        "custom_path": "Custom learning path",
        "custom_path_desc": "Creation of a personalized training path starting from the selection of role, skills and interests",
        "interview": "Video interview about personality",
        "interview_desc": "Video interview which, through artificial intelligence, defines personality traits and personal attitudes",
        "system_coll_occ": "Suggested content for hard and soft skills",
        "system_coll_occ_desc": "Suggested content referring to the role's hard and soft skills",
        "system_coll_skill": "Suggested content for skills",
        "system_coll_skill_desc": "Suggested contents related to skills and interests (max 5)",
        "system_coll_hot_tech": "Suggested content for tech skills",
        "system_coll_hot_tech_desc": "Suggested content related to technology skills and trends",
        "system_coll_soft": "Contents suggested for personal aptitudes",
        "system_coll_soft_desc": "Suggested contents referring to personality traits and personal attitudes",
        "search": "Course search engine",
        "search_desc": "Course search engine with more than 55,000 contents including videos, courses, podcasts, talks and webinars",
        "free_providers": "Free content catalog",
        "free_providers_desc": "Free course catalog from all our providers (more than 25,500) such as POLIMI, Udemy, Udacity, EdX, Future Learn, Coursera, TED, Harvard Business School, Spotify, Youtube playlist and more.",
        "udemy_providers": "Udemy premium content catalog",
        "udemy_providers_desc": "Udemy paid course catalog with 10,000 certifications",
        "udemy_providers_note": "Min 21 users",
        "future_providers": "Future Learn premium content catalog",
        "future_providers_desc": "Future Learn paid course catalog with 5,000 certifications",
        "future_providers_note": "Min 50 users",
        "coursera_providers": "Coursera premium content catalog",
        "coursera_providers_desc": "Coursera paid course catalog with 9,000 certifications",
        "coursera_providers_note": "Min 150 users",
        "collections": "Personal collections",
        "collections_desc": "Create personal collections",
        "org_collections": "Corporate Collections",
        "org_collections_desc": "Create corporate collections by team and department",
        "global_collections": "Expert Collections",
        "global_collections_desc": "Expert collections and tailor-made training courses to achieve business objectives",
        "yearly_subscription_note": "For annual subscription only",
        "share_collections": "Sharing collections",
        "dashboard": "My analytics dashboard",
        "dashboard_desc": "Personal analytical dashboard for analyzing data on training and the progress of your growth",
        "org_dashboard": "Enterprise Analytics Dashboard",
        "org_dashboard_desc": "Corporate analytics dashboard for analyzing training and growth data, filterable by team and department",
        "management": "Management of collaborators, teams, departments",
        "management_desc": "Creation and management of collaborators, teams, departments",
        "roles_management": "Role management",
        "roles_management_desc": "Assignment of roles to all collaborators (manager, supervisor, etc.)",
        "std_setup": "Company standard setup",
        "std_setup_desc": "Collaborative Artificial Intelligence for company standard setup",
        "onboarding": "Onboarding and Support",
        "onboarding_desc": "Onboarding and Support which includes a dedicated Learning Architect who will support you with Set Up Profiles, Launch and Follow Up Webinars, collection, tailor-made, dedicated help desk",
        "custom_setup": "Custom company setup",
        "custom_setup_desc": "Custom corporate setup",
        "custom_roles": "Custom roles and skills",
        "custom_roles_desc": "Custom roles and skills integration",
        "org_providers": "Enterprise Content Catalog",
        "org_providers_desc": "Integration with proprietary company course catalog",
        "sso": "Single sign-on (SSO) ",
        "sso_desc": "SAML-based single sign-on (SSO)",
        "api": "Access to API",
        "data": "Data Management",
        "data_desc": "Import & Export of data from the platform",
        "performance": "Employee Tracking & Performance MGMT",
        "performance_desc": "Employee Tracking & Performance MGMT",
        "game": "Gamification and marketplace",
        "game_desc": "Platform marketplace and gamification-based engagement techniques"
      }
    },
    "miss_payment_modal": {
      "title": "There is no payment method",
      "subtitle": "to activate Viblio you must first add a payment method.<br> Click on “Enter payment method” and then select the plan you want to activate.",
      "confirm": "Enter payment method"
    },
    "change": {
      "title": "Change plan",
      "description": "Select plan type and billing period.",
      "type_of_plan": "Type of plan",
      "billing_frequency": "Billing",
      "summary_title": "Summary",
      "summary_actual_plan": "Current plan “{plan}” ",
      "summary_selected_plan": "Selected plan “{plan}”",
      "summary_current_case": "€ {amount} / month ( {monthly_amount} / month  € x {users} user)",
      "summary_actual_plan_amount": "Current plan: {amount} €",
      "summary_selected_plan_amount": "Selected plan: {amount} €",
      "summary_total": "Total to pay: € {amount}/month (VAT included)",
      "summary_downgrade": "Until the date indicated you will continue to benefit from the features of the “Business” plan",
      "summary_next_renewal": "Next charge: {renewal_date}",
      "footer_cta": "By selecting “Confirm selected plan”, I declare that I understand that I am subscribing to a subscription plan and I authorize Viblio to charge the current list price to the selected payment method, unless cancelled. I can cancel my subscription at any moment by going to the “Manage plan” page.",
      "footer_downgrade": "We noticed that you have created multiple users with your Business plan. By selecting a personal plan that includes only 1 user, only the user with whom you registered on Viblio will remain active, the others will be disabled and will not be able to log in. To give your team access again, you can subscribe to the “Business” plan at any time.",
      "cta_button": "Confirm selected plan",
      "change_plan_success": "Your plan has been successfully updated",
      "change_plan_error": "An error occurred while changing plans. Please try again later. {errormsg}",
      "summary_no_selected_plan": "No plan selected",
      "active_plan_success": "Your plan has been activated successfully",
      "active_plan_error": "An error occurred while activating the plan. Please try again later. {errormsg}",
      "plan_type_personal_label": "Personal (9,00€ user/month) - 1 user",
      "plan_type_business_label": "Business (12,00€ user/month) - 1 user",
      "billing_frequency_monthly": "Monthly",
      "billing_frequency_yearly": "Yearly"
    },
    "setpayed_action": "Set as Payed"
  },
  "users": {
    "first_name": "Name",
    "last_name": "Surname",
    "filter_name": "Name Surname",
    "email": "Email",
    "plan": "Plan",
    "profile": "Profile",
    "profile_help": "Business User Plan",
    "profile_flex": "Flexible",
    "profile_fullon": "FULLON",
    "switch_tofull": "Switch to Full-On",
    "switch_toflex": "Switch to Flexible",
    "role": "Role",
    "role_help": "All roles allow you to train with Viblio. Supervisor (user who manages users, teams and departments, create and share collections), manager (user who can create teams and create and share collections), member (can do training and create personal collections).",
    "team_counter": "Teams",
    "info": "User information",
    "owner_teams": "Team to which you belong",
    "department_name": "Department",
    "department_help": "Select the department you belong to (you can also assign users to departments later)",
    "is_active": "Status",
    "is_active_help": "A user's status can be active or inactive. Each active user has a monthly or annual license.",
    "is_active_banner": "Deactivated users cannot access the platform and do not contribute to the total price of the licenses.",
    "is_active_true": "Active",
    "is_active_false": "Disabled",
    "agreement": "Share info",
    "agreement_true": "Yes",
    "agreement_false": "No",
    "deactivate_action": "Deactivate",
    "activate_action": "Activate",
    "manage_action": {
      "tooltip": "Switch subscription",
      "title": "Are you sure you want to convert this user to {switchTo} ?",
      "subtitle": "The operation will cost {switchToMsg} of price.",
      "subtitle_full_on": "an increase",
      "subtitle_flex": "a diminution",
      "message": "The organization will be notified of the new amount.",
      "message_full_on": "can enjoy",
      "message_flex": "cannot enjoy",
      "message_success": "The conversion was successful",
      "message_success_msg": "User {name} {switchToMsg} now of Full-on benefits",
      "message_failed": "Error"
    },
    "confirm_remove": {
      "message": "User data will be deleted. All associated subscriptions will be deleted too.",
      "remove": "Delete",
      "subtitle": "Are you sure you want delete <strong>{name}</strong>? ",
      "title": "Delete User"
    },
    "no_select_record": "There are no users to select",
    "no_select_record_add_now": "Add new user",
    "deleted": "The organization has been deleted"
  },
  "general_table": {
    "search": "Search",
    "no_record": "No record",
    "no_record_add_now": "Add first record",
    "add_record": "New Record",
    "actions": "Actions",
    "action_not_available": "Not available in backend yet.Coming soon.",
    "confirm_remove": {
      "message": "The record will be delete.",
      "remove": "Delete",
      "subtitle": "Are you sure you want to delete <strong>{name}</strong>?",
      "title": "Delete"
    }
  },
  "collections": {
    "title_": "No",
    "title": "Collections",
    "create": "Create new collection",
    "create_cta": "Create collection",
    "create_desc": "Enter the name of the new collection you want to create and click on “Create collection” to add the content to the new collection",
    "create_success": "The collection {collection} has just been created!",
    "create_add_success": "Content added to the newly created {collection}",
    "collection": "Collection ",
    "system_hard": "Hard Skills",
    "system_soft": "Soft Skills",
    "system_hot_tech": "Tech Skills",
    "system_interview": "Personality Traits",
    "system_skill_desc": "Description of Viblio collection of this skill.",
    "add_and_count_empty": "Add to a collection",
    "add_and_count_many": "Present in {count} collections",
    "add_and_count_one": "Present in 1 collection | Present in {count} collections",
    "add_to_a_collection": "Do you want to add this content to a collection?",
    "add_to_a_new_collection": "Enter the name of the new collection you want to create and click on “Create and add” to add the content to the new collection",
    "add_to_collection": "Add to Collection",
    "in_collection": "Added to collections",
    "added_to": "Added to: ",
    "collection_under_construction": "Some of the results are being curated for your professional profile.",
    "collection_created": "The new collection <strong>{name}</strong> has been successfully created.",
    "collection_updated": "The collection <strong>{name}</strong> has been successfully updated.",
    "collection_deleted": "The collection has been successfully deleted.",
    "confirm_remove_course_title": "Do you want to remove content from the collection?",
    "confirm_remove_course_subtitle": "Are you sure you want to remove the selected content from the collection?",
    "confirm_remove_course_message": "To confirm click on “Remove contents”.",
    "confirm_remove_course_button": "Remove contents",
    "confirm_delete_title": "Do you want to delete the collection?",
    "confirm_delete_subtitle": "Are you sure you want to delete the collection {collection}? Deleting the collection will delete all favorite content within it.",
    "confirm_delete_message": "To confirm click on “Delete collection”.",
    "confirm_delete_button": "Delete collection",
    "confirm_unshare_title": "Do you want to stop sharing the collection?",
    "confirm_unshare_subtitle": "If you stop sharing, people will no longer be able to consult the learning content in their portal in shared collections.",
    "confirm_unshare_message": "Select “Stop sharing” to confirm.",
    "confirm_unshare_button": "Stop sharing",
    "shared_msg": "You have shared your collection",
    "unshared_msg": "Your personal collection is no longer shared",
    "course_added": "The content has been added to collection | The content has been added to collections ",
    "courses_count": "contents",
    "create_new": "Create and add",
    "create_new_subtitle": "Give your collection a name and description to keep your content organized",
    "edit_collection": "Edit collection name",
    "edit_collection_help": "Edit the name of your collection and click on the “Save” button to confirm.",
    "new_collection": "New Collection",
    "new_collection_name": "Collection name",
    "new_collection_button_text": "or create a new collection",
    "new_collection_description_placeholder": "Is there any additional detail you want to add?",
    "new_collection_title_placeholder": "What is this collection about?",
    "no_collections_cta": "Create your first collection",
    "no_collections_description": "To create your first collection click on the “New Collection” button",
    "no_collections_title": "You have no personal collection",
    "remove_from_collection": "Remove from Collection",
    "resource_removed": "The selected content has been removed from the collection",
    "share_btn": "Share",
    "share_collection_title": "Share collection",
    "share_collection_org": "Share with all organization",
    "share_collection_global": "Share with the Viblio community",
    "share_collection_with_group": "Share with selected people, teams and departments",
    "share_collection_group_placeholder": "Select teams and/or departments",
    "shared_with": "Shared with:",
    "shared_with_org": "All company",
    "shared_with_global": "All community",
    "shared": "Already Shared",
    "share_title": "Sharing Collection",
    "share_subtitle": "Select who you want to share the collection with.",
    "edit_share_org_subtitle": "Edit which organizations can access the collection.",
    "edit_share_group_subtitle": "Edit who or what group in {organization} can access the collection.",
    "share_radio_error": "Select at least one recipient to share the collection",
    "share_groups_users": "Teams, departments or members",
    "edit_share_groups_users": "Add teams, departments or members",
    "shared_global": "Shared with the Viblio community",
    "users": "{total} person | {total} persons",
    "SHARE_NONE": "Private",
    "SHARE_ORG": "Shared with your organization",
    "SHARE_GLOBAL": "Shared with the Viblio community",
    "back_to_list": "Go to the collections list",
    "personal_title": "Personal Collections",
    "company_title": "Company Collections",
    "shared_with_groups_title": "Shared Collections",
    "personal_list_subtitle": "Collections help you reach your growth goals faster! Create your own collections to save and organize your favorite content.",
    "company_list_subtitle": "In this section you will find the collections shared by your company",
    "shared_with_me_list_subtitle": "In this section you will find collections shared with you.",
    "shared_with_group_title": "Shared with your team or department",
    "shared_with_user_title": "Shared with you",
    "classification_hard": "Hard Skills",
    "classification_soft": "Soft Skills",
    "classification_hot_tech": "Hot Technologies Skills",
    "classification_interview": "Personality Traits Collection",
    "title_job_role": "Competenze per il tuo ruolo: {jobName}",
    "title_job_role_desc": "Qui sotto trovi i contenuti che Viblio ha selezionato per te per stare al passo con il tuo ruolo da un punto di vista tecnico, (non puoi farne a meno!).",
    "title_skill": " Since you have chosen: {skillName}",
    "title_skill_desc": "",
    "title_from_interview_desc": "",
    "title_from_interview": "Transferable Skills / My Personality",
    "unshare_collection": "Stop sharing",
    "user_collections": "Personal Collections",
    "private_empty": "There is no content in this collection",
    "private_empty_desc": "There is no content in this collection. You can add content from the list or from the detail of a training content available on Viblio.",
    "shared_with_me_empty": "There is no content in this collection",
    "shared_with_me_empty_desc": "There is no content in this collection but content selected and suggested by your team or department will soon be shown",
    "shared_with_org_empty_desc": "There is no content in this collection but content selected and suggested by your organization will soon be shown"
  },
  "course_detail": {
    "access_needs_account": "Access to this course is reserved for registered users. Click here to request information.",
    "access_needs_upgrade": "To access this course, upgrade to the Full On plan. Click here to request information.",
    "access": "Access Course",
    "access_podcast": "Listen to the podcast",
    "unavailable": "Content Unavailable",
    "unavailable_desc": "Content no longer available from the provider",
    "full_on_free": "FREE by FullOn Plan",
    "free": "FREE",
    "payed": "PAYED",
    "resources": {
      "course": "Course",
      "podcast": "Podcast",
      "video": "Video",
      "document": "Document",
      "webinar": "Webinar",
      "youtube_playlist": "Youtube Playlist",
      "codecademy_playlist": "Codecademy Playlist",
      "access_content": "Access the Content",
      "access_course": "Access the Course",
      "access_podcast": "Listen the Podcast",
      "access_video": "Watch the Video",
      "access_document": "Read the Document",
      "access_webinar": "Access the Webinar",
      "access_youtube_playlist": "Access the Youtube Playlist",
      "access_codecademy_playlist": "Access the Codecademy Playlist"
    },
    "content_level": "CONTENT LEVEL",
    "skills_level": "LEVEL UP YOUR SKILLS"
  },
  "course_results": {
    "cost": "Price Range",
    "provider": "Provider",
    "language": "Language",
    "filters": "Search Filters",
    "reset_filters": "Reset Filters",
    "searching": "searching...",
    "active_filters": "Active Filters:",
    "course_tabs": {
      "author_many": "Authors",
      "author_one": "Author",
      "description": "Description",
      "requisites": "Prerequisites",
      "source": "Source"
    },
    "effort": "Effort",
    "effort_periods": {
      "months_many": "months",
      "months_one": "month",
      "weeks_many": "weeks",
      "weeks_one": "week"
    },
    "effort_values": {
      "less:2:hours": "Up to two hours",
      "less_2_hours": "Up to two hours",
      "more:1:months|less:3:months": "1-3 months",
      "more_1_months__less_3_months": "1-3 months",
      "more:1:weeks|less:4:weeks": "1-4 weeks",
      "more_1_weeks__less_4_weeks": "1-4 weeks",
      "more:3:months": "More than 3 months",
      "more_3_months": "More than 3 months"
    },
    "level": "Level",
    "level_values": {
      "Advanced": "Advanced",
      "Beginner": "Beginner",
      "Intermediate": "Intermediate",
      "exclude_others": "For everyone",
      "Anyone": "For everyone"
    },
    "no_results": "It seems that your search for <strong>\"{searchTerm}\"</strong> did not yield any results.",
    "price": "Price Range",
    "price_values": {
      "all": "All",
      "pay": "PAYMENT",
      "free": "FREE",
      "full_on_free": "FullOn FREE"
    },
    "not_only_fullon": "Included",
    "only_fullon": "Full On Only",
    "tot_results_many": "{count} results for",
    "tot_results_one": "One result for",
    "try_changing_text": "Try checking the search terms",
    "try_changing_text_or_filters": "Try modifying the active filters or checking the search terms",
    "type": "Content Type",
    "type_values": {
      "course": "Online Course",
      "podcast": "Podcast",
      "webinar": "Webinar",
      "video": "Video",
      "youtube_playlist": "YouTube Playlist",
      "youtube playlist": "YouTube Playlist",
      "codecademy_playlist": "Codecademy Playlist",
      "codecademy playlist": "Codecademy Playlist"
    },
    "uh_oh": "UH-OH."
  },
  "search": {
    "title": "Hello!",
    "description": "What do you want to learn today?",
    "search_button": "Search",
    "results_found": "results found",
    "filters": "Filters",
    "all_types": "All content types",
    "all_prices": "All price ranges",
    "no_results": {
      "title": "Oh no! No results found for “{query}”",
      "description": "We couldn't find any learning content that matches the term entered in our catalog. What can you do?",
      "step_1": "Change the entered term",
      "step_2": "Check the applied filters",
      "step_3": "Try modifying your search",
      "last_paragraph": "Our catalog is constantly expanding, so you will find more learning content in the future."
    }
  },
  "entities": {
    "hide_soft_deleted": "Hide deleted entities",
    "show_soft_deleted": "Show deleted entities"
  },
  "Error": "Error",
  "errors": {
    "generic_msg": "Save error, please try again",
    "api_down": "We are experiencing issues with our server.",
    "failed_login": "Login failed. Please check your email and password.",
    "failed_logout": "Logout failed.",
    "failed_registration": "Registration failed. Please check your information and try again.",
    "general_title": "An error occurred while saving. Please try again.",
    "general_msg": "If the error persists, please try again later. ",
    "general": "An error occurred. Please try again later.",
    "logout": "Logout Error",
    "offline": "It seems that the connection is not available.",
    "retry": "Retry",
    "session_expired": "Your session has expired. We are redirecting you to the login page."
  },
  "footer": {
    "about_us": "About Us",
    "contact_us": "Contact Us",
    "terms": "Terms of Privacy and Cookie Policy",
    "about_us_link": "https://www.viblio.com/about",
    "contact_us_link": "https://www.viblio.com/contacts",
    "terms_link": "https://www.viblio.com/privacy"
  },
  "general": {
    "add": "Add",
    "show": "Show",
    "new": "New",
    "mark_as_completed": "Mark as completed",
    "completed": "Completed",
    "optional": " (optional)",
    "desktop_only": "Not available on mobile",
    "required_fields_tooltip": "Fill in all required fields to proceed",
    "select": "Select",
    "select_all": "Select All",
    "select_added": " (Already added)",
    "change": "Change",
    "settings": "Settings",
    "address": "Address",
    "back": "Back",
    "skip_to_conent": "Jump to content",
    "skills": "Skills",
    "level": "Level",
    "course_description": "Course Description",
    "back_to": "Back to ",
    "cancel": "Cancel",
    "city": "City",
    "click_here": "Click here",
    "confirm": "Confirm",
    "cost": "Cost",
    "language": "Language",
    "country": "Country",
    "create": "Create",
    "create_and_insert": "Create and insert",
    "description": "Description",
    "password": "Password",
    "phone": "Phone",
    "delete": "Delete",
    "remove": "Remove",
    "role": "Role",
    "save": "Save",
    "title": "Title",
    "update": "Update",
    "detail": "Detail",
    "website": "Website",
    "province": "Province",
    "zipcode": "Zipcode",
    "form": {
      "field_errors": {
        "text": "{name} is a required field",
        "email": "Insert a valid email address",
        "password": "Password must have at least 8 characters, one uppercase letter, a number and a special character"
      }
    }
  },
  "home_box": {
    "error_cta": "Retry the interview",
    "error_msg": "You can retake the interview to get a new analysis.",
    "error_pre_title": "An error occurred",
    "error_title": "Unfortunately, the interview analysis failed",
    "has_interview_cta": "View Results",
    "has_interview_msg": "I have curated this collection of content based on the results of your video interview. Here, you can learn specific skills to highlight your strengths and strengthen your areas for improvement.",
    "has_interview_processing_msg": "We are processing your report. Check out the collections and choose what interests you. As soon as it's ready, you'll find it in your profile.",
    "has_interview_title": "Let's work together",
    "logged_cta": "start the interview",
    "logged_msg": "Discover tailored suggestions for faster and more personalized learning.",
    "logged_pre_title": "Ready to level up your content game?",
    "logged_title": "Take the Video Interview",
    "interview_banner": "The video interview is available for the browser",
    "started_cta": "Take the Video Interview",
    "started_msg": "We noticed that you started the video interview but didn't complete it. Answer the questions; it will take a maximum of 10 minutes. My artificial intelligence will help suggest the most suitable content for you.",
    "unlogged_cta": "Sign up to access",
    "unlogged_msg": "Want personalized recommendations? Let's get to know each other better. Answer the questions in my video interview, and my artificial intelligence will suggest the most suitable content for you.",
    "unlogged_title": "Customized Learning Paths",
    "team_title": "Collections suggested by your organization"
  },
  "home": {
    "collections_in_progress_description": "I'm currently working on selecting the best content for you. Generally, it takes me less than a minute. Click 'Refresh' to see the results.",
    "collections_in_progress_title": "I'm building collections for your job role!",
    "collections_empty_description": "I'm currently working on selecting the best content for you. It usually takes me a few days for a new job role.",
    "collections_empty_title": "Oops, this is the first time your job role has been requested!",
    "collections_in_progress_button": "Refresh",
    "first_reel_title": "Most Popular Content Right Now",
    "first_reel_title_w_report": "Viblio's Recommendations for Your Personal Development",
    "intro": "Choose what you like, with complete freedom",
    "job_role_reel_title": "Personalized Recommendations",
    "job_role_reel_title_not_dev": "Viblio's Suggestions for Your Job Role",
    "subtitle": "Expand your knowledge with the help of our selection of personalized content! With its artificial intelligence, Viblio searches and selects content from the best digital platforms worldwide: not just courses, but also podcasts, videos, and articles.",
    "title": "Your Digital Mentor",
    "created_by": "created by",
    "welcome_header": "Hello ",
    "welcome": "Let's go, Viblio!",
    "top_content_header": "See what's new",
    "viblio_suggested": "Suggested from Viblio Experts",
    "quotes": {
      "default": {
        "cit": "Avoid doing what you don't know, but learn everything that is necessary.",
        "author": "Pythagoras (Greek philosopher, 5th century BC)"
      }
    }
  },
  "job_roles": {
    "customer_service_representatives": "Customer Service Representative",
    "developer": "Software Developer",
    "generic": "Most Popular Content for Your Job Role",
    "hr_manager": "HR Manager",
    "marketing_manager": "Marketing Manager"
  },
  "login": {
    "forgot_password": "Forgot Password?",
    "no_account": "Don't have a Viblio account?",
    "placeholders": {
      "email": "enter the email you registered with",
      "password": "enter your password"
    },
    "welcome": "Log in to discover a universe of content and continue your growth journey."
  },
  "organizations": {
    "add_user": "Add user",
    "add_user_no_limit_for_trial": "You cannot add more than 10 users during the trial period.",
    "users_counter": "Users:",
    "filter_name": "Organization Name",
    "small_title": "Edit workspace name",
    "small_subtitle": "Edit the name of your workspace and click on 'Save' to confirm.",
    "small_name": "Organization",
    "name": "Name and surname or company name",
    "email": "Email",
    "status": "Status",
    "status_disabled": "Disabled",
    "confirm_remove": {
      "message": "Organization data will not be deleted and will remain in the database. All associated users will be disabled.",
      "remove": "Disable",
      "subtitle": "Are you sure you want to disable <strong>{name}</strong>?",
      "title": "Disable Organization"
    },
    "created": "The organization <strong>{organization}</strong> has been created.",
    "create_organization": "Create Organization",
    "create_user": "New User",
    "cta_create": "Create the first",
    "deleted": "The organization has been deleted",
    "edit_organization": "Edit Organization {name}",
    "edit_user": "Edit User {name}",
    "empty": "There are no organizations",
    "error_saving_user": "An error occurred while saving. Please try again later. {errormsg}",
    "error_deleting_user": "Si è verificato un errore nell' eliminazione dell'utente. {errormsg}",
    "form": {
      "company_info": {
        "billing_title": "Enter billing address",
        "billing_subtitle": "Fill in the following data and click on “Save”.",
        "address": "Headquarters",
        "source": "Source",
        "plan": "Plan",
        "pay_in_app": "Payment via stripe",
        "trial": "Trial period",
        "trial_days": "Duration",
        "address_detail": "Stair, interior, floor",
        "help": "Personal data",
        "legal_address": "Legal Address",
        "title": "Company Information",
        "country_help": "Two-letter country code (Eg. IT, DE, FR ...)",
        "cf_code": "Tax Id",
        "vat_code": "VAT Code",
        "vat_code_help": "Add the country prefix before the VAT number"
      },
      "general": {
        "email": "Organization Email",
        "title": "Personal Information"
      },
      "referent": "Referent",
      "personal": "Personal",
      "payment_attached_date": "Payment method attached",
      "pay_in_app": "Pay in app",
      "stripe_customer_id": "Stripe customer ID"
    },
    "covert_to_pay_in_app": "Convert to pay in App",
    "covert_to_pay_in_app_subtitle": "Are you sure you want to convert {name}?",
    "covert_to_pay_in_app_message": "This operation cannot be revoked.",
    "covert_to_pay_in_app_success": "The operation converted successfully.",
    "join_us_payload": "Hello! You have been invited by <strong>{orgName}</strong> to join Viblio!<br><br>Create your account: the future of learning is just a click away.",
    "manage_users": "Users",
    "manage_users_description": "Create users in your organization and assign them the role of member, manager and supervisor. \nEach user created will be sent an email to immediately start using Viblio.",
    "manage_users_description_business": "Create users in your organization and associate them with a role. For each active user added, an email will be sent to start using Viblio. Each active user corresponds to a monthly/annual license.",
    "no_users": "There are no users in this organization.",
    "no_users_add_now": "Add the first one",
    "organizations": "Organizations",
    "updated": "The organization <strong>{organization}</strong> has been updated.",
    "user_created": "User created successfully.",
    "user_form": {
      "job_role": "Job Role",
      "job_role_help": "",
      "manager": "manager",
      "signup_status_invited": "INVITED",
      "user_is_MEMBER": "Member",
      "user_is_MANAGER": "Manager",
      "user_is_SUPERVISOR": "Supervisor",
      "user_is_ADMIN": "Admin",
      "user_is_CONSUMER": "Consumer",
      "wrong_link": "The link you followed is invalid or expired. Please check the link and try again. If the error persists, contact an administrator.",
      "manager_sets_role_true": "I want to set the role and skills myself",
      "manager_sets_role_false": "I want the user to set their own role and skills",
      "manager_sets_role_incomplete": "You have not completed the role selection",
      "reset_job_titles_skills": "Reset Job Roles and Skills",
      "no_department": "No departments have been created yet"
    },
    "users": "Your users",
    "plans": "Your plan | Your plans",
    "add_plan": "Add plan",
    "user_updated": "User updated successfully",
    "your_organization": "Your Organization",
    "update_collection": "Refreshing collections!",
    "unavailable_plans": "There are not available licenses. Please contact your admin",
    "add_department": "Create Department",
    "add_team": "Create Team",
    "teams": "Teams"
  },
  "groups": {
    "team": "Team | Teams",
    "department": "Department | Departments",
    "departments_description": "Departments are a collection of people who share common roles.\nCreate departments in your organization, associate users with departments (each user can be associated with only one department) and create shared collections.",
    "teams_description": "Teams are a collection of people who share common goals.\nCreate teams in your organization, associate users with teams (each user can be associated with multiple teams), and create shared collections.",
    "name": "Name",
    "description": "Description",
    "assigned_to": "Head of {model}",
    "assigned_to_department": "Head of department",
    "assigned_to_team": "Head of team",
    "assigned_to_help": "You can also change it later",
    "user_counter": "Users",
    "delete_success": "Delete successfully",
    "msg_error_saving": "An error occurred while saving. Please try again later. {errormsg}",
    "msg_success_created": "The Group <strong>{name}</strong> created",
    "msg_success_updated": "The Group <strong>{name}</strong> updated",
    "no_record": "There is no {group}.",
    "no_record_add_now": "Click Create to add the first one",
    "users": {
      "confirm_remove": {
        "remove": "Remove",
        "title": "Remove user from this group",
        "subtitle": "Are you sure you want remove <strong>{name}</strong>?",
        "message": ""
      }
    }
  },
  "register": {
    "already_account": "Already an account?",
    "access": "Sign-in",
    "access_1": "Sign-in and start learning",
    "continue": "Continue",
    "complete": "Complete Sign up",
    "pick_password": "Choose a password",
    "create_password": "Create a password",
    "wait": "Wait a moment",
    "back": "Back",
    "process": "we are processing your data...",
    "select": "Select",
    "title": "Sign up and discover your learning path",
    "terms": "Terms and conditions",
    "sub_line_1": "No credit card requested",
    "sub_line_2": "14 days of free trial",
    "sub_line_3": "Possibility to delete the subscribtion",
    "separator": "OR",
    "choose_password": "Choose a Password",
    "confirm_password": "Confirm Password",
    "google_register": "Sign-up with Google",
    "linkedin_register": "Sign-up with Linkedin",
    "weak": "Weak Password",
    "medium": "Good Password",
    "complex": "Awesome Password",
    "have_account": "Already have an account? {login}",
    "same_email": "Email already registered.",
    "title_2": "Tell us something more about you",
    "mandatory": "Mandatory fields must be filled",
    "first_name": "First Name",
    "last_name": "Last Name",
    "identify": "How do you identify yourself professionally?",
    "objective": "What professional goal do you want to achieve?",
    "company": "Company",
    "company_size": "Company size",
    "student": "I am a student",
    "worker": "I am a worker",
    "conselour": "I am a consultant/freelancer",
    "team_manager": "I am a manager or a team manager",
    "hr": "I am a Human Resource",
    "other": "Other",
    "updated": "I want to stay updated",
    "new_skills": "I want to learn new skills",
    "change_path": "I want to change my career path",
    "continous_formation": "I want a tool that allows my team or company to do continuous training",
    "1": "Only me",
    "2-9": "2-9 employees",
    "10-49": "10-49 (small business)",
    "50-249": "50 - 249 (medium business)",
    "+250": "over 250 employees",
    "privacy_policy": "I accept ",
    "tec": "terms and conditions",
    "and": "and the",
    "newsletter": "I want to stay updated on what's new in Viblio (only when we add something interesting)",
    "end_registration": "You have completed registration!",
    "end_registration_sub": "Welcome on Viblio!",
    "end_registration_text": "Hi, I'm Viblio, your digital mentor. Thanks to my artificial intelligence, I select content from the best e-learning platforms to evolve your role and skills to adapt to current and future European standards.",
    "check_email": "Check your email inbox",
    "check_email_text_1": "We have sent you an email at",
    "check_email_text_2": ". Check your inbox and click on the verification link to confirm your email address.",
    "check_email_sub": "(If you don't find the verification email in your inbox, check your spam or junk mail folder)",
    "email_error": "Email field can't be empty",
    "password_error": "Password field can't be empty",
    "join_us": "Join Us",
    "join_us_payload": "Sign up on Viblio and start learning the knowledge of the future with the tools and guidance of our experts",
    "password_format": "At least 6 alphanumeric characters",
    "placeholders": {
      "email": "enter your email",
      "name": "enter your name",
      "surname": "enter your surname",
      "work_email": "enter your work email"
    },
    "registering_user": "Validating data and registering user...",
    "privacy1": "I accept the: ",
    "privacy2": "privacy policy",
    "privacy3": " of the service"
  },
  "reset_password": {
    "enter_email": "Enter your email",
    "info": "Enter the email address you used to register. You will receive a message with instructions to change your password and access Viblio.",
    "warning": "If your address is not in our records, you will not receive any notifications."
  },
  "user": {
    "login": "LOGIN",
    "profile": "Profile",
    "progress": "Activities and Collections",
    "subscription": "Subscription",
    "register": "Sign up",
    "logout": "Logout"
  },
  "user_profile": {
    "save_changes": "Save changes",
    "wait_save_changes": "No changes were detected",
    "profile_section_title": "Login data, privacy, and notifications",
    "my_data": "My data",
    "no_skills": "No interests selected",
    "change_name_surname_lang": "Update",
    "account_settings": "Accounts and notifications",
    "change_password_title": "Login password",
    "change_password": "Change Password",
    "change_password_description": "Click on “Change password” to change your login password. Once changed you will have to log in again",
    "change_password_error": "Change of password failed. Please log in again and try again.",
    "change_password_success": "The password was changed successfully",
    "delete_account": "Delete account",
    "delete_account_description": "Send an email to ",
    "delete_account_instructions": " to delete your account and all your data.",
    "delete_account_instructions_2": "By deleting your account you will lose all suggestions and the history of your consultations.",
    "description": "Here you will find the information you shared during the initial profiling. You can change your name and surname, but not the registration email.",
    "interview": "Video Interview",
    "interview_description": "Do you want even more personalized learning suggestions? Do the video interview which, thanks to artificial intelligence, defines your aptitude profile. At the end you can download the detailed report and discover the most suitable contents for growing professionally.",
    "interview_lets_start": "Start the video interview",
    "interview_started": "You have not completed the video interview. Click on “continue the video interview” to resume where you left off. At the end you can download the detailed report and discover the most suitable content for growing professionally.",
    "interview_started_cta": "Continue the video interview",
    "interview_processing": "You have completed the video interview and your report will be available shortly. As soon as it is available you will receive an email and you will be able to find the content best suited to you on Viblio.",
    "interview_completed": "You have already completed the video interview to learn about your aptitude profile. Below you can download the report or view the contents suggested for you.",
    "interview_go_collection": "Go to suggested content",
    "interview_download_cta": "Download the report",
    "interview_failed": "Unfortunately the analysis of your interview generated an error.",
    "interview_error_cta": "Replay the video interview",
    "interview_start_title": "Get the best from Viblio: identify and develop your potential",
    "interview_start_desc1": "The video interview lasts only 5 minutes. You will answer 3 questions, for each one you will have 1 minute to read about it and prepare your response, and 1 minute to record your answer.",
    "interview_start_desc2": "Viblio uses the YobsTech artificial intelligence engine and an evaluation mechanism based on APA standards to analyze your responses and suggest the most suitable learning path for you.",
    "interview_start_desc3": "At the end of the video interview, you will receive a detailed report with your strengths and areas for improvement, allowing you to analyze the results in depth.",
    "interview_start_apple": "Attention, Apple Safari is not yet supported by the video interview. Please try using one of these alternatives:",
    "interview_start_browser_recommended": "(recommended)",
    "interview_start_submit": "Are you ready? Start now!",
    "interview_start_loading": "Connecting...",
    "interview_start_no_tos_accepted": "To continue, please accept the Terms and Conditions",
    "interview_finish_title": "CONGRATULATIONS! YOU HAVE COMPLETED YOUR VIDEO INTERVIEW!",
    "interview_finish_desc": "We are creating your report. We will send you an email as soon as it's ready! Typically, it takes 1 or 2 days.",
    "interview_finish_desc2": "In the meantime, you can continue to explore the myriad of content selected by our experts.",
    "interview_finish_back": "Back to homepage",
    "intro_section_title": "My professional profile",
    "profile": "Professional profile",
    "title_form": "Personal information",
    "title_interview": "Personality Traits - Video Interview",
    "job_title": "Job Title",
    "skills": "Interests",
    "update_profile_success": "The profile has been successfully updated.",
    "logout_cta": "Log out of Viblio",
    "logout_description": "Click on the “Exit Viblio” button to log out.",
    "logout_description_2": "The next time you log in you will need to enter your credentials again.",
    "notification_title": "Email preferences",
    "email_notification": "Receive emails with tips about your training",
    "notification_description": "These emails allow you to receive additional training content, insights and advice based on your learning habits",
    "update_notification_success": "Your preferences have been updated",
    "notification": "Receive functional emails",
    "agreement_title": "Data Sharing Preferences",
    "agreement": "Share data about education and interests",
    "agreement_description": "If you choose not to share information about your learning journey, it will be more difficult for your company to provide you with the training you need and give you support.",
    "policies_title": "Terms and conditions, privacy and cookie policy",
    "policies_term_link": "Terms and conditions",
    "policies_privacy_link": "Privacy policy",
    "policies_cookie_link": "Cookie policy"
  },
  "agreement": {
    "title": "Welcome to Viblio!",
    "text": "Viblio is a corporate tool that allows you to train and develop new skills adopted by {nome}. Before starting your training path, {nome} asks you to indicate your preference regarding data sharing on learning contents, which allow your company to fully exploit the potential of Viblio by guaranteeing personalized and timely training and support.",
    "button-ag": "I agree to sharing",
    "button-not": "Do not share",
    "undertext": "If you choose not to share information about your learning path, it will be more difficult for your company to offer you the training you need and give you support. You can change your preferences in your profile at any time."
  },
  "support": {
    "nav_bar_text": "Support",
    "categories": {
      "ho_bisogno_di_supporto": "I need support",
      "voglio_segnalare_un_errore_bug": "I want to report an error (Bug)",
      "richiesta_di_nuove_funzionalita": "Request for new features",
      "informazioni_sui_piani_pagamenti_fatturazione": "Information about plans, payments, billing",
      "annullamento_piano": "Plan cancellation",
      "contatta_un_commerciale": "Contact a salesperson",
      "feedback_e_suggerimenti": "Feedback and suggestions",
      "domande": "Questions about Viblio",
      "errori_tecnici": "Technical errors",
      "attivita_amministrative": "Administrative activities"
    },
    "customer_request": {
      "question": "Questions about Viblio",
      "task": "I need support",
      "incident": "I want to report an error (Bug)",
      "payment": "Information about plans, payments, billing",
      "feedback": "Feedback and suggestions",
      "request_new_feature": "Request for new features",
      "cancel_plan": "Cancel plan",
      "contact_sales": "Contact a sales representative"
    },
    "success": "Your request has been sent successfully. We will contact you as soon as possible.",
    "title": "Write to support",
    "subtitle": "Fill in the following fields and send your report/question, our support team will respond to you as soon as possible.",
    "contact_label": "How can we help you?",
    "object": "Describe your request",
    "cta": "Send"
  },
  "expired": {
    "title": "Continue learning with Viblio",
    "subtitle": "There are no active subscriptions on your account. Go back to the plan table, choose the plan that best suits your needs and click on “Manage plan” and add a valid payment method.",
    "cta": "Choose plan and activate Viblio",
    "help_cta": "Do you need support? contact us at ",
    "footer_title": "Do you no longer want to continue with Viblio for now?",
    "footer_subtitle": "Tell us briefly why. Your feedback is essential for us to improve our product",
    "footer_cta": "Help us improve"
  },
  "dashboard": {
    "nav_bar_text": "Data highlights",
    "description": "In this section you can view your organization's analytical data relating to users, teams and departments",
    "tab_my": "My data",
    "tab_org": "Company data",
    "back_all_members": "Back to all users",
    "filters_title": "Filters",
    "members": "Users",
    "contents": "Searches",
    "view_more": "More details",
    "open_table": "view as table",
    "go_to_user_dashboard": "Go to user dashboard",
    "go_to_content": "View content",
    "modal_pagination": "Showing {limit} of {total}",
    "no_data": "No data available",
    "no_users_title": "No members found with the current filters",
    "no_users_desc": "Try adjusting your filters for a better match.",
    "popular_skill_per_users_chart_title": "Popular interests",
    "popular_skill_per_users_chart_tooltip": "Main interests in your organization not directly related to professional roles.",
    "company_skill_chart_title": "Popular company collections",
    "company_skill_chart_tooltip": "",
    "top_users_chart_title": "Top users",
    "top_users_chart_tooltip": "Ranking of users who viewed more content",
    "popular_skill_per_contents_chart_title": "Common skills",
    "popular_skill_per_contents_chart_tooltip": "Ranking of the professional skills of your organization's roles by level",
    "days_of_week_distribution_chart_title": "Visits by day of the week",
    "days_of_week_distribution_chart_tooltip": "",
    "top_contents_chart_title": "Most popular learning contents",
    "top_contents_chart_tooltip": "",
    "soft_skill": "Soft skills",
    "soft_skill_tooltip": "",
    "hard_skill": "Hard skills",
    "hard_skill_tooltip": "",
    "tech_knowledge": "Technological skills",
    "tech_knowledge_tooltip": "In this section you can find trending skills that could impact in your career growth  in the short term.",
    "no_tech_knowledge_for_role": "No skills found for this job title from European employment systems.",
    "company_collections_title": "Interaction with company collections",
    "company_collections_tooltip": "Each graph represents a company collection, the percentage indicates how many contents of the total collection have been opened.",
    "top_contents_for_user_chart_title": "Trend skill related to your job title",
    "top_contents_for_user_chart_tooltip": "Learning contents that other similar professionals in your role have viewed.",
    "search_focus_user_title": "Interests",
    "search_focus_user_tooltip": "Skills you are developing that are not directly related to your role when you view learning content",
    "collection_legend": "Content interacted with",
    "counter_total_origin_by": "Contents viewed",
    "counter_total_levels": "Learning contents levels",
    "counter_content_total_levels": " for level {level}",
    "counter_origin_by_user": "From search and personal collection",
    "counter_origin_by_share": "From shared company collections",
    "counter_origin_by_system": "From collections suggested by Viblio",
    "counter_level_beginner": "Beginner",
    "counter_level_intermediate": "Intermediate",
    "counter_level_advanced": "Advanced",
    "user_chart_agreement": "It is not possible to see <br />the contents of the selected user <br />because he hasn't agreed <br />to the sharing of personal <br />data policy.",
    "job_charts_row": "Professional skills related to your job title",
    "trend_charts_row": "Trend skill related to your job title",
    "your_focus_row": "Interests",
    "org_focus_trend_row": "Trend learning contents you may consider",
    "org_focus_row": "Company collections",
    "improve_skill": "To improve the skill: ",
    "progress_collection": "Collection: {collectionName}",
    "content_searched_level": "{level} level contents viewed",
    "week_days": {
      "mon": "Mon",
      "tue": "Tue",
      "wed": "Wed",
      "thu": "Thu",
      "fri": "Fri",
      "sat": "Sat",
      "sun": "Sun"
    },
    "search_member": "Search user",
    "skills": "Skills",
    "points": "Points",
    "top_5": "Top {limit}",
    "total_searched_members": " user viewed content related to the skill |  users viewed content related to the skill ",
    "total_interacted_members": " User who interacted with: |  Users who interacted with: ",
    "collection_title": "company collection - ",
    "no_data_with_filter": "No results found for “{search}”",
    "from_for_level": "Skill “{skill}” improved after viewing  following learning contents",
    "resources_title_from_org_bubble": "{totalUsers} users viewed content related to the skill “{skill}”",
    "resources_title_from_top": "{userFullName} has viewed the following learning contents",
    "filters": {
      "search": "Refresh",
      "from_members": "Users personal dashboard",
      "organization_placeholder": "Your Organization",
      "occupation_placeholder": "Select Job Titles",
      "group_placeholder": "Select Teams and Departments",
      "limit": {
        "item0": "All",
        "item10": "Top 10",
        "item20": "Top 20"
      }
    },
    "banner_manager_without_groups": {
      "title": "No data is available at the moment because you have not created your team",
      "message": "In this section you can view the data relating to the formation of your teams. \n\r Create your team to monitor the skills and interests of the people within the team.",
      "cta_button": "Create your team"
    }
  },
  "notification": {
    "empty": "No notification available"
  },
  "privacy": {
    "title": "PRIVACY POLICY",
    "header": "Articles 13-14 EU Regulation April 27, 2016, No. 679 'General Data Protection Regulation'",
    "hello": "Dear User,",
    "subtitle": "Apprendo S.r.l. is committed to respecting the user's privacy in accordance with the applicable regulations on the protection of personal data, particularly Regulation (EU) 2016/679 (hereinafter 'Regulation').",
    "info": "This document ('Privacy Policy') provides information on the processing of personal data collected by the company APPRENDO S.r.l. through this website (hereinafter 'Site') and therefore constitutes information for the data subjects in accordance with the aforementioned regulations. The information is provided only for this website www.viblio.com and not for other websites that may be consulted by the user through links.",
    "title_1": "1. WHO IS THE DATA CONTROLLER AND THE DPO?",
    "answer_1": "The data controller is the company Apprendo S.r.l. with headquarters in 33100 Udine, via Marinoni n. 12 (VAT ID 03029040304). The Data Controller can be contacted at the email address:",
    "title_2": "2. WHAT PERSONAL DATA DO WE COLLECT AND PROCESS?",
    "answer_2_1": "The categories of personal data that Apprendo S.r.l. may collect during navigation on the website www.viblio.com are as follows:",
    "answer_2_2": "We process data from your navigation on our site, necessary for the operation of the systems managing the Site and Internet communication protocols.",
    "answer_2_3": "These data are used solely for the purpose of obtaining anonymous statistical information about the use of the site and checking its correct operation, and are deleted immediately after processing. The data may be used to ascertain responsibility in case of hypothetical computer crimes against the site.",
    "answer_2_4": "We process the personal data you provide (including name, surname, company, email) to provide you with the requested information.",
    "answer_2_5": "If you choose to participate in the Beta Test community, we will use your email to update you on platform developments.",
    "answer_2_6": "We use cookies, small text files that the website inserts into user devices, to enhance usability and enable certain features. For more details, refer to the site's Cookie Policy.",
    "title_3": "3. FOR WHAT PURPOSES DO WE PROCESS PERSONAL DATA AND WHAT IS THE LEGAL BASIS",
    "answer_3_1": "For the operation of computer systems managing the Site. Only navigation data necessary for the operation of these systems and Internet communication protocols are processed.",
    "answer_3_2": "Legal Basis: The legitimate interest of the Data Controller in the technical management related to the functionality and security of the site.",
    "answer_3_3": "To provide assistance and respond to your queries.",
    "answer_3_4": "Legal Basis: The lawful basis for processing is the execution of the contract of which you are a part.",
    "answer_3_5": "To update you on platform developments if you have chosen to participate in Viblio's Beta Test community (for private users only).",
    "answer_3_6": "Legal Basis: The lawful basis for processing is the execution of the contract of which you are a part.",
    "answer_3_7": "Purposes necessary to ascertain, exercise, or defend a right in court or related to cases in which judicial authorities perform their functions.",
    "answer_3_8": "It is the legitimate interest of the Data Controller in exercising or defending a right.",
    "title_4": "4. NATURE OF DATA PROVISION AND CONSEQUENCES OF REFUSAL TO RESPOND",
    "answer_4_1": "Regarding points 3 a), 3 b), and 3 c) of this information, providing data is necessary to fulfill legal and contractual obligations. The user is free to provide them, but refusal may result in Apprendo S.r.l. being unable to manage your requests, navigate the site, inform you about the development phases to participate in the beta test version of the Viblio platform (for private users only), or carry out all the obligations required by current regulations.",
    "title_5": "5. HOW LONG DO WE KEEP THE DATA?",
    "answer_5_1": "Data will be stored for a limited period depending on the type of activity involving the processing of your personal data. After this period, your data will be permanently deleted or made irreversible anonymous. Your personal data is stored in accordance with the terms and criteria specified below:",
    "answer_5_2": "Data related to your assistance request is stored until the request is satisfied.",
    "answer_5_3": "Regarding the purpose under 3 c) - data used to participate in the beta test of the Viblio platform - they are stored until the request is satisfied. If you are no longer interested in participating in this initiative and do not wish to receive our communications, you can exercise your right to object through the 'unsubscribe' link found in the communications we send you.",
    "title_6": "6. TO WHOM COULD YOUR PERSONAL DATA BE COMMUNICATED?",
    "answer_6_1": "Your personal data may be processed by personnel of the Data Controller, appropriately trained and committed to confidentiality, or by third parties providing instrumental services to the Company, typically acting as data processors. These subjects will only receive data necessary for their respective functions and commit to using them only for the purposes mentioned above and treating them in compliance with applicable privacy regulations. The updated list of data processors is available at the Data Controller's offices and will be provided upon request by the data subject by writing to the following email address:",
    "title_7": "7. WHAT ARE YOUR RIGHTS?",
    "answer_7_1": "You have the right to obtain information about the processing of personal data concerning you, to access it, and obtain a copy, even by electronic means commonly used.",
    "answer_7_2": "You also have the right to update, integrate, or rectify your data.",
    "answer_7_3": "You can request the portability of data processed due to the execution of a contract or based on your consent.",
    "answer_7_4": "If interested, you have the right to request the deletion of your data.",
    "answer_7_5": "You also have the right to request the limitation of processing or to object, in whole or in part, to the processing of personal data concerning you, even for marketing purposes.",
    "answer_7_6": "If you believe that one or more processing activities carried out by us may violate regulations, or if you believe that your rights have not been protected, you can file a complaint with the Control Authority of the place where you usually reside, work, or where the alleged violation occurred. In Italy, you can contact the Privacy Guarantor Authority.",
    "answer_7_7": "These rights can be exercised by writing by postal mail to the above addresses or by email to the following email address:"
  },
  "progress": {
    "cta": "Every great journey is accomplished one step at a time",
    "help": "In this section, you can track your progress and see how much you have learned over time."
  },
  "price_table": {
    "flex": "FLEXIBLE & LIGHT",
    "full": "FULL-ON",
    "hint_jobrole": "PERSONALIZED CONTENT RECOMMENDATIONS FOR ROLE SKILLS",
    "hint_skill": "PERSONALIZED CONTENT RECOMMENDATIONS FOR INTEREST AREAS",
    "interview": "VIDEO INTERVIEW WITH PERSONALITY TRAIT ANALYSIS WITH YOBS Technology",
    "custom_report": "Custom Report (Big Five)",
    "custom_report_collections": "Custom Report (Big Five) + Personalized Collection",
    "metasearch": "METASEARCH",
    "available_contents_over": "over",
    "available_contents_flex_count": "25.000",
    "available_contents_full_count": "14.000",
    "available_contents_flex": "contents, including COURSES, PODCASTS, WEBINARS, and PROJECTS (with direct payment on provider platforms)",
    "available_contents_full": "contents, including PODCASTS, WEBINARS, COURSES, and PROJECTS (all Coursera content included in the subscription)",
    "certifications": "CERTIFICATION-RELATED CONTENT",
    "certifications_flex": "Certifications",
    "certifications_flex_strong": "are paid on the platforms of the providers that offer them, according to their economic conditions.",
    "certifications_full_count": "9.000",
    "certifications_full": "certification courses",
    "certifications_full_included": "INCLUDED IN THE SUBSCRIPTION",
    "provider": "PROVIDER",
    "subscriptions": "SUBSCRIPTIONS",
    "subscriptions_cta": "Buy Now"
  },
  "subscription": {
    "title": "Unfortunately, Viblio cannot be used",
    "subtitle": "It appears that the plan associated with your organization has expired.",
    "contact_supervisor": "Contact the contact person {supervisor} for more information."
  }
}