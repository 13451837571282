<template>
  <div
    class="fixed w-[300px] h-full px-4 py-6 bg-white border-r [border-right-style:solid] border-gray-200 flex-col justify-between inline-flex z-10"
  >
    <div class="self-stretch h-96 flex-col justify-start items-start gap-8 flex">
      <OrganizationItem v-if="isAuthenticated" />
      <div class="self-stretch h-80 flex-col justify-start items-start gap-1 flex">
        <SideBarMenuItem
          :label="$t('sidebar.for_you')"
          icon="uil-bookmark"
          :active="currentRoute.name === 'ForYou'"
          @click="navigate({ name: 'ForYou' })"
        />
        <SideBarMenuItem
          :label="$t('sidebar.collections')"
          icon="uil-books"
          expandable
          :subitems="[{ label: $t('sidebar.collections_personal'), click: () => navigate({ name: 'collections-list', params: { collectionType: 'personal' } }) },
                      { label: $t('sidebar.collections_shared'), click: () => navigate({name: 'collections-list', params: { collectionType: 'shared_with_me'}}) },
                      { label: $t('sidebar.collections_company'), click: () => navigate({name: 'collections-list', params: { collectionType: 'company'}}) }]"
        />
        <SideBarMenuItem
          :label="$t('sidebar.explore')"
          icon="uil-search"
          :active="['search', 'search-results'].includes(currentRoute.name)"
          @click="navigate('/search')"
        />
        <SideBarMenuItem
          desktop-only
          :label="$t('sidebar.analysis')"
          icon="uil-graph-bar"
          :active="currentRoute.name === 'dashboard'"
          @click="navigate({ name: 'dashboard' })"
        />
        <SideBarMenuItem
          v-if="!userIsMember()"
          desktop-only
          :label="$t('sidebar.manage')"
          :active="currentRoute.name === 'settings'"
          icon="uil-cog"
          @click="navigateToSettings"
        />
      </div>
    </div>
    <div class="flex-col justify-start items-start gap-4 flex">
      <Alert
        v-if="isAuthenticated && currentUser.organization_info.pay_in_app && !currentUser.organization_info.payment_attached_date && !isMobile"
        :title="$t('sidebar.missing_payment_title')"
        type="info"
      >
        <template #content>
          <p class="text-xs">
            {{ $t('sidebar.missing_payment_text') }}
          </p>
        </template>
      </Alert>
      <div class="self-stretch h-px bg-slate-200" />
      <div class="gap-2 hidden md:flex">
        <div class="text-gray-900 text-sm leading-tight">
          Powered by
        </div>
        <img
          class="h-4"
          src="../../assets/logo_viblio_transparent_short_blue.svg"
        >
      </div>
      <div class="flex flex-col md:hidden gap-2">
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toSupport"
        >
          <i class="uil uil-envelope text-gray-500" />
          <div class="text-sm">
            {{ $t('support.nav_bar_text') }}
          </div>
        </div>
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toProfile"
        >
          <i class="uil uil-user text-gray-500" />
          <div class="text-sm">
            {{ $t('user.profile') }}
          </div>
        </div>
        <a
          data-test="logout"
          class="flex gap-2 cursor-pointer"
          @click="logoutAndRedirect"
        >
          <div class="text-sm">
            {{ $t('user.logout') }}
          </div>
        </a>
      </div>
    </div>
    <Dialog
      v-model:visible="showProfileDialog"
      class="w-full sm:w-[1040px] m-4"
      modal
      :draggable="false"
      :header="$t('users.profile')"
    >
      <Profile @closeDialog="() => { showProfileDialog = false}" />
    </Dialog>
    <Dialog
      v-model:visible="showSupportDialog"
      class="m-4"
      :header="$t('support.title')"
      modal
      :draggable="false"
    >
      <Support @closeDialog="() => { showSupportDialog = false }" />
    </Dialog>
  </div>
</template>
<script setup>
import { defineEmits, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserData } from '@/composables/userData'
import OrganizationItem from '@/components/sidebar/OrganizationItem.vue'

import Alert from '@/components/Alert.vue';
import SideBarMenuItem from './SideBarMenuItem.vue'
import { useUserRoles } from '../../composables/userData';
import { computed } from 'vue';
import Profile from "@/components/Profile.vue";
import Support from "@/components/Support.vue";
import Dialog from "primevue/dialog";
import {useWindowSize} from "@vueuse/core";

const { isAuthenticated, logoutUser, getUser } = useUserData();
const { userOrganizationId, userIsAdmin, userIsMember } = useUserRoles();

const router = useRouter()
const currentUser = getUser();
const currentRoute = computed(() => router.currentRoute.value)

const emit = defineEmits(['close'])
const { width } = useWindowSize()
const isMobile = computed(() => width.value < 600)

const navigate = (route) => {
  router.push(route)
  emit('close')
}

const navigateToSettings = () => {
  const route = userIsAdmin() ? { name: 'organizations' } : { name: 'organization', params: { id: userOrganizationId() } }
  router.push(route)
}

const logoutAndRedirect = () => {
  logoutUser();
};

const showProfileDialog = ref(false)
const showSupportDialog = ref(false)
const toProfile = () => {
  showProfileDialog.value = true;
}
const toSupport = () => {
  showSupportDialog.value = true;
}
</script>
