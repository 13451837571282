<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
    :header="$t(header)"
    :style="{ width: '30rem' }"
  >
    <div class="flex flex-col gap-4">
      <div class="text-base">
        {{ $t('interview.modal_body_first') }}
      </div>
      <div class="text-base">
        {{ $t('interview.modal_body_second') }}
      </div>
      <div class="text-base">
        {{ $t('interview.modal_body_third') }}
      </div>
      <div class="flex align-items-center">
        <Checkbox
          v-model="privacyChecked"
          input-id="privacy-check"
          binary
          :invalid="formInvalid"
          @change="formInvalid = false"
        />
        <label
          for="privacy-check"
          class="ml-2"
          v-html="$t('interview.modal_accept_privacy')"
        />
      </div>
      <div
        v-if="formInvalid"
        class="text-red-500 text-sm mt-[-0.5rem]"
      >
        {{ $t('interview.modal_accept_privacy_error') }}
      </div>
      <div class="flex justify-end gap-2">
        <Button
          type="button"
          :label="$t('interview.modal_cancel')"
          severity="secondary"
          @click="model = false"
        />
        <Button
          type="button"
          severity="contrast"
          :label="$t(saveLabel)"
          @click="submitForm"
        />
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { computed, defineModel, defineProps, ref } from 'vue';
import { useInterview } from '@/composables/userData'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

const { startInterview } = useInterview()
const privacyChecked = ref(false);
const formInvalid = ref(false);

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: 'initialize',
  },
});

const saveLabel = computed(() => {
  return props.mode === 'initialize' ? 'interview.modal_save_label_initialize' : 'interview.modal_save_label_continue';
});

const header = computed(()=> {
  return props.mode === 'initialize' ? 'interview.modal_header_initialize' : 'interview.modal_header_continue';
})
const model = defineModel({ default: false });

const isApple = ref(false)
let UA = navigator.userAgent
let isSafari =
  /\b(iPad|iPhone|iPod|Safari)\b/.test(UA) &&
  /WebKit/.test(UA) &&
  /Safari/.test(UA) &&
  /like Gecko/.test(UA) &&
  !/Edge/.test(UA) &&
  !/Chrome/.test(UA) &&
  !/Chromium/.test(UA) &&
  !window.MSStream
// console.debug(`isApple=${this.isApple} isSafari=${isSafari}`)
if (isSafari === true) {
  isApple.value = true
  console.debug('Disabling Apple Safari')
}

const submitForm = async () => {
  if (!privacyChecked.value) {
    formInvalid.value = true;
  } else {
    const result = await startInterview()
    if (isApple.value) {
      window.location.href  = result.url
    } else {
      window.open(result.url, '_blank');
    }
  }
  model.value = false;
}

</script>
