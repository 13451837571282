<template>
  <div class="flex flex-col">
    <!--    notStarted {{ !userInterviewState.hasInterview() || userInterviewState.isEmpty()}}-->
    <!--    isCreated {{ userInterviewState.isCreated() }}-->
    <!--    isProcessing {{ userInterviewState.isProcessing() }}-->
    <!--    isReady {{ userInterviewState.isReady() }}-->
    <!--    isFailed {{ userInterviewState.isFailed() }}-->
    <div
      v-if="!userInterviewState.hasInterview() || userInterviewState.isEmpty()"
      class="p-4 sm:p-6 text-white rounded-2xl shadow background-gradient"
    >
      <div class="text-xs sm:text-sm font-bold">
        {{ $t('interview.not_done_title') }}
      </div>
      <div class="text-xl font-extrabold">
        {{ $t('interview.not_done_subtitle') }}
      </div>
      <div class="text-sm sm:text-base">
        {{ $t('interview.not_done_body') }}
      </div>
      <Button
        :label="$t('interview.not_done_cta')"
        severity="secondary"
        class="h-8 mt-6"
        icon="uil uil-arrow-right"
        icon-pos="right"
        @click="showInterviewModal('initialize')"
      />
    </div>
    <Alert
      v-if="userInterviewState.isCreated()"
      :title="$t('interview.created_title')"
      type="info"
      icon="uil uil-file"
      class="relative"
    >
      <template #content>
        {{ $t('interview.created_subtitle') }}
      </template>
      <template #cta>
        <Button
          data-test="banner-cta"
          class="p-0 sm:p-3 underline"
          :link="true"
          :label="$t(`interview.created_button`)"
          @click="showInterviewModal('continue')"
        />
      </template>
    </Alert>
    <Alert
      v-if="userInterviewState.isProcessing()"
      :title="$t('interview.in_progress_title')"
      type="info"
      icon="uil uil-file"
      class="relative"
    >
      <template #content>
        <p>
          {{ $t('interview.in_progress_subtitle') }}
        </p>
      </template>
    </Alert>
    <Alert
      v-if="userInterviewState.isFailed()"
      type="info"
      icon="uil uil-file"
      class="relative"
    >
      <template #content>
        <span class="text-xs sm:text-sm font-bold">
          {{ $t('sidebar.interview_banner_failed') }}
        </span>
      </template>
      <template #cta>
        <Button
          :label="$t('interview.not_done_cta')"
          :link="true"
          class="h-8 mt-6"
          icon="uil uil-arrow-right"
          icon-pos="right"
          @click="showInterviewModal('initialize')"
        />
      </template>
    </Alert>
    <Alert
      v-if="userInterviewState.isReady()"
      :title="$t('interview.report_ready_title')"
      type="info"
      icon="uil uil-file"
      class="relative"
    >
      <template #content>
        {{ $t('interview.report_ready_subtitle') }}
      </template>
      <template #cta>
        <Button
          :label="$t('interview.report_ready_button')"
          :link="true"
          class="h-8"
          icon="uil uil-arrow-right"
          icon-pos="right"
          @click="downloadReport"
        />
      </template>
    </Alert>
    <InterviewModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import Button from 'primevue/button';

import Alert from '@/components/Alert.vue';
import { useInterview } from '@/composables/userData'
import InterviewModal from '@/views/InterviewModal.vue';
import { getReport } from "@/api/user.js";

const showDialog = ref(false);
const mode = ref('initialize');

const downloadReport = async () => {
    try {
        const response = await getReport();
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL || window.webkitURL;
        const objectUrl = url.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", "report.pdf");
        link.click();
        link.remove();

        url.revokeObjectURL(objectUrl);
    } catch (error) {
        console.error("Failed to download report:", error);
    }
}

const { userInterviewState }  = useInterview();

const showInterviewModal = (value) => {
    mode.value = value;
    showDialog.value = true;
}

</script>
<style scoped>
.background-gradient {
    background: linear-gradient(13deg, rgba(1, 187, 237, 1) 0%, rgba(9, 9, 121, 1) 81%, rgba(31, 27, 124, 1) 100%);
}
</style>
