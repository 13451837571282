import { createRouter, createWebHistory } from 'vue-router';
// layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// views
import Home from '@/views/Home.vue';
import Register from '@/views/Register.vue';
import LoginRedirect from '@/views/LoginRedirect.vue';
// import ForgotPass from '@/views/ForgotPass.vue'
// import ResetPass from '@/views/ResetPass.vue'
import NewCourseSearch from '@/views/Search/NewCourseSearch.vue';
import SearchResults from '@/views/Search/SearchResults.vue';
import StartInterview from '@/views/StartInterview.vue';
import InterviewFinishedLanding from '@/views/InterviewFinishedLanding.vue';
import { ROLES } from '@/api/user.js';
import { useUserData, useUserRoles, getCurrentSubscription } from '@/composables/userData.js';
import { useCourseResults } from '@/composables/useElasticCourseResults.js';
// import { getKeyCloak } from '@/composables/useSSO.js';
import { useAuthStore } from '../stores';
import config from '../config';
import {ModalBus, ModalCommands} from "@/eventBus";
import ModalForExpired from "@/components/common/ModalForExpired.vue";
import ForYou from '../views/ForYou.vue';

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/',
    name: 'ForYou',
    component: ForYou,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/api/v2/accounts/openid_connect/login/callback',
    name: 'HomeLogin',
    component: Home,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/register/:contract/:language',
    name: 'Register',
    component: Register,
    meta: {
      layout: DefaultLayout,
      requiresAuth:false,
      requiresNoAuth: true,
      menu: false,
      navbar: false,
      registration: true
    },
    props: (route) => {
        return { contract: route.params.contract, language: route.params.language }
    }

  },
  {
    path: '/login/:language',
    name: 'login-redirect',
    component: LoginRedirect,
    meta: {
      layout: DefaultLayout,
      requiresAuth:false,
      requiresNoAuth: true,
      menu: false,
    },
    props: (route) => {
        return { language: route.params.language }
    }

  },
  {
    path: '/interview',
    name: 'InterviewStart',
    component: StartInterview,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      menu: true,
    },
  },
  {
    path: '/landing',
    name: 'InterviewEnd',
    component: InterviewFinishedLanding,
    meta: {
      requiresAuth: false,
      layout: DefaultLayout,
    },
  },
  {
    path: '/search',
    name: 'search',
    component: NewCourseSearch,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/search/results',
    name: 'search-results',
    component: SearchResults,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/NewDetail.vue'),
    props: (route) => {
      return { id: route.params.id , clickSource: history.state.click_source }
    },
  },
  {
    path: '/old-detail/:id',
    name: 'olddetail',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
      menu: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
    props: (route) => {
      return { id: route.params.id , clickSource: history.state.click_source }
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
      modalAllowed: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    props: true,
  },
  {
    path: '/collection/:id',
    name: 'collection-detail',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "collection-detail" */ '../views/NewCollectionDetail.vue'),
    props: (route) => {
      return { id: route.params.id, fromSingleShare: history.state.fromSingleShare }
    },
  },
  {
    path: '/collections/:collectionType',
    name: 'collections-list',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "collections" */ '../views/CollectionList.vue'),
    props: (route) => {
      return { collectionType: route.params.collectionType }
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'Privacy',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tos" */ '../views/Privacy.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/terms-of-service',
  //   name: 'TOS',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tos" */ '../views/TOS.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  //
  // {
  //   path: '/progress',
  //   name: 'Progress',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "user-progress" */ '../views/UserProgress.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/:pathMatch(.*)',
  //   name: 'Error',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "404" */ '../views/Error.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  {
    path: '/welcome',
    name: 'welcome',
    component: () =>
      import(
        '../views/Welcome.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      menu: false,
      navbar: false,
      onboarding: true,
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () =>
      import(
        '../views/Subscription.vue'
      ),
    meta: {
      layout: DefaultLayout,
      menu: false,
      navbar: true,
      subject: 'expired'
    },
  },

  //// ADMIN

  {
    path: '/admin/organization/:id',
    name: 'organization',
    props: (route) => {
      return { organizationId: route.params.id }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "setting-tab-menu" */
        '../components/management/SettingTabMenu.vue'
      ),
      beforeEnter: (to, from, next) => {
        const { userIsAdmin, userIsOrganizationAdmin, userIsManager, userOrganizationId } = useUserRoles()
        if (userIsAdmin()) {
          next()
          return
        }
        if (!(userIsOrganizationAdmin() || userIsManager()) && userOrganizationId() !== to.params.id) {
          // TODO toast
          next('/')
        } else {
          next()
        }
      },


    meta: {
      layout: DefaultLayout,
      requiresAuth: [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.MANAGER],
      modalAllowed: true,
    },
    children: [
      {
        path: 'edit',
        name: 'organization-edit',
        component: () =>
          import(
            /* webpackChunkName: "OrganizationForm" */
            '../components/management/OrganizationForm.vue'
          ),
        props: true,
      },
      {
        path: 'teams',
        name: 'organization-teams',
        component: () =>
          import(
            /* webpackChunkName: "TeamList" */
            '../components/management/OrgTeamList.vue'
          ),
        props: true,
      },
      {
            path: 'teams/:groupId',
            name: 'team-detail',
            props: (route) => {
              return { groupId: route.params.groupId, isDepartment: false }
            },
            component: () =>
              import(
                /* webpackChunkName: "GroupUserList" */
                '../components/management/GroupUserList.vue'
              ),
          },
      {
        path: 'departments',
        name: 'organization-departments',
        component: () =>
          import(
            /* webpackChunkName: "DepartmentList" */
            '../components/management/OrgDepartmentList.vue'
          ),
        props: true
      },
      {
          path: 'departments/:groupId',
          name: 'department-detail',
          props: (route) => {
            return { groupId: route.params.groupId, isDepartment: true }
          },
          component: () =>
            import(
              /* webpackChunkName: "DepartmentUserList" */
              '../components/management/GroupUserList.vue'
            ),
        },
      {
        path: 'users',
        name: 'organization-users',
        component: () =>
          import(
            /* webpackChunkName: "UserList" */
            '../components/management/UserList.vue'
          ),
        props: true,
        // beforeEnter: (to, from, next) => {
        //   const { userIsAdmin, userIsOrganizationAdmin, userOrganizationId } =
        //     useUserRoles()
        //   if (userIsAdmin()) {
        //     next()
        //     return
        //   }
        //   if (!userIsOrganizationAdmin() && userOrganizationId() !== to.params.id) {
        //     // TODO toast
        //     next('/')
        //   } else {
        //     next()
        //   }
        // },
        // meta: {
        //   layout: AdminDefaultLayout,
        //   requiresAuth: [ROLES.ADMIN, ROLES.SUPERVISOR],
        // },
      },
      // {
      //   path: 'plans/:planId',
      //   name: 'plan-edit',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "PlanForm" */
      //       '../components/management/plans/PlanForm.vue'
      //     ),
      //   props: (route) => {
      //     return { id: route.params.planId }
      //   },
      // },
      {
        path: 'plans',
        name: 'plan-users',
        component: () =>
          import(
            /* webpackChunkName: "PlanTab" */
            '../components/management/plans/PlanTab.vue'
          ),
        // props: true,
        props: (route) => {
          return { organizationId: route.params.id, id: route.params.id }
        },
        meta: {
          layout: DefaultLayout,
          subject: 'expired'
        },
      },

    ]
  },
  // {
  //   path: '/organization/:id/user/signup',
  //   name: 'organization-users-signup',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "profile" */
  //       '../views/OrganizationUsersSignup.vue'
  //     ),
  //   props: (route) => {
  //     return { id: route.params.id }
  //   },
  //   meta: {
  //     layout: AdminDefaultLayout
  //   },
  // },
  {
    path: '/admin/organizations',
    name: 'organizations',
    component: () =>
      import(
        /* webpackChunkName: "organizations-page" */
        '../components/management/OrganizationList.vue'
      ),
    meta: {
      layout: DefaultLayout,
      requiresAuth: [ROLES.ADMIN],
      modalAllowed: true,
    },
    children: [
      {
        path: '/create',
        name: 'create-organization',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "organization-form" */
            '../components/management/OrganizationForm.vue'
          ),
        meta: {
          layout: DefaultLayout,
          requiresAuth: [ROLES.ADMIN],
        },
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */
        '../views/PageNotFound.vue'
      ),
    meta: {
      layout: DefaultLayout,
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(/*to, from, savedPosition*/) {
    // always scroll to top
    return { top: 0 }
  },
  // scrollBehavior: function (to) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash
  //     }
  //   }
  //   return { top: 0 }
  // },
  routes,
})

const isAuthorized = async (requiresAuth, isAuthenticated, user) => {
  if (!requiresAuth) {
    return true
  }

  if (requiresAuth && !isAuthenticated) {
    return false
  }

  if (requiresAuth === true && isAuthenticated) {
    return true
  }

  if (!user || !user.role) {
    return false
  }

  if (Array.isArray(requiresAuth) && requiresAuth.indexOf(user.role) === -1) {
    return false
  }
  return true
}

const allowedRouteForExpiredToo = ['Register', 'plan-users', 'login-redirect'];


router.beforeEach(async (to, from) => {
  //const { initiateLogin } = useUserData()
  const { isAuthenticated, user } = await useUserData()

  const { searchTerm } = useCourseResults()
  // console.log('router waiting for KC')
  //await getKeyCloak()
  // check from authentication store if token was loaded
  const authStore = await useAuthStore();
  if(authStore.getKCToken() == null){
  await authStore.checkAuthentication()
  }

  if (!authStore.authenticated && to.meta.requiresAuth) {
    authStore.keycloak.login({redirectUri: config.FRONTEND_URL+to.path})
  }



  //AWAIT USER LOADED DATA
  while(!user.is_loaded){

    await new Promise(r => setTimeout(r, 100));
    // console.log(user.is_loaded)
  }
  /*
  if (Object.hasOwn(window, 'Matomo')){
  const matomo = window.Matomo.getTracker()
  console.log(user.email)
  if(matomo.getUserId() == ''){
  matomo.setUserId(user.email)
  matomo.trackPageView()
  matomo.enableLinkTracking()
  }
  }*/
  // console.log('router got KC')
  const subscription = getCurrentSubscription();
  const expired = !subscription || ['active', 'pending'].indexOf(subscription.plan_status) === -1

  const { userIsAnyAdmin } = useUserRoles();
  const { getUser } = useUserData();
  const currentUser = getUser()
  /*if (to.path == '/subscription' && !isAuthenticated.value){
    return { path: '/'};
  }*/

  // If it is authenticated but
  // - it is expired
  // - and the route is not allowed
  // - and the route requires auth or it isn't subject == 'expired', route will be subscription.

  if (isAuthenticated.value
    && allowedRouteForExpiredToo.indexOf(to.name) === -1
    && (expired
      || (subscription.plan_status === 'pending' && userIsAnyAdmin() && from.path === '/' && typeof from.name === 'undefined') /* in pending only for the first time */
    )
    && (to.meta.requiresAuth || to.meta.subject !== 'expired')
  ) {
    if (userIsAnyAdmin() && currentUser.organization_info && currentUser.organization_info.pay_in_app) {
      // let path = `/admin/organization/${user.organization_id}/plans`;
      // return { path: path, params: {isExpired: true} }
      if (subscription.plan_status === 'expired') {
        ModalBus.emit(ModalCommands.OPEN, {
          component: ModalForExpired,
          props: {
            fit: true
          }
        })
      }
      return {name: 'plan-users', params: {id: user.organization_id}}
    } else {
      return {name: 'subscription'}
    }
  }

  // if (!to.query.q && ['search', 'detail', 'collection-detail'].indexOf(to.name) === -1) {
  if (!to.query.q && to.name !== 'detail') {
    searchTerm.value = ''
  }

  if (to.meta.requiresNoAuth && isAuthenticated.value) {
    return {
      path: '/',
    }
  }


  const isAuthorizedEval = await isAuthorized(
    to.meta.requiresAuth,
    isAuthenticated.value,
    user
  )

  // console.log(to.meta.requiresAuth)
  // console.log(isAuthenticated.value)
  // console.log(isAuthenticated)

  // console.log('isAuthorizedEval', isAuthorizedEval)
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if(!isAuthorizedEval ){
    //await initiateLogin()
    authStore.keycloak.login({redirectUri: config.FRONTEND_URL+to.path})
  }



  // if (to.meta.requiresAuth === true && !isAuthenticated.value) {
  //   if (!isAuthorizedEval) {
  //     // this route requires auth, check if logged in
  //     // if not, redirect to login page.
  //     if (!isAuthenticated.value) {
  //       initiateLogin()
  //       // return {
  //       //   path: '/login',
  //       //   // save the location we were at to come back later
  //       //   query: { redirect: to.fullPath }
  //       // }
  //     } else {
  //       return {
  //         path: '/',
  //       }
  //     }
  //   }
  // }
})

// router.beforeEach(async (to, from, next) => {
//   document.title = `Viblio - ${to.meta.title || 'Il mentor digitale'}`
//   next()
// })

export default router
