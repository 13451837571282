import { ref } from 'vue';

import {getSystemCollections} from "@/api/collections";

export function useCollectionsSystem () {
  const isSystemCollectionLoading = ref(true);
  const systemCollections = ref([]);

  const loadSystemCollection = async () => {
    if (systemCollections.value.length === 0) {
      try {
        const colls = await getSystemCollections();
        if (colls && !colls.error) {
          systemCollections.value = colls.results;
        } else {
          console.error('[useSystemCollection.vue] error: ' + colls.error);
          isSystemCollectionLoading.value = false;
        }
      } catch (e) {
        console.error('[useSystemCollection.vue] error: ' + e);
        isSystemCollectionLoading.value = false;
      }
    }
  };
  loadSystemCollection();
  const refreshSystemCollection = async () => {
    systemCollections.value = [];
    loadSystemCollection();
  };

  return {
    isSystemCollectionLoading,
    systemCollections,
    refreshSystemCollection,
  };
}
