<template>
  <div>
    <SideBarMenu
      v-if="menu"
      class="hidden md:flex"
    />
    <Sidebar
      v-model:visible="visible"
      class="block md:hidden w-fit fixed top-0 left-0 h-full"
    >
      <template #container="{ closeCallback }">
        <SideBarMenu @close="closeCallback" />
      </template>
    </Sidebar>
    <main
      :class="{
        main: navbar, 'w-full m-0': !menu,
        'm-0 md:ml-[300px]': menu}"
    >
      <NavBar
        v-if="navbar"
        @openSideBar="visible = true"
        @closeSideBar="visible = false"
      />
      <error-manager />
      <slot />
    </main>
  </div>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
import { ref, onMounted, computed } from "vue";
import Sidebar from 'primevue/sidebar';

import SideBarMenu from '@/components/sidebar/SideBar.vue';
import ErrorManager from '@/components/ErrorManager.vue';
import NavBar from '@/components/sidebar/NavBar.vue';
import ModalAskForAgreement from '@/components/ModalAskForAgreement.vue'
import { ModalBus, ModalCommands } from '@/eventBus'
import { useUserData, useUserRoles } from '@/composables/userData.js';

export default {
  name: 'DefaultLayout',
  components: {
    NavBar,
    SideBarMenu,
    Sidebar,
    ErrorManager,
},
  props: {
    menu: { type: Boolean, default: true },
    navbar: { type: Boolean, default: true },
  },
   setup() {
    const { userNeedsToChooseRole } = useUserRoles()
    const { locale } = useI18n();

    const { width } = useWindowSize()
    const isMobile = computed(() => width.value < 600)
    const visible = ref(false);

    const openConsentModal  = () => {
      ModalBus.emit(ModalCommands.OPEN, {
          component: ModalAskForAgreement,
          allowedInExpired:true,
          title:"",
          unclose:true,
          props: {

          },
          class: "",
          style: "width:auto;height:auto;margin:0;padding:0;",

        });
    }

    onMounted(async () => {
      const { isAuthenticated, user } = await useUserData()
      if(locale.value !== user.lang){
        locale.value = user.lang
      }
      if(isAuthenticated.value && !user.made_choice && !userNeedsToChooseRole())
      {
        openConsentModal()
      }
    })

    return {visible, isMobile}
  }
}
</script>
<style>
.icon-bar {
  width: 2rem;
  height: 2rem;
  padding: 0.4rem;
}
.sidebar-mobile {
  width: 16.25rem; /* var(--dimension-width-sidebar); */
}
</style>
