<template>
  <div
    v-if="status_val=='NEUTRAL'"
    class="group flex z-30"
  >
    <Button
      class="w-8 h-8 flex-shrink-0"
      icon="icon-thumbs-up"
      rounded
      outlined
      @click.stop=""
    />
    <div class="hidden group-hover:flex absolute gap-x-4 bg-blue-100 rounded-full p-2 -m-2">
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-up"
        rounded
        outlined
        @click.stop="ChangeStatusTo('LIKED')"
      />
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-down"
        rounded
        outlined
        @click.stop="ChangeStatusTo('UNLIKED')"
      />
    </div>
  </div>
  <div
    v-else-if="status_val=='LIKED'"
    class="group flex z-30"
  >
    <Button
      class="w-8 h-8 flex-shrink-0 "
      icon="icon-thumbs-up-solid"
      rounded
      outlined
      @click.stop=""
    />
    <div class="hidden group-hover:flex absolute gap-x-4 bg-blue-100 rounded-full p-2 -m-2">
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-up-solid"
        rounded
        outlined
        @click.stop="ChangeStatusTo('NEUTRAL')"
      />
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-down"
        rounded
        outlined
        @click.stop="ChangeStatusTo('UNLIKED')"
      />
    </div>
  </div>
  <div
    v-else-if="status_val=='UNLIKED'"
    class="group flex z-30"
  >
    <Button
      class="w-8 h-8 flex-shrink-0 "
      icon="icon-thumbs-down-solid"
      rounded
      outlined
      @click.stop=""
    />
    <div class="hidden group-hover:flex absolute gap-x-4 bg-blue-100 rounded-full p-2 -m-2">
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-down"
        rounded
        outlined
        @click.stop="ChangeStatusTo('LIKED')"
      />
      <Button
        class="hidden group-hover:flex w-8 h-8 flex-shrink-0 hover:bg-blue-300"
        icon="icon-thumbs-down-solid"
        rounded
        outlined
        @click.stop="ChangeStatusTo('NEUTRAL')"
      />
    </div>
  </div>
</template>
<script setup>
import Button from 'primevue/button';
import { ref,defineProps } from 'vue';
import { setResourceStatus } from '../../api/user'; 

const props = defineProps({
  status:{ type: String, default: "NEUTRAL" },
    resource_id:{ type: String, default: "" },
    resource_type:{ type: String, default: "" }
})
const status_val = ref(props.status)
const ChangeStatusTo = async(state) =>{
    await setResourceStatus(props.resource_id,props.resource_type,state)
    status_val.value = state
}

</script>