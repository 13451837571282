
import BaseModel from "@/resources/BaseModel";
export default class TrackedModel extends BaseModel {
  constructor(data) {
    super(data);

    this.date_create = BaseModel.asDate(data.date_create);
    this.date_update = BaseModel.asDate(data.date_update);
    this.date_delete = BaseModel.asDate(data.date_delete);

    return this;
  }

}
