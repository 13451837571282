
const courseProviders = {
  coursera: {
    label: "Coursera",
    website: "https://www.coursera.org/",
    slug: "coursera"
  },
  edx: {
    label: "EDX",
    website: "https://www.edx.org/",
    logo: "edx.png",
    slug: "edx"
  },
  future_learn: {
    label: "Future Learn",
    website: "https://www.futurelearn.com/",
    logo: "future_learn.svg",
    slug: "future_learn"
  },
  harvard_business_school: {
    label: "Harvard Business School",
    website: "https://online.hbs.edu/",
    logo: "harvard_business_school.png",
    slug: "harvard_business_school"
  },
  mxe: {
    label: "mixergy",
    logo: "mixergy.png",
    slug: "mxe"
  },
  ted: {
    label: "TEDx",
    website: "https://www.ted.com/podcasts",
    logo: "ted.png",
    slug: "ted"
  },
  uda: {
    label: "Udacity",
    website: "https://www.udacity.com/",
    logo: "udacity.png",
    slug: "uda"
  },
  udemy: {
    label: "Udemy",
    website: "https://www.udemy.com",
    logo: "",
    slug: "udemy"
  },
  youtube: {
    label: "Youtube",
    website: "https://www.youtube.com",
    logo:"youtube.png",
    slug:"youtube"
  },
  polimi: {
    label: "Polimi",
    website: "https://www.polimi.it",
    logo:"polimi.png",
    slug:"polimi"
  },
  aws: {
    label: "AWS",
    website: "https://explore.skillbuilder.aws",
    logo:"aws.png",
    slug:"aws"
  },
  codecademy: {
    label: "Codecademy",
    website: "https://www.codecademy.com/",
    logo:"codecademy.png",
    slug:"codecademy"
  },
  spreaker: {
    label: "Spreaker",
    website: "https://www.spreaker.com/",
    logo:"spreaker.png",
    slug:"spreaker"
  },
  spotify: {
    label: "Spotify",
    website: "https://open.spotify.com/",
    logo:"spotify.png",
    slug:"spotify"
  },
  steel_university: {
    label: "Steel University",
    website: "https://steeluniversity.org/",
    logo:"steel_university.png",
    slug:"steel_university"
  },
  learning_365: {
    label: "Learning 365",
    website: "https://learning365.it/",
    logo:"learning365.png",
    slug:"learning_365"
  }
};

function getProvider(id) {
  return courseProviders[id];
}

function getLogo(providerId) {
  if (providerId && providerId in courseProviders) {
    return courseProviders[providerId].logo;
  }
  return "";
}

export default function useCourseProviders() {
  return { courseProviders, getProvider, getLogo };
}

// export const providersForProfile = (profile) => {
//   // console.log({ profile})
//   let ret = [];
//   if(profile == BUSINESS_PROFILES.fullon_business_user) {
//     ret = [
//       courseProviders.coursera,
//       courseProviders.ted,
//       courseProviders.harvard_business_school,
//       courseProviders.mxe
//     ];
//   }
//   if(profile == BUSINESS_PROFILES.business_user) {
//     ret = [
//       courseProviders.ted,
//       courseProviders.harvard_business_school,
//       courseProviders.mxe,
//       courseProviders.udemy,
//       courseProviders.uda,
//       courseProviders.future_learn,
//       courseProviders.edx
//     ];
//   }
//   return ret;
// }
