<template>
  <div
    class="banner"
    data-test="interview-box"
  >
    <p>
      <span class="text-banner-pretitle">
        {{ $t('home_box.error_pre_title') }}
      </span>
      <br>
      <span class="text-banner-title">
        {{ $t('home_box.error_title') }}
      </span>
      <br>
      <span class="banner-description">
        {{ $t('home_box.error_msg') }}
      </span>
    </p>
    <button
      class="button button-primary button-large"
      @click="openInterviewModal"
    >
      <i class="fas fa-video" />
      {{ $t('home_box.error_cta') }}
    </button>
  </div>
</template>
<script>
import StartInterview from '@/views/StartInterview.vue';
import {ModalBus, ModalCommands} from "@/eventBus";

export default {
  setup(){
const openInterviewModal = () => {
      ModalBus.emit(ModalCommands.OPEN, {
          component: StartInterview,
          title:"Video Interview",
          modalClass: "interview-modal",
        })
    }
    return {openInterviewModal}
  }
}

</script>
<style scoped>
.text-banner-pretitle {
flex: 1 0 0;
color: var(--Text-on-fill, #FFF);
/* text-xs/bold */
font-family: var(--font-text);
font-size: 0.6875rem;
font-style: normal;
font-weight: 700;
line-height: 0.875rem; /* 127.273% */
text-transform: none!important;
}

.text-banner-title {
  color: var(--Text-on-fill, #FFF);
/* text-xl/bold */
font-family: var(--font-text);
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 2rem; /* 133.333% */
}


.banner-description  {
  color: var(--Text-on-fill, #FFF);
/* text-sm/regular */
font-family: var(--font-text);
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1rem; /* 133.333% */
}

.button-large{
  display: flex;
padding: 0.65625rem 1.09375rem;
align-items: center;
gap: 0.4375rem;
border-radius: 1.75rem;
border: 1px solid var(--Border-primary, #004BE5);
background: var(--Fill-primary, #004BE5);
color: var(--Text-on-fill, #FFF);
/* text-default/bold */
font-family: var(--font-text);
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 1.25rem; /* 142.857% */
}


.banner{
  display: flex;
width: 29.375rem;
height: 12.75rem;
padding: var(--space-4, 1rem);
align-items: flex-end;
gap: var(--space-5, 1.25rem);
flex-shrink: 0;
}
</style>

