<template>
  <div
    class="banner"
    data-test="interview-started-box"
  >
    <div class="banner-content">
      <h5 class="text-banner-pretitle">
        {{ $t('home_box.logged_pre_title') }}
      </h5>
      <h4 class="text-banner-title">
        {{ $t('home_box.logged_title') }}
      </h4>
      <p>
        <span class="text-banner-description">
          {{ $t('home_box.started_msg') }}
        </span><br>
        <router-link
          class="button button-primary button-large"
          to="/interview"
        >
          {{ $t('home_box.started_cta') }}
        </router-link>
        <!-- <span class="text-banner-description">
          È semplice ci vorranno solo pochi minuti
        </span> -->
        <br><br>
        <span class="text-banner-small">
          {{ $t('home_box.interview_banner') }} <strong>Mozilla Firefox</strong> e <strong>Google Chrome</strong>
        </span>
      </p>
    </div>
  </div>
</template>
