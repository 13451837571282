import TrackedModel from "@/resources/TrackedModel";

export default class Resource extends TrackedModel {

  constructor(data) {
    super(data);

    this.level = data.level || "";
    this.resource_type = data.resource_type || "";
    this.provider = data.provider || "";
    this.provider_display = data.provider_display || "";
    this.language = data.language || "";
    this.url = data.url || "";
    this.title = data.title || "";
    this.description = data.description || "";
    this.collections_list = data.collections_list || [];
    this.skills = data.skills || [];
    this.main_skill = this.skills ? this.skills[0] : null;
    this.skills_counter = this.skills.length;
    this.opening_date = data.opening_date || null;
    this.collections = data.collections || 0;
    this.completed = data.completed || false;
    this.opening_count = data.opening_count || 0;
    this.opening_date_human = TrackedModel.asOutputDate(data.opening_date, 'it-IT') || null;
    this.points = data.points || null;
    this.classification = data.classification || '';
    this.hit_for_occupation = data.hit_for_occupation;
    this.deleted = data.deleted || false;
    this.authors = data.authors || [];
    this.permissions.delete = false;
    this.user_resource_status = data.user_resource_status;
    // this.rel_users = [];

    // let users = this.is_department ? data.users : data.rel_users;
    // if( users ) {
    //   this.rel_users = users.map(user => {
    //     let userObj = User.fromApiResult(user);
    //     userObj.permissions.edit = false;
    //     userObj.permissions.delete = this.assigned_to !== userObj.id;
    //     return userObj;
    //   });
    // }

    this.price = data.price || 0;
    const placeholderType = this.getPlaceHolderType();
    this.image = {
      src: data.image || require(`@/assets/${placeholderType}`),
      // src: require(`@/assets/${placeholderType}`),
      width: 512,
      height: 512
    };
    this.placeholder = {
      src: require(`@/assets/${placeholderType}`),
      width: 512,
      height: 512
    };


    return this;
  }

  getPlaceHolderType(){
    if(this.resource_type.toLowerCase() === "podcast"){
      return 'placeholder-podcast-kid.jpg';
    }

    if(this.provider.toLowerCase() === 'udemy'){
      return 'course-udemy-placeholder.jpg';
    }

    return 'course-placeholder.svg';
  }



  static fromApiResult(data) {
    const obj = new Resource(data);
    return obj;
  }
}
