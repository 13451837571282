<template>
  <div>
    <h4 v-html="title" />
    <h5 v-html="subtitle" />
    <p v-html="message" />
    <div class="buttons">
      <Button
        class="p-button-rounded white-space-nowrap"
        :label="cancelButtonText"
        severity="secondary"
        @click="closeModal"
      />
      <Button
        data-test="modal-confirm"
        class="p-button-rounded white-space-nowrap"
        :label="confirmButtonText"
        @click="confirm"
      />
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import { ModalBus, ModalCommands } from "@/eventBus";
import Button from 'primevue/button';
export default {
  name: "Confirm",
  components: { Button },
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    message: { type: String, default: "" },
    confirmText: { type: String, default: "" },
    cancelText: { type: String, default: "" },
    confirmFunction: { type: Function, default: () => {} },
    closeFunction: { type: Function, default: () => {} },
  },
  setup(props) {
    const { t } = useI18n();
    const cancelButtonText = props.cancelText?.length > 0 ? props.cancelText : t("general.cancel");
    const confirmButtonText = props.confirmText?.length > 0 ? props.confirmText : t("general.confirm");
    const closeModal = () => {
      ModalBus.emit(ModalCommands.CLOSE, {});
      props.closeFunction();
    };
    const confirm = () => {
      props.confirmFunction();
      closeModal();
    };
    return { cancelButtonText, confirmButtonText, closeModal, confirm };
  },
};
</script>
<style scoped>
h2 {
  font-family: var(--fonts-main-font);
  font-size: 16px;
  font-weight: 700;
  line-height: auto;
  color: var(--color-gray-darken);
}
h3 {
  font-family: var(--fonts-main-font);
  font-size: 16px;
  font-weight: 400;
  line-height: auto;
  color: var(--color-gray-darken);
}
p {
  font-family: var(--fonts-main-font);
  font-size: 14px;
  font-weight: 300;
  line-height: auto;
  font-style: italic;
  color: var(--color-gray-middle);
  margin-bottom: 30px;
}
.buttons {
  display: flex;
  justify-content: space-between;
}
</style>
