<template>
  <div class="expired-container">
    <div class="banner-text">
      <span class="banner-title expired-title">
        {{ $t('expired.title') }}
      </span>
      <span class="banner-msg">
        {{ $t('expired.subtitle') }}
      </span>
    </div>
    <Button
      data-test="change-plan"
      class="p-button-rounded white-space-nowrap expired-btn"
      :label="$t('expired.cta')"
      @click="changePlanCTA"
    />
    <div class="banner-text">
      <span class="banner-msg">
        {{ $t('expired.help_cta') }}
        <a
          :href="'mailto:'+APP_EMAIL"
          target="_blank"
        >
          {{ APP_EMAIL }}
        </a>
      </span>
    </div>
    <div class="expired-footer">
      <div class="banner-text">
        <span class="banner-title">
          {{ $t('expired.footer_title') }}
        </span>
        <span class="banner-msg">
          {{ $t('expired.footer_subtitle') }}
        </span>
      </div>
      <div>
        <Button
          data-test="row-button"
          :label="$t('expired.footer_cta')"
          :link="true"
          @click="goToSurvey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Config from "@/config";

import {getCurrentSubscription, useUserData} from "@/composables/userData";
import {ModalBus, ModalCommands} from "@/eventBus";
import ChangePlanFormWrapper from "@/components/management/plans/ChangePlanFormWrapper.vue";

export default {
  name: "ModalForExpired",
  components: {
      Button
  },
  setup(){

    const { APP_EMAIL, SURVEY_QUIZ_IT, SURVEY_QUIZ_EN } = Config;
    const { getUser } = useUserData()
    const subscription = getCurrentSubscription();
    const currentUser = getUser()

    const changePlanCTA = () => {
      ModalBus.emit(ModalCommands.OPEN, {
        component: ChangePlanFormWrapper,
        allowedInExpired: true,
        props: {
          currentPlan: null,
          choosePlan: '',
          personal: currentUser.organization_info.personal,
          planStatus: subscription.plan_status
        },
      })
    }
    const goToSurvey = () => {
      const survey_link = currentUser.lang === 'it' ? SURVEY_QUIZ_IT : SURVEY_QUIZ_EN;
      window.open(survey_link, '_blank');
    }
    return {
      APP_EMAIL,
      changePlanCTA,
      goToSurvey
    }
  }
};
</script>

<style scoped>

.expired-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}
.expired-title {
  font-size: 1.375rem;
  line-height: 2rem; /* 145.455% */
}
.expired-btn {
  width: 100%;
}
.expired-footer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
  border-top: solid 0.0625rem #D9D9D9;
  padding-top: 1.5rem;
}
</style>
