import { ref } from 'vue'
import { useUuidSearch } from '@/composables/useElasticCourseResults'
import { useUserData } from '@/composables/userData'

const { getUser } = useUserData()
const { getResources } = useUuidSearch()

export default function useCollectionItems(id, limit=30, offset=0) {
  const data = ref([])
  const isLoading = ref(true)
  const [type, name] = id.split('|')
  const currentUser = getUser()

  let params

  if (['occupation', 'interview'].includes(type)) {
    params = {
      filters: {
        system_collection: {
          key: 'system_collection',
          value: currentUser.id + '|' + name,
          matchMode: 'equals',
        },
      },
    }
  } else if (type === 'skill') {
    params = {
      filters: {
        system_collection: {
          key: 'system_collection',
          value: id,
          matchMode: 'equals',
        },
      },
    };
  } else {
    params = {
      filters: {
        collection: {
          key: 'collection',
          value: id,
          matchMode: 'equals',
        },
      },
    }
  }
  const loadResources = (forceLoad) => {
    if (data.value.length === 0 || forceLoad) {
      getResources(limit, offset, params).then((response) => {
        if (!response.error) {
          data.value = response.results;
          isLoading.value = false;
        } else {
          isLoading.value = false;
          console.error('Error fetching collection items:', response.error);
        }
      });
    }
  };
  loadResources();
  const refreshResources = () => {
    loadResources(true);
  };

  return {
    data,
    isLoading,
    refreshResources
  };
}
