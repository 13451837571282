import TrackedModel from "@/resources/TrackedModel";
import {useUserRoles} from "@/composables/userData";

export default class Organization extends TrackedModel {

  constructor(data) {
    super(data);
    const { userIsAdmin } = useUserRoles();
    this.name = data.name || '';
    this.address = data.address || null;
    this.referent = data.referent || '';
    this.address_detail = data.address_detail || null;
    this.province = data.province || null;
    this.legal_address = data.legal_address || null;
    this.zip_code = data.zip_code || null;
    this.city = data.city || null;
    this.country = data.country || null;
    this.cf_code = data.cf_code || null;
    this.vat_code = data.vat_code || null;
    this.phone = data.phone || null;
    this.email = data.email || '';
    this.description = data.description || '';
    this.status = data.status || '';
    this.url = data.url || '';
    this.domain = data.domain || '';
    // this.allowed_job_roles = data.allowed_job_roles;
    this.allow_interview = data.allow_interview || true; // not used, removed from serializer
    this.allow_member_wishlist = data.allow_member_wishlist || true; // not used, removed from serializer
    this.allow_member_collection = data.allow_member_collection || true; // not used, removed from serializer
    this.allow_member_share = data.allow_member_share || true; // not used, removed from serializer
    this.personal = data.personal || false;
    this.source = data.source || 'Direct';
    this.stripe_customer_id = data.stripe_customer_id || '';
    this.pay_in_app = data.pay_in_app || false;
    this.trial = data.trial || true;
    this.trial_days = data.trial_days || 14;
    this.payment_attached_date = data.payment_attached_date || null;
    this.users_counter = data.users_counter || 0;

    this.disabled = this.status === "DELETED";
    this.permissions.delete = true; // !this.disabled;
    this.permissions.select = false;
    this.permissions.manage = true;
    this.permissions.convert = userIsAdmin() && !this.pay_in_app && this.status === 'ACTIVE';

    return this;
  }
  convertForListBoxOption() {
    return {
      key: this.id || 'null',
      label: this.name || 'N/D',
      users_counter: this.users_counter || '0'
    };
  }

  static fromApiResult(recordData) {
    const recordObj = new Organization(recordData);
    return recordObj;
  }
}
