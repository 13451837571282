import {authorizedRequest, manageApiError, responseApi, transformFilters} from '@/api';
import config from "@/config";
import Collection from "@/resources/Collection";


async function getCollections(limit, offset, filters) {
  const filtersFormatted = transformFilters(filters);
  const paginationParams = `limit=${limit}&offset=${offset}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/collections/${params}`);
    // return {error: false, result: result.data};
    return responseApi(Collection, {error: false, result: result.data}, false, true);
  } catch (error) {
    return manageApiError(error);
  }
}
async function getSystemCollections() {
  // return the title of system collections (phantom collections)
  try {
    const result = await authorizedRequest().get(`api/v2/drf/collections/system/`)
    return responseApi(Collection, {error: false, result: result.data}, false, true);
  } catch (error) {
    return manageApiError(error);
  }
}

// TO BE remove
// async function interview() {
//   try {
//     const result = await authorizedRequest().get(`api/v2/drf/collections/interview/`)
//     return result.data
//   } catch (error) {
//     return { error }
//   }
// }

// async function roles() {
//   try {
//     const result = await authorizedRequest().get(`api/v2/drf/collections/roles/`)
//     return result.data
//   } catch (error) {
//     return { error }
//   }
// }
//
// async function skills() {
//   try {
//     const result = await authorizedRequest().get(`api/v2/drf/collections/skills/`)
//     return result.data
//   } catch (error) {
//     return { error }
//   }
// }
//
// async function list() {
//   try {
//     let result = []
//     const personal = playlists()
//     const interview = interview()
//     const roles = roles()
//     const skills = skills()
//     result = result.concat(personal)
//     result = result.concat(interview)
//     result = result.concat(roles)
//     result = result.concat(skills)
//     console.log(result)
//     return result.data
//   } catch (error) {
//     return { error }
//   }
// }


async function getCollectionById(id) {
  try {
    const result = await authorizedRequest().get(`/api/v2/drf/collections/${id}/`)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}
async function addResource(data) {
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/collections/${data.id}/add_resource/`, data)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}
async function removeResource(data) {
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/collections/${data.id}/remove_resource/`, data)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}



async function create(data) {
  try {
    const result = await authorizedRequest().post('/api/v2/drf/collections/', data)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}

async function patch(data) {
  try {
    const result = await authorizedRequest().patch(`/api/v2/drf/collections/${data.id}/`, data)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}

async function shareCollection(id, shareType='SHARE_ORG', optionIds=null) {
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/collections/${id}/share/`, {share_type: shareType, option_ids: optionIds})
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}
async function unshareCollection(id) {
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/collections/${id}/unshare/`)
    return responseApi(Collection, {error: false, result: result.data}, true, true);
  } catch (error) {
    return manageApiError(error);
  }
}

async function deleteColl(id) {
  try {
    const result = await authorizedRequest().delete(`/api/v2/drf/collections/${id}/`)
    return result.data
  } catch (error) {
    return { error }
  }
}



export { getCollections, getSystemCollections, getCollectionById, create, patch, addResource, removeResource, deleteColl, shareCollection, unshareCollection}
