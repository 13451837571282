<template>
  <div class="interview-status-banner">
    <component :is="InterviewBanner" />
  </div>
</template>
<script>
import RegisterBox from '@/components/RegisterBox.vue'
import InterviewBox from '@/components/InterviewBox.vue'
import InterviewFailedBox from '@/components/InterviewFailedBox.vue'
import InterviewStartedBox from '@/components/InterviewStartedBox.vue'
import InterviewResultsBox from '@/components/InterviewResultsBox.vue'
import { useInterview, useUserData } from '@/composables/userData'
import { computed } from "vue";
const engagementBoxes = {
      register: RegisterBox,
      started: InterviewStartedBox,
      interview: InterviewBox,
      has_interview: InterviewResultsBox,
      in_error: InterviewFailedBox,
    }
export default {
  name: 'InterviewStatusBanner',
  setup() {
    const { userInterviewState}  = useInterview();
    const { isAuthenticated } = useUserData();
    const InterviewBanner = computed(() => {
      if (isAuthenticated.value) {
        if(userInterviewState.isCreated()) return engagementBoxes.interview;
        if(userInterviewState.isEmpty()) return engagementBoxes.interview;
        if(userInterviewState.isFailed()) return engagementBoxes.in_error;
        return (userInterviewState.hasInterview()) ? null : engagementBoxes.interview;
      }

      return engagementBoxes.register
    })
    return { InterviewBanner }
  },
}
</script>

