<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
    data-test="collection-manager"
    class="w-full sm:w-[600px] m-4"
    :header="$t(createMode ? 'collections.create' : 'collections.add_to_collection')"
  >
    <div class="flex flex-col gap-4">
      <div class="text-l">
        {{ createMode ? $t('collections.add_to_a_new_collection') : $t('collections.add_to_a_collection') }}
      </div>
      <div
        v-show="!createMode"
        class="flex flex-col gap-4"
      >
        <CollectionSelectForm
          :course="course"
          :show="model"
          @closeModal="() => { model=false; createNew = false }"
          @refreshDetail="refreshDetail"
        />
        <hr class="line-divider">
        <div class="flex flex-col gap-2 sm:flex-row sm:justify-between items-center">
          <div>
            {{ $t('collections.new_collection_button_text') }}
          </div>
          <Button
            type="button"
            icon="uil uil-plus"
            :link="true"
            :label="$t('collections.new_collection')"
            data-test="collection-manager-create-new-btn"
            @click="createNew = true"
          />
        </div>
      </div>
      <div
        v-show="createMode"
      >
        <CollectionForm
          :create="true"
          :course="course"
          :show="model"
          @closeModal="() => { model=false; createNew = false }"
          @refreshDetail="refreshDetail"
        />
      </div>
    </div>
  </Dialog>
</template>
<script setup>

import {ref, watch, computed, defineProps, defineModel, defineEmits} from 'vue'

import Resource from "@/resources/Resource";
import CollectionSelectForm from "./CollectionSelectForm.vue";
import CollectionForm from "./CollectionForm.vue";

import Dialog from 'primevue/dialog';
import Button from "primevue/button";


// name: 'CollectionManager',
const props = defineProps({
  course: { type: Resource, required: true },
  onlyCreateMode: { type: Boolean, default: false}
});
const model = defineModel({ default: false });

const emit = defineEmits([ 'refresh-detail'])
watch(() => model.value, (value) => {
  if (!value) {
    createNew.value = false;
  }
});

const createNew = ref(false)

const createMode = computed(() => {
  return createNew.value || props.onlyCreateMode
})

const refreshDetail = () => {
  emit('refresh-detail', props.course?.id)
}
</script>
