
export default function sourceCollection (systemType, fromSingleShare=false) {
  let tag = "";
  switch (systemType) {
    case 'occupation':
      tag = "COLLECTION_SYSTEM_OCCUPATION";
      break;
    case 'occupation_hard':
      tag = "COLLECTION_SYSTEM_HARD";
      break;
    case 'occupation_soft':
      tag = "COLLECTION_SYSTEM_SOFT";
      break;
    case 'occupation_hot_tech':
      tag = "COLLECTION_SYSTEM_HOT_TECH";
      break;
    case 'interview':
      tag = "COLLECTION_SYSTEM_INTERVIEW";
      break;
    case 'skill':
      tag = "COLLECTION_SYSTEM_SKILLS";
      break;
    case 'global':
      tag = "COLLECTION_GLOBAL";
      break;
    case 'from_mine':
      tag = "COLLECTION_PRIVATE";
      break;
    case 'from_organization':
      tag = "COLLECTION_ORG";
      break;
    case 'from_group':
      tag = fromSingleShare ? "COLLECTION_MEMBER" : "COLLECTION_GROUP";
      break;
    case 'SEARCH':
      tag = "SEARCH";
      break;
    default:
      tag = "";
  }
  return tag;
}
