<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <Alert
        v-if="errorResponse"
        :title="$t('errors.general_title')"
        type="error"
      >
        <template #content>
          <p>
            {{ $t('errors.general_msg') }}
            {{ errorResponse }}
          </p>
        </template>
      </Alert>
      <InputWrapper
        v-if="loadedCollections['personal']"
        name="collections"
        type="text"
        label="collections.title"
        :required="true"
        :field-error="showError"
      >
        <MultiSelect
          v-model="selectedCollections"
          display="chip"
          :options="collections"
          :show-toggle-all="false"
          field="collections"
          icon="fa fa-filter"
          name="collections"
          data-key="id"
          option-label="title"
          option-disabled="disabled"
          :placeholder="$t('general.select')"
        >
          <template #option="slotProps">
            <div>
              {{ slotProps.option.title }} <span>{{ slotProps.option.disabled ? $t('general.select_added') : '' }}</span>
            </div>
          </template>
          <template #removetokenicon="slotProps">
            <i
              class="p-multiselect-token-icon uil uil-times-circle"
              :class="{'hide': slotProps.item['disabled']}"
              @click="slotProps.removeCallback"
            />
          </template>
        </MultiSelect>
      </InputWrapper>
      <Skeleton
        v-else
        width="full"
        height="2.5rem"
        class="p-4"
        border-radius="4px"
      />
      <div class="flex flex-col-reverse gap-4 sm:flex-row sm:justify-end">
        <Button
          type="button"
          :label="$t('general.cancel')"
          severity="secondary"
          @click="$emit('close-modal')"
        />
        <Button
          type="button"
          data-test="collection-manager-create-new"
          :label="$t('collections.add_to_collection')"
          :loading="isSaving"
          @click="addToCollection"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, defineEmits, defineProps, ref, watch} from "vue";

import {useUserCollections} from "@/composables/useUserCollections";
import {useToasts} from "@/composables/useToasts";

import InputWrapper from "@/components/common/InputWrapper.vue";

import Resource from "@/resources/Resource";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import Alert from "@/components/Alert.vue";
import useCollectionsForUser from "@/composables/useCollectionsForUser";
import Skeleton from 'primevue/skeleton';

const { addResourceToCollection } = useUserCollections()

const { collectionList, loadedCollections } = useCollectionsForUser('personal')
const { toastSuccess } = useToasts();


// name: "CollectionSelectForm",
const props = defineProps({
    show: { type: Boolean, default: false },
    course: { type: Resource, required: true }
});
const emit = defineEmits(['close-modal', 'refresh-detail'])


const mapCollectionOptions = (c) => {
  return {
      'id': c.id,
      'title': c.title,
      'disabled': props.course?.collections_list.indexOf(c.title) > -1
    }
}

const collections = computed(() => {
  return collectionList.value['personal'].map((c) => {
    return mapCollectionOptions(c)
  })
})
const selectedCollections = ref([])

const showError = ref(false);
const isSaving = ref(false);
const errorResponse = ref(false);
const found = ref(0);
const initializeSelectedOptions = (userCols) => {
  for (let collectionTitle of props.course?.collections_list){
    if(selectedCollections.value.indexOf(collectionTitle) === -1){
      let c = userCols.filter((col) => col.title === collectionTitle)
      if (c.length) {
        let mapped = mapCollectionOptions(c[0])
        selectedCollections.value.push(mapped)
      }
    }
  }
  found.value = selectedCollections.value.length
}
if (!collectionList.value['personal']) {
  watch(() => collectionList.value['personal'], (userCols) => {
    initializeSelectedOptions(userCols)
  })
} else {
    initializeSelectedOptions(collectionList.value['personal'])
}

const addToCollection = async () => {
  errorResponse.value = false;
   if(selectedCollections.value.length <= found.value ) {
    showError.value = true
    return;
  }
  isSaving.value = true

  let collToSave = []
  let counterSuccess = 0

  for (let collection of selectedCollections.value) {
    if (!collection.disabled) {
      collToSave.push(
        addResourceToCollection({
        id: collection.id,
        resource_id: props.course.id
      }).then((res) => {
        if (res && !res.error) {
          counterSuccess += 1
          if (counterSuccess === selectedCollections.value.length) {
            toastSuccess('', 'collections.course_added', counterSuccess)
          }
        } else {
          errorResponse.value = true;
          console.log(res.error)
          errorResponse.value = res.error;
          return true
        }
      }).catch((e) => {
        errorResponse.value = true;
        errorResponse.value = e;
        return false
      })
      )
    }
  }

  Promise.all(collToSave).then( () => {
    isSaving.value = false;
    if (!errorResponse.value) {
      emit('close-modal')
      emit('refresh-detail')
    }

  })

}

</script>

