import { ref } from "vue";
import {request, authorizedRequest, transformFilters, manageApiError} from "@/api";
import config from '../config';

const updateProfileError = ref("");

async function list(limit= 20, offset= 0, paramsArgs=null) {
  const filtersFormatted = transformFilters(paramsArgs.filters);
  const ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;

  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/users/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}


async function fetchUserData(id) {
    // console.log('fetch user data', id)
    try {
        const result = await authorizedRequest().get(
            // `/user/${id}`
          // config.BACKEND_URL+`/api/v2/auth/user/`
          config.BACKEND_URL+`/api/v2/drf/users/${id}/`
        );
        return result.data;
    } catch (error) {
        console.error(error);
        return { error };
    }
}

async function storeOpenCourseInteraction(source,course_id){
    try {
        const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/drf/metrics_open_courses/`,
          {resource: course_id, opening_source: source}
        );
        return result;
    } catch (error) {
        console.error(error);
        return { error };
    }
}
async function stripeChangePlan(data){
    try {
        const result = await authorizedRequest().post(config.BACKEND_URL + `/api/v2/drf/plans/change_plan/`, data);
        console.error(result);
        return result;
    } catch (error) {
        console.error(error);
        return error;
    }
}
async function stripePortalSession(paymentFlow){
    try {
      const query_str = paymentFlow ? '?payment_flow=true' : '';
      const result = await authorizedRequest().get(config.BACKEND_URL + `/api/v2/drf/subscriptions/create_customer_portal_session/${query_str}`);
      if (result.data.session_url) {
        // window.open(result.data.session_url, '_blank');
        window.location.href = result.data.session_url;
      }
      return result;
    } catch (error) {
        console.error(error);
        return error;
    }
}


async function switchEmailNotification(user_id) {
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/users/${user_id}/switch_email_notification/`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

async function setAgreement(user_id, privacy_policy){
  try {
    const result = await authorizedRequest().post(`/api/v2/drf/users/${user_id}/set_agreement/`, {
      privacy_policy: privacy_policy,
      made_choice: true
    });
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

// async function isExpiredSubscription(){
//     try {
//         const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/subscriptions/user_is_expired/`
//         );
//         return result.data;
//     } catch (error) {
//         console.error(error);
//         return { error };
//     }
// }

// async function Subscription(){
//     try {
//         const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/subscriptions/user_data/`
//         );
//         return result.data;
//     } catch (error) {
//         console.error(error);
//         return { error };
//     }
// }

// async function CanceledSubscription(id){
//   if (!id) {
//     return {};
//   }
//     try {
//         const result = await request().post(config.VUE_APP_BACKEND_NODE_URL+`/api/v1/deleted_subscription/${id}/`
//         );
//         return result.data;
//     } catch (error) {
//         console.error(error);
//         return { error };
//     }
// }


async function registerOrganizationUserRequest(org_id, data) {
    if (!org_id) {
        return { error: "Organization id is required" }
    }
    try {
        const response = await request().post(`/organization/${org_id}/user/signup`, data);
        const {
            token,
            name,
            surname,
            id,
            email,
            role,
            active,
            occupation_1,
            organization_id
        } = response.data.data;
        const user = { name, surname, id, email, role, active, occupation_1, organization_id };
        return { token, user, error: null };
    } catch (error) {
        console.error("Got: " + error);
        let errors = ["Errore generico"];
        if (error.response) {
            errors = parseError(error.response.data.data);
        }
        return { token: "", user: {}, errors };
    }
}

async function registerRequest(data) {
    try {
        const response = await request().post("/user", data);
        const {
            token,
            name,
            surname,
            id,
            email,
            role,
            active,
            occupation_1,
            organization_id
        } = response.data.data;
        const user = { name, surname, id, email, role, active, occupation_1, organization_id };
        return { token, user, error: null };
    } catch (error) {
        console.error("Got: " + error);
        let errors = ["Errore generico"];
        if (error.response) {
            errors = parseError(error.response.data.data);
        }
        return { token: "", user: {}, errors };
    }
}

function parseError(data) {
    if (data === undefined) {
      return ["Errore generico"];
    }

    const keys = Object.keys(data);
    let errors = [];
    if (Array.isArray(keys) && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
            errors.push(data[keys[i]].message || keys[i]+": " + data[keys[i]]);
        }
    } else if (Array.isArray(data) && data.length > 0) {
      // data.map((fieldError) => {
      for (let j = 0; j < data.length; j++) {
        let msg = (typeof data[j] === "string") ? data[j] : Object.values(data[j]);
        errors.push(msg);
      }
        // );
    }else {
        errors.push("Operazione fallita");
    }
    return errors;
}

// async function updateProfile(userId, name, surname) {
//     try {
//         const data = {
//           'first_name': name,
//           'last_name': surname
//         };
//         // const result = await authorizedRequest().put(`/user/${userId}/`, data);
//         const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/users/${userId}/`, data);
//         updateProfileError.value = "";
//         return result.data;
//     } catch (error) {
//         console.error("Got: " + error);
//         if (error.response) {
//             console.error(
//                 "Error occurred when updating profile: ",
//                 error.response.data
//             );
//         }
//         updateProfileError.value = parseError(error.response.data);
//         return null;
//     }
// }

async function patchUser(userId, data) {
  try {
      // const result = await authorizedRequest().put(`/user/${userId}/`, data);
      // return result.data.data;
      const result = await authorizedRequest().patch(config.BACKEND_URL+`/api/v2/drf/users/${userId}/`, data);
      if ("first_name" in data){
          updateProfileError.value = "";
      }
      return result.data;
  } catch (error) {
      console.error("Got: " + error);
      if (error.response) {
          console.error(
              "Error occurred when updating profile: ",
              error.response.data
          );
          if ("first_name" in data){
              updateProfileError.value = parseError(error.response.data);
          }
      }
      return null;
  }
}

async function postInterviewRequest() {
    try {
        const result = await authorizedRequest().post("/api/v2/drf/interviews/new/");
        return result.data;
    } catch (e) {
        const error = e.message ? e.message : e.toString();
        return { error };
    }
}

async function getReport() {
    try {
        const result = await authorizedRequest(true).get(
            `/api/v2/drf/interviews/report`
        );
        return result;
    } catch (e) {
        const error = e.message ? e.message : e.toString();
        return { error };
    }
}

async function courseAccess(courseId, courseType) {
    try {
        const result = await authorizedRequest().post(`api/v2/drf/trackers/track/${courseId}/${courseType}/`);
        return result;
    } catch (e) {
        const error = e.message ? e.message : e.toString();
        return { error };
    }
}

async function setResourceStatus(courseId, courseType,status) {
    try {
        const result = await authorizedRequest().post(`api/v2/drf/trackers/set_status/${courseId}/${courseType}/`,{"status":status});
        return result;
    } catch (e) {
        const error = e.message ? e.message : e.toString();
        return { error };
    }
}

async function markAsCompleted(courseId, courseType) {
    try {
        return await authorizedRequest().post(`api/v2/drf/trackers/switch_mark_as_completed/${courseId}/${courseType}/`);
    } catch (e) {
        const error = e.message ? e.message : e.toString();
        return { error };
    }
}

async function userEmailExists(email){
try{
    const result = await request().post(`api/v2/drf/users/user_email_exists/`,{email:email});
        return result;
} catch (e) {
    const error = e.message ? e.message : e.toString();
    return { error };
}
}

async function registerUserFrontend(user_data){
try{
    const result = await request().post(`api/v2/drf/users/register_new_user/`,{user_data:user_data});
    return result;
}catch (e) {
    const error = e.message ? e.message : e.toString();
    return { error };
}
}

const ROLES = {
    ADMIN: "ADMIN",
    CONSUMER: "CONSUMER",
    SUPERVISOR: "SUPERVISOR",
    MANAGER: "MANAGER",
    MEMBER: "MEMBER"
};

export {
    list,
    courseAccess,
    fetchUserData,
    // CanceledSubscription,
    switchEmailNotification,
    // loginRequest,
    // logoutRequest,
    registerRequest,
    registerOrganizationUserRequest,
    postInterviewRequest,
    storeOpenCourseInteraction,
    // updatePassword,
    // updatePasswordError,
    // requestResetPassEmail,
    // resetPassPatch,
    updateProfileError,
    patchUser,
    getReport,
    parseError,
    stripeChangePlan,
    stripePortalSession,
    userEmailExists,
    registerUserFrontend,
    setAgreement,
    setResourceStatus,
    markAsCompleted,
    ROLES
};
