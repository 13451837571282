<template>
  <button
    class="button button-primary button-large button-shaded"
    :class="loading ? 'loading' : ''"
    :disabled="disabled || loading"
    @onclick="onclick"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "SubmitButton",
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
};
</script>
