<template>
  <div class="w-full h-full pt-20 md:pt-16 bg-gray-100 px-4 sm:px-6">
    <div class="flex flex-col gap-6">
      <InterviewBanner v-if="hasInterviewCollection.length === 0" />
      <div class="flex flex-col">
        <div class="text-2xl font-extrabold">
          {{ t('for_you.title') }}
        </div>
        <div>
          {{ t('for_you.description') }}
        </div>
      </div>

      <div v-if="isSystemCollectionLoading">
        <CardGroup
          v-for="item in systemCollectionsMapped"
          :id="item.id"
          :key="item.title"
          :name="item.titleToDisplay"
          :system-type="item.systemType"
          :only-create-mode="onlyCreateMode"
        />
        <h5 v-if="globalCollectionsMapped.length > 0">
          {{ t('for_you.global_collections', globalCollectionsMapped.length) }}
        </h5>
        <CardGroup
          v-for="item in globalCollectionsMapped"
          :id="item.id"
          :key="item.title"
          :name="item.titleToDisplay"
          :system-type="item.systemType"
          :only-create-mode="onlyCreateMode"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import CardGroup from '@/components/CardGroup.vue'
import { computed,onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import InterviewBanner from './InterviewBanner.vue';
import { useCollectionsSystem } from "@/composables/useCollectionsSystem";
import { useUserData, useUserRoles } from '@/composables/userData.js';
import ModalAskForAgreement from '@/components/ModalAskForAgreement.vue'
import { ModalBus, ModalCommands } from '@/eventBus'

const { t } = useI18n();
const { isSystemCollectionLoading, systemCollections } = useCollectionsSystem()

const { userNeedsToChooseRole } = useUserRoles()

const hasInterviewCollection = computed(() => systemCollections.value.filter(c => c.title === 'interview|interview'))
const globalCollectionsMapped = computed(() => systemCollections.value.filter(c => c.share === 'SHARE_GLOBAL').map((c) => ({
  'id': c.systemType === 'global' ? c.id : c.title,
  'title': c.title,
  'description': c.classification ? c.description : t(c.description),
  'systemTitle': c.classification ? c.classification : c.systemTitle,
  'systemType': c.systemType,
  'titleToDisplay': c.classification ? t('collections.system_' + c.classification) : (c.systemTitle || c.title),
})))
const systemCollectionsMapped = computed(() => systemCollections.value.filter(c => c.share !== 'SHARE_GLOBAL').map((c) => ({
  'id': c.systemType === 'global' ? c.id : c.title,
  'title': c.title,
  'description': c.classification ? c.description : t(c.description),
  'systemTitle': c.classification ? c.classification : c.systemTitle,
  'systemType': c.systemType,
  'titleToDisplay': c.classification ? t('collections.system_' + c.classification) : (c.systemTitle || c.title),
})))

const openConsentModal  = () => {
      ModalBus.emit(ModalCommands.OPEN, {
          component: ModalAskForAgreement,
          allowedInExpired:true,
          title:"",
          unclose:true,
          props: {

          },
          class: "",
          style: "width:auto;height:auto;margin:0;padding:0;",

        });
    }
import useCollectionsForUser from "@/composables/useCollectionsForUser";
const { collectionList, loadedCollections } = useCollectionsForUser('personal')
const onlyCreateMode = computed(() => {
  return !loadedCollections.value['personal']  || (!collectionList.value['personal'] || collectionList.value['personal'].length === 0)
})

onMounted(async () => {
  const { isAuthenticated, user } = await useUserData()
      if(isAuthenticated.value && !user.made_choice && !userNeedsToChooseRole())
      {
        openConsentModal()
      }
    })


</script>
