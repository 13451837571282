<template>
  <div class="roles-and-skills">
    <div class="user-onboarded-info">
      <div class="font-bold">
        {{ $t('user_profile.job_title') }}:
      </div>
      <div class="title">
        {{ jobNames.filter(name => !!name).join(', ') }}
      </div>
    </div>
    <div
      v-if="skillNames.length"
      class="user-onboarded-info"
    >
      <div class="font-bold">
        {{ $t('user_profile.skills') }}:
      </div>
      <div
        v-if="skillNames.length > 0"
        class="title"
      >
        {{ skillNames.join(', ') }}
      </div>
      <div v-else>
        {{ $t('user_profile.no_skills') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RolesAndSkills",
  props: {
    jobNames: {
      type: Array,
      default: () => []
    },
    skillNames: {
      type: Array,
      default: () => []
    },
  },
  setup(){
  }
}
</script>
<style scoped>
.jobs {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--space-4, 1rem);

  align-self: stretch;
}
</style>
