<template>
  <div class="change-plan-container">
    <div class="change-plan-header">
      {{ $t('plans.change.title') }}
    </div>
    <form
      class="change-plan-form-container"
      @submit.prevent="callStripeChangePlan"
    >
      <div class="change-plan-body">
        <div class="change-plan-form">
          <div class="change-plan-description">
            {{ $t('plans.change.description') }}
          </div>
          <FormInput
            v-model="changePlanData.plan_type"
            name="assigned_to"
            type="select"
            field="assigned_to"
            label="plans.change.type_of_plan"
            :options="planTypeList"
            :options-trad="true"
          />
          <FormInput
            v-model="changePlanData.billing_frequency"
            name="assigned_to"
            type="select"
            field="assigned_to"
            label="plans.change.billing_frequency"
            :options="billingFrequencyList"
            :options-trad="true"
            :disabled="true"
          />
        </div>
        <div
          class="change-plan-summary"
        >
          <span class="title"> {{ $t('plans.change.summary_title') }} </span>
          <span
            v-if="currentPlan"
            class="description"
          >
            <span class="head"> {{ $t('plans.change.summary_actual_plan', {'plan': currentPlan}) }}  </span>
            {{
              $t('plans.change.summary_current_case', {
                'amount': mapPlansPrices[currentPlan] * activeUsers,
                'monthly_amount': mapPlansPrices[currentPlan],
                'users': activeUsers})
            }}
          </span>
          <span
            v-if="changePlanData.plan_type"
            class="description"
          >
            <span class="head"> {{ $t('plans.change.summary_selected_plan', {'plan': changePlanData.plan_type}) }}  </span>
            {{
              $t('plans.change.summary_current_case', {
                'amount': mapPlansPrices[changePlanData.plan_type] * (changePlanData.plan_type === 'personal' ? 1 : activeUsers),
                'monthly_amount': mapPlansPrices[changePlanData.plan_type],
                'users': (changePlanData.plan_type === 'personal' ? 1 : activeUsers)})
            }}
          </span>
          <span
            v-else
            class="description"
          >
            {{ $t('plans.change.summary_no_selected_plan') }}
          </span>

          <div
            v-if="currentPlan && !isFreeTrial"
            class="summary"
          >
            <span class="description">
              {{ $t('plans.change.summary_selected_plan_amount', {'amount': mapPlansPrices[changePlanData.plan_type] * (changePlanData.plan_type === 'personal' ? 1 : activeUsers) }) }}
            </span>
            <span class="description">
              <span class="head">
                {{ $t('plans.change.summary_total', {'amount': (mapPlansPrices[changePlanData.plan_type] * (changePlanData.plan_type === 'personal' ? 1 : activeUsers)) }) }}
              </span>
            </span>
          </div>
          <span
            v-if="currentPlan"
            class="description"
          >
            {{ $t('plans.change.summary_next_renewal', {'renewal_date': newRenewalDateStr }) }}
          </span>
          <span
            v-if="changePlanData.plan_type === 'personal' && activeUsers > 1"
            class="description"
          >
            {{ $t('plans.change.summary_downgrade') }}
          </span>
        </div>
      </div>
      <div
        v-if="changePlanData.plan_type === 'personal' && activeUsers > 1"
        class="change-plan-footer downgrade-plan"
      >
        {{ $t('plans.change.footer_downgrade') }}
      </div>
      <div class="change-plan-footer">
        {{ $t('plans.change.footer_cta') }}
      </div>
      <div class="form-controls">
        <Button
          data-test="cancel"
          :label="$t('general.cancel')"
          severity="secondary"
          class="button-full p-button-rounded white-space-nowrap"
          @click.prevent="close"
        />
        <Button
          data-test="submit"
          :disabled="!canSubmit"
          :label="$t('plans.change.cta_button')"
          class="p-button-rounded white-space-nowrap"
          @click="callStripeChangePlan"
        />
      </div>
    </form>
  </div>
</template>
<script>

import { ref, computed } from "vue";
import {useToasts} from "@/composables/useToasts";
import {stripeChangePlan} from "@/api/user";
import {useUserData} from "@/composables/userData";
import FormInput from "@/components/FormInput.vue";

import Button from 'primevue/button';
import {ModalBus, ModalCommands} from "@/eventBus";

import Plan  from "@/resources/Plan.js";

export default {
  name: 'ChangePlanForm',
  components: {
    FormInput,
    Button
  },
  props: {
    currentPlan: { type: String, default: null },
    currentFrequency: { type: String, default: "monthly" },
    choosePlan: { type: String, default: "business" },
    isFreeTrial: { type: Boolean, default: false },
    activeUsers: { type: Number, default: 1 },
    newRenewalDateStr: { type: String, default: "" },
  },
  setup(props) {

    const { toastSuccess, toastError } = useToasts();
    const { getUser } = useUserData()
    const currentUser = getUser()
    const changePlanData = ref({
      'plan_type': props.choosePlan,
      'billing_frequency': props.currentFrequency
    })

    const mapPlansPrices = Plan.mapPlans
    const planTypeList = [
      {'key': '', 'label': 'general.select'},
      {'key': 'personal', 'label': 'plans.change.plan_type_personal_label'},
      {'key': 'business', 'label': 'plans.change.plan_type_business_label'}
    ]
    const billingFrequencyList = [
      {'key': '', 'label': 'general.select'},
      {'key': 'monthly', 'label': 'plans.change.billing_frequency_monthly'},
      {'key': 'yearly', 'label': 'plans.change.billing_frequency_yearly', 'disabled': true} //
    ]
    const canSubmit = computed(() => {
      // TODO add check for only edit billing_frequency
      return props.currentPlan !== changePlanData.value['plan_type']
    })


    const callStripeChangePlan = () => {
      // console.log('callStripeChangePlan', changePlanData.value)
      // if miss card open card currentUser.organization_info.payment_attached_date
      // if(currentUser.organization_info.payment_attached_date){
      //   managePlan()
      // }
      stripeChangePlan(changePlanData.value).then(
        (response) => {
          // console.log('callStripeChangePlan response', response, response.data, response.data.error)
          if (!response.data.error) {
            toastSuccess('', props.currentPlan ? 'plans.change.change_plan_success' : 'plans.change.active_plan_success');
            // TODO: refresh
            ModalBus.emit(ModalCommands.CLOSE, {})
            ModalBus.emit('PlanList:refresh')
            location.reload();
          } else {
            toastError('Error',props.currentPlan ? "plans.change.change_plan_error": 'plans.change.active_plan_error', { translation: {errormsg: response.data.error_msg}})
          }
        }).catch((error) => {
          toastError('Error',"plans.change.change_plan_error")
          console.log('callStripeChangePlan error', error)
      })
    }
    // const managePlan = () => {
    //   stripePortalSession()
    // }

    const close = () => {
      ModalBus.emit(ModalCommands.CLOSE, {})
    }

    return {
      mapPlansPrices,
      changePlanData,
      planTypeList,
      billingFrequencyList,
      canSubmit,
      callStripeChangePlan,
      close,
      currentUser
    };
  },
}
</script>
<style scoped>
</style>
