<template>
  <div
    class="smart-background"
    :class="{ 'fallback' : loadFailed }"
    :style="loadFailed? `background-image: url(${placeHolder});` : `background-image: url(${src});`"
  >
    <slot />
  </div>
</template>
<script>
import { onMounted } from '@vue/runtime-core'
import { ref } from 'vue'
export default {
  name: "SmartImageBackground",
  props: {
    src: {
      type: String,
      required: true
    },
    placeholder:
    {
      type: String,
      default: require(`@/assets/collection-placeholder.svg`)
    },
  },
  setup(props) {
    const loadFailed = ref(false);
    const placeHolder = props.placeholder;
    onMounted(() => {
      const img = new Image()
      img.src = props.src
      img.onerror = () => {
        loadFailed.value = true;
      }
    })
    return {
      loadFailed,
      placeHolder
    }
  },
}
</script>
<style scoped>

</style>
