import { createApp, isProxy, toRaw } from 'vue';
import { createPinia } from 'pinia';

//import Vue from "vue";
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMatomo from 'vue-matomo';
import primevuePassThrough from './primevue-passthrough';
import * as Sentry from "@sentry/vue";

import Unicon from 'vue-unicons';
import {
  uniPen, uniUsersAlt, uniUser, uniTrashAlt, uniBell, uniEnvelope, uniEnvelopeCheck,
  uniPlusCircle, uniPlus, uniMinus, uniCheck, uniExclamationCircle, uniExclamationTriangle, uniQuestionCircle,
  uniYoutube, uniLinkedin, uniChartLine, uniHome, uniVideo, uniImport, uniSpinner, uniHeart, uniShieldExclamation
} from 'vue-unicons/dist/icons';

import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';
import "primevue/resources/primevue.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import '@iconscout/unicons/css/line.css'
import '@iconscout/unicons/css/solid.css'
import VueCookies from 'vue3-cookies';


import config from './config';
import { useUserData } from './composables/userData';

(async () => {
  const { user } = useUserData()

  // Import the CSS or use your own!


  const app = createApp(App)
  app.use(i18n)
  app.use(createPinia());

  Sentry.init({
    app,
    dsn: config.SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost',/^http:\/\/app\.viblio\.local\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: config.SENTRY_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.use(PrimeVue, {
    pt: primevuePassThrough
  });
  app.use(VueCookies);


  Unicon.add([uniPen, uniUsersAlt, uniUser, uniTrashAlt, uniBell, uniEnvelope, uniEnvelopeCheck,
    uniPlusCircle, uniPlus, uniMinus, uniCheck, uniExclamationCircle, uniExclamationTriangle, uniQuestionCircle,
    uniYoutube, uniLinkedin, uniChartLine, uniHome, uniVideo, uniImport, uniSpinner, uniHeart, uniShieldExclamation
  ]);

  app.use(Unicon, {
    fill: '#2C3E50',
    height: 24,
    width: 24
  });

  app.directive('tooltip', Tooltip);

  app.use(router);


  const options = {
    position: POSITION.TOP_RIGHT
  };

  app.use(Toast, options);
  //AWAIT USER LOADED DATA
  while(!user.is_loaded){
    await new Promise(r => setTimeout(r, 100));
    //console.log(user.is_loaded)
  }
  //----------------------------------
  if(isProxy(user)){
    var data = toRaw(user)
  }
  if(!data.email.includes('dmejiasroman') && !data.email.includes('@viblio') && !(data.email == 'paolaviblio@gmail.com') && !(data.email == 'admin@apprendo.ai')){
  const matomo_options = {
    host: "https://apprendoai.matomo.cloud/",
    siteId: config.MATOMO_SITE_ID,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: data.email,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
  }
  console.log(matomo_options);
  app.use(VueMatomo, matomo_options);
  }

  app.mount('#app')
})();
