<template>
  <div
    v-if=" isAuthenticated && !userNeedsToChooseRole()"
    id="home"
    class="w-full h-full pt-20 md:pt-16 bg-gray-100 px-4 sm:px-6"
  >
    <div class="flex flex-col gap-6">
      <InterviewBanner />
      <div class="flex flex-col">
        <div class="text-2xl font-extrabold">
          {{ $t('for_you.title') }}
        </div>
        <div>
          {{ $t('for_you.description') }}
        </div>
      </div>

      <CardGroup
        v-for="item in skillTypesFolder"
        :id="item.id"
        :key="item.title"
        :name="item.titleToDisplay"
        :system-type="item.systemType"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n";

// composables
import { useUserData, useUserRoles } from '@/composables/userData'
import {getSystemCollections} from "@/api/collections";

import InterviewBanner from "@/views/InterviewBanner.vue";
import CardGroup from "@/components/CardGroup.vue";

export default {
  name: 'Home',
  components: {
    InterviewBanner,
    CardGroup,
},
  setup() {
    const stillWaitingForCollections = ref(false)

    const router = useRouter()
    const { isAuthenticated, getUser } = useUserData()
    const currentUser = getUser()
    const { userNeedsToChooseRole } = useUserRoles()
    const skillTypesFolder = ref([]);
    const loadedSkillTypesFolder = ref(false);
    const { t } = useI18n();

    if (isAuthenticated.value) {
      getSystemCollections().then((response) => {
        loadedSkillTypesFolder.value = true;
        if (response.error) {
          console.log('skillTypesFolder, error!', response, response.error);
        } else {
          skillTypesFolder.value = response.results.map((c) => {
            let toDisplay = c.classification ? t('collections.system_' + c.classification) : (c.systemTitle || c.title || '')
            return {
              'id': c.systemType === 'global' ? c.id : c.title,
              'title': c.title,
              'description': c.classification ? c.description : t(c.description),
              'systemTitle': c.classification ? c.classification : c.systemTitle,
              'systemType': c.systemType,
              'titleToDisplay': toDisplay,
              'titleToDisplayShort': toDisplay.substring(0, 11).trim() + (toDisplay.length > 11 ? ' ...' : ''),
            };
          });
          stillWaitingForCollections.value = false;
        }
      });
    }

    onMounted(async () => {
      if (isAuthenticated.value) {
        if (userNeedsToChooseRole()) {
          router.push({ name: 'welcome' })
        }
      }
    })

    watch(
      () => [isAuthenticated.value, currentUser.role],
      async () => {
        if (userNeedsToChooseRole()) {
          router.push({ name: 'welcome' })
        }
      }
    )

    // const reloadHomePage = () => {
    //   document.location.reload()
    // }

    return {
      userNeedsToChooseRole,
      skillTypesFolder,
      isAuthenticated,
      currentUser,
    }
  },
}
</script>
