<template>
  <div class="banner">
    <div
      class="banner-image"
      title="Seemingly happy people dancing"
    />
    <div class="banner-content">
      <h4 class="text-banner-title">
        {{ $t('home_box.unlogged_title') }}
      </h4>
      <div>
        <span class="text-banner-description">
          {{ $t('home_box.unlogged_msg') }}
        </span>
        <router-link
          class="button button-primary"
          to="/register"
        >
          <p>
            {{ $t('home_box.unlogged_cta') }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.banner-image {
  background-image: url("../assets/man-telephone.svg");
  background-size: cover;
  background-position: top;
}
</style>
